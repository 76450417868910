import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SocketServiceService } from './socket-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Coin Baazar';
  url: any;

  constructor(
    public router: Router,
    public socketService: SocketServiceService
  ) {
    this.socketService.initializeSocket();


    this.socketService.onEvent('connect')
      .subscribe(() => {
      });

    this.socketService.onEvent('disconnect')
      .subscribe(() => {
        this.socketService.initializeSocket();
        if (localStorage.getItem('_id') != null) {
          this.socketService.socket.emit('initChat', {
            userId: localStorage.getItem('_id')
          });
        }
      });


    if (localStorage.getItem('_id') != null) {
      this.socketService.socket.emit('initChat', {
        userId: localStorage.getItem('_id')
      });
    }
  }

  ngOnInit () {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url.split('/')[event.url.split('/').length - 1];
        if (localStorage.getItem('LOGINTOKEN') != null) {
          if (this.url == 'login') this.router.navigate(['/admin/dashboard']);
        }
      }
    });
  }
}


// "ssl": true,
//             "sslKey": "./ssl/server.key",
//             "sslCert": "./ssl/server.crt"
