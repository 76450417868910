import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2ImgMaxModule } from 'ng2-img-max';
// import { SocketIoModule, SocketIoConfig } from 'ng6-socket-io';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login/login.component';
import { SidebarComponent } from './sidebar/sidebar/sidebar.component';
import { UserDetailsComponent } from './userDetails/user-details/user-details.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer/footer.component';
import { NgxPaginationModule} from 'ngx-pagination';
import { AdvertisementComponent } from './advertisement/advertisement/advertisement.component';
import { TradesComponent } from './trades/trades.component';
import { HttpClientModule } from '@angular/common/http';
import { TabModule} from 'angular-tabs-component';
import { AddAdminstratorComponent } from './addAdminstrator/add-adminstrator/add-adminstrator.component';
import { EditAdvertisementComponent } from './advertisement/edit-advertisement/edit-advertisement.component';
import { UserManagementComponent } from './userDetails/user-management/user-management.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
// Import angular-fusioncharts
import { FusionChartsModule } from 'angular-fusioncharts';

// Import FusionCharts library and chart modules
import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';

import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { TradeDetailsComponent } from './trades/trade-details/trade-details.component';
import { KYCManagementComponent } from './KYCManagement/kycmanagement/kycmanagement.component';
import { KYCReviewComponent } from './KYCManagement/kycreview/kycreview.component';
import { FinanceManagementComponent } from './FinanceManagement/finance-management/finance-management.component';
import { DisputeManagementComponent } from './DisputeManagement/dispute-management/dispute-management.component';
import { EditUserDataComponent } from './userDetails/edit-user-data/edit-user-data.component';
import { TrackStaffActionsComponent } from './staff/track-staff-actions/track-staff-actions.component';
import { UserDetailLoginHistoryComponent } from './userDetails/user-detail-login-history/user-detail-login-history.component';
import { EditAdminUserProfileComponent } from './userDetails/edit-admin-user-profile/edit-admin-user-profile.component';
import { SystemManagementComponent } from './systemManagement/system-management/system-management.component';
import { PaymentMethodComponent } from './systemManagement/payment-method/payment-method.component';
import { StaticContentManagementComponent } from './StaticContentManagement/static-content-management/static-content-management.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { EditStaticContentManagementComponent } from './StaticContentManagement/edit-static-content-management/edit-static-content-management.component';
import { TradeDisputeHistoryComponent } from './trades/trade-dispute-history/trade-dispute-history.component';
import {MatTabsModule} from '@angular/material/tabs';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { CommonModule } from '@angular/common';

import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { ToastrModule } from 'ngx-toastr';
import { TagManagementComponent } from './tags/tag-management/tag-management.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordMainComponent } from './forgot-password-main/forgot-password-main.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SocketServiceService } from './socket-service.service';
import { MyServiceService } from './my-service.service';
import { VerifyEmailMessageComponent } from './verify-email-message/verify-email-message.component';
import { EditFAQComponent } from './StaticContentManagement/edit-faq/edit-faq.component';
import { AdminWalletBalanceComponent } from './admin-wallet-balance/admin-wallet-balance.component';
import { Tofixedto8Pipe } from './tofixedto8.pipe';




// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, Charts, FusionTheme);


// const config: SocketIoConfig = { url: 'http://172.16.6.218:8080', options: {} };

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidebarComponent,
    UserDetailsComponent,
    FooterComponent,
    AdvertisementComponent,
    TradesComponent,
    AddAdminstratorComponent,
    EditAdvertisementComponent,
    UserManagementComponent,
    DashboardComponent,
    TradeDetailsComponent,
    KYCManagementComponent,
    KYCReviewComponent,
    FinanceManagementComponent,
    DisputeManagementComponent,
    EditUserDataComponent,
    TrackStaffActionsComponent,
    UserDetailLoginHistoryComponent,
    EditAdminUserProfileComponent,
    SystemManagementComponent,
    PaymentMethodComponent,
    StaticContentManagementComponent,
    EditStaticContentManagementComponent,
    TradeDisputeHistoryComponent,
    TagManagementComponent,
    ForgotPasswordComponent,
    ForgotPasswordMainComponent,
    PageNotFoundComponent,
    VerifyEmailMessageComponent,
    EditFAQComponent,
    AdminWalletBalanceComponent,
    Tofixedto8Pipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule,
    HttpClientModule,
    TabModule,
    FusionChartsModule,
    CKEditorModule,
    MatTabsModule,
    BrowserAnimationsModule,
    CommonModule,
    NgMultiSelectDropDownModule.forRoot(),
    // SocketIoModule.forRoot(config) ,
    Ng2ImgMaxModule,
    ToastrModule.forRoot({
      preventDuplicates:true
    }
      
    ),
    Ng4LoadingSpinnerModule.forRoot()

  ],
  providers: [
    SocketServiceService,
    MyServiceService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
