import { Component, OnInit, NgZone } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MyServiceService } from '../../my-service.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { SocketServiceService } from 'src/app/socket-service.service';
import { ngCopy } from 'angular-6-clipboard';

declare var $: any ;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  LoginForm: FormGroup ;
  modalForm: FormGroup;
  imageurl: any;
  browser: any;
  id: any;
  userType: any;
  scan2FACode: any;
  forgot2FAForm: FormGroup ;
  OTPForm: FormGroup ;
  constructor(public zone: NgZone,   private myRoute: Router, public spinnerService: Ng4LoadingSpinnerService, private service: MyServiceService, public socket: SocketServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    let isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent)
    let isChrome = /msie\s|trident\/|chrome\//i.test(window.navigator.userAgent)
    let isfirefox = /msie\s|trident\/|firefox\//i.test(window.navigator.userAgent)
    let isSafari = /msie\s|trident\/|safari\//i.test(window.navigator.userAgent)
    if (isChrome) {
      this.browser = "chrome"
    }
    else {
      if (isfirefox) {
        this.browser = "firefox"
      }
      else {
        if (isSafari) {
          this.browser = "safari"
        }
        else {
          if (isIEOrEdge) {
            this.browser = "edge"
          }

          else {
            this.browser = "other"
          }
        }
      }
    }
    this.modalFormValidation();
    this.LoginForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]),
    });
    this.forgot2FAForm = new FormGroup({
      OTP: new FormControl('', [Validators.required])
    });

    this.OTPForm = new FormGroup({
      sendedOTP: new FormControl('', [Validators.required])
    });
  }

  modalFormValidation() {
    this.modalForm = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[0-9]*')])
    })
  }

  onSubmit(data) {
    if (this.LoginForm.invalid) {
      return;
    }
    this.spinnerService.show();
    let apiData = {
      "email": data.email,
      "password": data.password,
      "loginHistory": [{ "date": new Date().toISOString(), "browser": this.browser, "system_ip": this.service.IP }]
    }
    this.service.postApi('/api/v1/admin/login', apiData, 0).subscribe((success) => {
      if (success.body.responseCode == 200) {
        if (success.body.succ) {
          this.imageurl = success.body.succ;
          this.scan2FACode = success.body.succ.scan2FACode
          this.id = success.body.succ._id;
          this.userType = success.body.succ.userType
          if (this.userType == "ADMIN") {
            $('#2FA').modal({ backdrop: 'static', keyboard: false });
          } else {
            localStorage.setItem('LOGINTOKEN', this.imageurl.token);
            localStorage.setItem("_id", this.imageurl._id)
            localStorage.setItem("role", this.imageurl.userType)
            this.spinnerService.hide();
            $('#2FA').modal('hide')
            if (this.imageurl.permission[0].dashboard == true) {
              this.myRoute.navigate(["/admin/dashboard"]);
            }
            else {
              if (this.imageurl.permission[0].userManagment == true) {
                this.myRoute.navigate(["/admin/userManagement/user"]);
              }
              else {
                if (this.imageurl.permission[0].advertisment == true) {
                  this.myRoute.navigate(["/admin/advertisement"]);
                }
                else {
                  if (this.imageurl.permission[0].trades == true) {
                    this.myRoute.navigate(["/admin/trades"]);
                  }
                  else {
                    if (this.imageurl.permission[0].dispute_resolution_center == true) {
                      this.myRoute.navigate(["/admin/disputeManagement"]);
                    }
                    else {
                      if (this.imageurl.permission[0].kycVerification == true) {
                        this.myRoute.navigate(["/admin/KYCManagement"]);
                      }
                      else {
                        if (this.imageurl.permission[0].finances == true) {
                          this.myRoute.navigate(["/admin/finance"]);
                        }
                        else {
                          if (this.imageurl.permission[0].systemConfiguration == true) {
                            this.myRoute.navigate(["/admin/systemConfiguration"]);
                          }
                          else {
                            this.myRoute.navigate(["/admin/staticContentManagement"]);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            this.service.success("Successfully loggedin")
          }
        }
        else {
        this.imageurl = success.body.data;
        this.scan2FACode = success.body.data.scan2FACode
        this.id = success.body.data._id;
        this.userType = success.body.data.userType
        if (this.userType == "ADMIN") {
          $('#2FA').modal({ backdrop: 'static', keyboard: false });
        } else {
          localStorage.setItem('LOGINTOKEN', this.imageurl.token);
          localStorage.setItem("_id", this.imageurl._id)
          localStorage.setItem("role", this.imageurl.userType)
          this.spinnerService.hide();
          $('#2FA').modal('hide')
          this.socket.socket.emit('initChat', { userId: localStorage.getItem('_id'), type: localStorage.getItem("role") });
          if (this.imageurl.permission[0].dashboard == true) {
            this.myRoute.navigate(["/admin/dashboard"]);
          }
          else {
            if (this.imageurl.permission[0].userManagment == true) {
              this.myRoute.navigate(["/admin/userManagement/user"]);
            }
            else {
              if (this.imageurl.permission[0].advertisment == true) {
                this.myRoute.navigate(["/admin/advertisement"]);
              }
              else {
                if (this.imageurl.permission[0].trades == true) {
                  this.myRoute.navigate(["/admin/trades"]);
                }
                else {
                  if (this.imageurl.permission[0].dispute_resolution_center == true) {
                    this.myRoute.navigate(["/admin/disputeManagement"]);
                  }
                  else {
                    if (this.imageurl.permission[0].kycVerification == true) {
                      this.myRoute.navigate(["/admin/KYCManagement"]);
                    }
                    else {
                      if (this.imageurl.permission[0].finances == true) {
                        this.myRoute.navigate(["/admin/finance"]);
                      }
                      else {
                        if (this.imageurl.permission[0].systemConfiguration == true) {
                          this.myRoute.navigate(["/admin/systemConfiguration"]);
                        }
                        else {
                          this.myRoute.navigate(["/admin/staticContentManagement"]);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          this.service.success("Successfully login")
        }
        }
        this.spinnerService.hide();
      }
      else {
        this.service.error(success.body.responseMessage)
        this.spinnerService.hide();
      }
    }, error => {
      this.service.error("Somethig went wrong")
      this.spinnerService.hide();
    })
  }
  login(data) {
    if (this.modalForm.invalid) {
      return;
    }
    let apiData = {
      "googleToken": data.code,
      "id": this.id,
      "type": this.userType
    }
    this.spinnerService.show();
    this.service.postApi('/api/v1/admin/verifyTwoFactorAuth', apiData, 0).subscribe((success) => {
      if (success.body.responseCode == 200) {
        localStorage.setItem('LOGINTOKEN', success.body.result.token);
        localStorage.setItem("_id", success.body.result._id)
        localStorage.setItem("role", success.body.result.userType)
        this.socket.socket.emit('initChat', { userId: localStorage.getItem('_id'), type: localStorage.getItem("role") });
        this.spinnerService.hide();
        $('#2FA').modal('hide')
        this.myRoute.navigate(["/admin/dashboard"]);
        this.service.success("Successfully login")
        this.modalForm.reset()
      }
      else {
        this.service.error(success.body.responseMessage)
        this.modalForm.reset()
        this.spinnerService.hide();
      }
    }, error => {
      this.service.error(error.body.responseMessage)
      this.modalForm.reset()
      this.spinnerService.hide();
    })
  }

  forgot2FA() {
    $('#2FA').modal('hide')
    $('#forgot2fa').modal({ backdrop: 'static', keyboard: false });
  }

  sendLink(data) {
    if (this.forgot2FAForm.invalid) {
      return;
    }
    this.spinnerService.show();
    if (data.OTP == 'message') {
      let apireq = {
        "_id": this.imageurl._id
      }
      this.service.postApi("/api/v1/admin/sent_phone_otp", apireq, 0).subscribe(success => {
        if (success.body.responseCode == 200) {
          $('#forgot2fa').modal('hide')
          $('#verifyOTP').modal({ backdrop: 'static', keyboard: false });
          this.forgot2FAForm.reset()
          this.spinnerService.hide();
        }
        else {
          this.service.error(success.body.responseMessage)
          this.forgot2FAForm.reset()
          this.spinnerService.hide();
        }
      }, error => {
        this.service.error("Something went wrong")
        this.forgot2FAForm.reset()
        this.spinnerService.hide();
      })
    }
    else {
      let apireq = {
        "_id": this.imageurl._id
      }
      this.service.postApi("/api/v1/admin/sendTwoFALink", apireq, 0).subscribe(success => {
        if (success.body.responseCode == 200) {
          $('#forgot2fa').modal('hide')
          this.service.success(success.body.responseMessage)
          this.spinnerService.hide();
        }
        else {
          this.service.error(success.body.responseMessage)
          this.spinnerService.hide();
        }
      }, error => {
        this.service.error("Something went wrong")
        this.spinnerService.hide();
      })
    }
  }

  verifyOTPFunc(data) {
    this.spinnerService.show();
    let apireq = {
      "_id": this.imageurl._id,
      "otp": data.sendedOTP
    }

    this.service.postApi("/api/v1/admin/otpVerify", apireq, 0).subscribe(success => {
      if (success.body.responseCode == 200) {
        $('#verifyOTP').modal('hide')
        this.service.success(success.body.responseMessage)
        this.spinnerService.hide();
      }
      else {
        this.service.error(success.body.responseMessage)
        this.spinnerService.hide();
      }
    }, error => {
      this.service.error("Something went wrong")
      this.spinnerService.hide();
    })
  }

  copyFunc() {
    ngCopy(this.imageurl.secret.base32);

    $(".qr-code").click(function () {
      $(".copycode").addClass("copyshow");
    });
    this.zone.run(() => {
      setTimeout(() => {
          $(".copycode").removeClass("copyshow");
      }, 2000)
    })
  }

}


