import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MyServiceService } from 'src/app/my-service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-system-management',
  templateUrl: './system-management.component.html',
  styleUrls: ['./system-management.component.css']
})
export class SystemManagementComponent implements OnInit {

  url: any
  configForm: FormGroup
  adminWalletAddressForm: FormGroup
  changeHistoryArr: any
  pageNumber: number = 1
  userType:any
  address:any
  constructor(public route: Router, public service: MyServiceService) {
    this.manageRounting()
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.formValue()
    this.getData()
    this.changeHistory()
  }

  formValue() {
    this.adminWalletAddressForm = new FormGroup({
      adminWalletAddress : new FormControl('',[Validators.required,Validators.pattern(/^[123][a-km-zA-HJ-NP-Z1-9]{25,34}$/)])
    })
    this.configForm = new FormGroup({
      tradeFee: new FormControl(''),
      walletBalance: new FormControl(''),
      externalFee: new FormControl(''),
      internalFee: new FormControl(''),
      maintenance: new FormControl(false),
      ragistration: new FormControl(false)
    })
  }

  getData(){
    this.service.getApi("/api/v1/admin/configData1",1).subscribe(success=>{
      if(success.body.responseCode == 200){
        this.configForm.patchValue({
          tradeFee:success.body.Data[success.body.Data.length-1].tradeFee,
          walletBalance: success.body.Data[success.body.Data.length-1].needWalletBalance,
          externalFee: success.body.Data[success.body.Data.length-1].externalTransferFee,
          internalFee: success.body.Data[success.body.Data.length-1].internalTransferFee,
          ragistration: success.body.Data[success.body.Data.length-1].registrationOff,
          maintenance: success.body.Data[success.body.Data.length-1].maintainanceProcess
        })
      }
    },error=>{
      this.service.error("Something went wrong")
    })
  }

  manageRounting() {
    this.route.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        if (this.url == "/admin/systemConfiguration") {
          let apireq = {
            "userId": localStorage.getItem("_id")
          }
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
            if (success.body.responseCode == 200) {
              if (success.body.data.permission[0].systemConfiguration == false) {
                this.route.navigate(["/pageNotFound"]);
              }
              this.userType = success.body.data.userType
              this.address = success.body.data.btc.addresses[0] ? success.body.data.btc.addresses[0].addr : '';
              if(success.body.data.btc.addresses.length>0){
                this.adminWalletAddressForm.patchValue({
                  adminWalletAddress: success.body.data.btc.addresses[0] ? success.body.data.btc.addresses[0].addr : ''
                })
              }  
            }
            else {
              this.service.error(success.body.responseMessage)
              if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
                localStorage.removeItem('LOGINTOKEN');
                localStorage.removeItem('role');
                localStorage.removeItem('_id');
                this.route.navigate(["/login"]);
              }
            }
          }, error => {
            this.service.error("Something went wrong")
          })
        }
      }
    })
  }

  save(data) {
    var id = localStorage.getItem("_id")
    if(this.changeHistoryArr.length){
      var apireq = {
        tradeFee: data.tradeFee, 
        _id: id,
        externalTransferFee: data.externalFee,
        internalTransferFee : data.internalFee,
        needWalletBalance: data.walletBalance,
        registrationOff: data.ragistration,
        maintainanceProcess: data.maintenance,
        oldWalletBalance: this.changeHistoryArr.docs[0].needWalletBalance
      }
    }
    else{
      apireq = {
        tradeFee: data.tradeFee,
        _id: id,
        externalTransferFee: data.externalFee,
        internalTransferFee : data.internalFee,
        needWalletBalance: data.walletBalance,
        registrationOff: data.ragistration,
        maintainanceProcess: data.maintenance,
        oldWalletBalance: 0
      }
    }
    
    this.service.postApi("/api/v1/admin/configSystem", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.service.success(success.body.responseMessage)
        this.changeHistory()
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.route.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  changeHistory() {
    let apireq = {
      "pageNumber": this.pageNumber,
      "limit": 10
    }
    this.service.postApi("/api/v1/admin/configData", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.changeHistoryArr = success.body.Data
        if(success.body.Data.docs != ''){
          // this.configForm.patchValue({
          //   tradeFee:success.body.Data.docs[0].tradeFee,
          //   walletBalance: success.body.Data.docs[0].needWalletBalance,
          //   externalFee: success.body.Data.docs[0].externalTransferFee,
          //   internalFee: success.body.Data.docs[0].internalTransferFee,
          //   ragistration: success.body.Data.docs[0].registrationOff,
          //   maintenance: success.body.Data.docs[0].maintainanceProcess
          // })
        }
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.route.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  adminWalletAddressFunc(data){
    if(this.adminWalletAddressForm.invalid){
      return;
    }
    if(data.adminWalletAddress == this.address) {
      this.service.error("Address already updated")
      return;
    }
    let apireq = {
      "userId":localStorage.getItem("_id"),
      "address":data.adminWalletAddress,
      "oldAdress":this.address   
    }
    this.service.postApi("/api/v1/admin/updateAddress",apireq,1).subscribe(success=>{
      if(success.body.responseCode==200){
        this.service.success(success.body.responseMessage)
        let apireq = {
          "userId": localStorage.getItem("_id")
        }
        this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
          if (success.body.responseCode == 200) {
            if (success.body.data.permission[0].systemConfiguration == false) {
              this.route.navigate(["/pageNotFound"]);
            }
            this.userType = success.body.data.userType
            this.address = success.body.data.btc.addresses[0].addr
            if(success.body.data.btc.addresses.length>0){
              this.adminWalletAddressForm.patchValue({
                adminWalletAddress:success.body.data.btc.addresses[0].addr
              })
            }  
          }
          else {
            this.service.error(success.body.responseMessage)
            if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
              localStorage.removeItem('LOGINTOKEN');
              localStorage.removeItem('role');
              localStorage.removeItem('_id');
              this.route.navigate(["/login"]);
            }
          }
        }, error => {
          this.service.error("Something went wrong")
        })
      }
      else{
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.route.navigate(["/login"]);
        }
      }
    },error=>{
      this.service.error("Something went wrong") 
    })
  }

  paginationList(event) {
    this.pageNumber = event
    this.changeHistory()
  }

  goToConfigure() {
    this.route.navigate(["/admin/paymentMethod"])
  }

  tagManagement() {
    this.route.navigate(['/admin/tagManagement'])
  }
}

