import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MyServiceService } from '../my-service.service';

@Component({
  selector: 'app-trades',
  templateUrl: './trades.component.html',
  styleUrls: ['./trades.component.css']
})
export class TradesComponent implements OnInit {
  tradeForm: FormGroup
  nowDate: Date;
  nowDate1: any;
  hide: string;
  countryArr: any;
  tradePages = 1;
  tradeListArr = [];
  apireq: any = {}
  paginationData: any
  showdropdownList: any = []
  showdropdownSettings: any = {}
  tradeTypeDropDown: any = []
  tradeTypeDropDownSettings: any = {}
  paymentMethodArr: any = []
  paymentMethodArrSettings: any = {}
  statusDropDown: any = []
  statusDropDownSettings: any = {}
  dropDownArrList: any = []
  uniqueIddropdownSettings: any = {}
  countryDropDown: any = []
  countryDropDownSettings: any = {}
  tradeOwnerList: any = []
  tradeOwnerNameDropDownSettings: any = {}
  filteredBuyerArray: any = []
  finalBuyerArr: any = []
  buyerDropDownSettings: any = {}
  finalfinalbuyer: any = []
  finalSellerArr: any = []
  finalFinalSeller: any = []
  sellerDropDownSettings: any = {}
  url: any
  userType: any
  nowDate2: string;
  countryMultiselectorData: any
  constructor(private router: Router, public service: MyServiceService) {
    this.manageRounting();
    this.tradeForm = new FormGroup({
      tradeUniqueId: new FormControl(''),
      tradeOwnerName: new FormControl(''),
      buyer: new FormControl(''),
      seller: new FormControl(''),
      search: new FormControl(''),
      country: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      status: new FormControl(''),
      paymentMode: new FormControl(''),
      tradeType: new FormControl(''),
      show: new FormControl('')
    });

  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.countryList()
    this.userType = localStorage.getItem("_id")
    this.countryArr = this.service.countryArr
    this.nowDate1 = new Date();
    this.hide = '';
    this.paymentMethod()
    this.dropdowndata()
    this.tradeListWithSearching("event", 1)
    this.nowDate = new Date();
    this.nowDate1 = new Date();
    this.nowDate2 = '';
  }

  countryList() {
    var countryArr1 = []
    var country = this.service.countryArr
    for (let i = 0; i < country.length; i++) {
      countryArr1.push({ id: i, country: country[i].name })
    }
    this.countryMultiselectorData = countryArr1
    this.countryDropDownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'country',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }



  Changed(event) {
    if (event) {
      this.nowDate2 = event;
      this.nowDate = new Date();
    }
    else{
      this.nowDate2 = '';
    }
  }
  maxFromDate(event){
    if(event){
      this.nowDate1= new Date(event);
      }
      else{
        this.nowDate1= new Date();
      }
  }

  manageRounting() {
    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        if (this.url == "/admin/trades") {
          let apireq = {
            "userId": localStorage.getItem("_id")
          }
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
            if (success.body.responseCode == 200) {
              if (success.body.data.permission[0].trades == false) {
                this.router.navigate(["/pageNotFound"]);
              }
            }
            else {
              this.service.error(success.body.responseMessage)
              if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
                localStorage.removeItem('LOGINTOKEN');
                localStorage.removeItem('role');
                localStorage.removeItem('_id');
                this.router.navigate(["/login"]);
              }
            }
          }, error => {
            this.service.error("Something went wrong")
          })
        }
      }
    })
  }

  paymentMethod() {
    this.service.getApi("/api/v1/admin/paymentMethodList", 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.paymentMethodArr = success.body.result
        this.paymentMethodArrSettings = {
          singleSelection: true,
          idField: '_id',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        }
      } else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  dropdowndata() {
    let apireqdd = {
      userId: ""
    }
    var buyerArr = []
    var sellerArr = []
    this.service.postApi("/api/v1/admin/tradeFilter", apireqdd, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.dropDownArrList = success!.body
        for (let i = 0; i < this.dropDownArrList.result1.length; i++) {
          buyerArr.push(this.dropDownArrList.result1[i].buyer[0].buyer_name)
        }
        var filteredBuyerArray = buyerArr.filter(function (item, pos) {
          return buyerArr.indexOf(item) == pos;
        })
        for (let i = 0; i < filteredBuyerArray.length; i++) {
          this.finalBuyerArr.push({ id: i, buyerName: filteredBuyerArray[i] })
        }
        this.finalfinalbuyer = this.finalBuyerArr
        for (let i = 0; i < this.dropDownArrList.result1.length; i++) {
          sellerArr.push(this.dropDownArrList.result1[i].seller[0].seller_name)
        }
        var filteredSellerArray = sellerArr.filter(function (item, pos) {
          return sellerArr.indexOf(item) == pos;
        })
        for (let i = 0; i < filteredSellerArray.length; i++) {
          this.finalSellerArr.push({ id: i, sellerName: filteredSellerArray[i] })
        }
        this.finalFinalSeller = this.finalSellerArr
        this.dropdownLists()

      }
      else {
        this.service.error(success.body.responseMessage)
      }
    })
  }



  dropdownLists() {
    this.uniqueIddropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'data',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
    this.countryDropDownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'country',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
    this.tradeOwnerNameDropDownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'trade_owner_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
    this.buyerDropDownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'buyerName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
    this.sellerDropDownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'sellerName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
    this.showdropdownList = [{ _id: 1, limit: "10" }, { _id: 2, limit: "15" }, { _id: 3, limit: "20" }, { _id: 4, limit: "30" }]
    this.showdropdownSettings = {
      singleSelection: true,
      idField: '_id',
      textField: 'limit',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.tradeTypeDropDown = [{ _id: 1, type: "ALL" }, { _id: 2, type: "BUY" }, { _id: 3, type: "SELL" }]
    this.tradeTypeDropDownSettings = {
      singleSelection: true,
      idField: '_id',
      textField: 'type',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.statusDropDown = [{ id: 1, status: "ALL" }, { id: 2, status: "Pending" }, { id: 3, status: "Dispute" }, { id: 4, status: "Complete" }, { id: 5, status: "Cancel" }]
    this.statusDropDownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'status',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  tradeListWithSearching(event, pag) {
    var tradeTypeHaiYe;
    if (this.tradeForm.value.tradeType != '') {
      if (this.tradeForm.value.tradeType[0].type != "ALL") {
        if (this.tradeForm.value.tradeType[0].type == "BUY") {
          tradeTypeHaiYe = 'buy'
        }
        else {
          if (this.tradeForm.value.tradeType[0].type == "SELL") {
            tradeTypeHaiYe = 'sell'
          }
        }
      }
      else {
        tradeTypeHaiYe = ''
      }
    }
    else {
      tradeTypeHaiYe = ''
    }
    if (this.userType == "ADMIN") {
      var employeeId = ''
    }
    else {
      if (this.userType == "SUBADMIN") {
        var employeeId = localStorage.getItem("_id")
      }
      else {
        if (this.userType == "MANAGER") {
          var employeeId = localStorage.getItem("_id")
        }
      }
    }
    if (pag == 1) {
      this.tradePages = 1
    }
    if (event == 'reset') {
      this.tradeForm.patchValue({
        tradeUniqueId: '',
        tradeOwnerName: '',
        buyer: '',
        seller: '',
        search: '',
        country: '',
        fromDate: '',
        toDate: '',
        status: '',
        paymentMode: '',
        tradeType: '',
        show: ''
      });
      this.dropdowndata()
      this.apireq = {
        "uniqueId": '',
        "tradeOwner": '',
        "buyerName": '',
        "sellerName": '',
        "paymentMethod": '',
        "status": '',
        "tradeType": '',
        "fromDate": '',
        "toDate": '',
        "country": '',
        "limit": 10,
        "page": this.tradePages
      }
    }
    else {
      this.apireq = {
        "uniqueId": this.tradeForm.value.tradeUniqueId != '' ? this.tradeForm.value.tradeUniqueId[0].data : '',
        "tradeOwner": this.tradeForm.value.tradeOwnerName != '' ? this.tradeForm.value.tradeOwnerName[0].trade_owner_name : '',
        "buyerName": this.tradeForm.value.buyer != '' ? this.tradeForm.value.buyer[0].buyerName : '',
        "sellerName": this.tradeForm.value.seller != '' ? this.tradeForm.value.seller[0].sellerName : '',
        "paymentMethod": this.tradeForm.value.paymentMode != '' ? this.tradeForm.value.paymentMode[0].name : '',
        "status": (this.tradeForm.value.status != '') ? ((this.tradeForm.value.status[0].status != "ALL") ? this.tradeForm.value.status[0].status : '') : '',
        // "tradeType": (this.tradeForm.value.tradeType != '') ? ((this.tradeForm.value.tradeType[0].type != "ALL") ? this.tradeForm.value.tradeType[0].type : '') : '',
        "tradeType": tradeTypeHaiYe,
        "fromDate": (this.tradeForm.value.fromDate == '' || this.tradeForm.value.fromDate == undefined) ? '' : (new Date(this.tradeForm.value.fromDate).toISOString()),
        "toDate": (this.tradeForm.value.toDate == '' || this.tradeForm.value.toDate == undefined) ? '' : (new Date(new Date(this.tradeForm.value.toDate).getTime() + 24 * 60 * 60 * 1000).toISOString()),
        "country": this.tradeForm.value.country != '' ? this.tradeForm.value.country[0].country : '',
        "limit": this.tradeForm.value.show != 10 ? (this.tradeForm.value.show != '' ? Number(this.tradeForm.value.show[0].limit) : 10) : 10,
        "page": this.tradePages
      }
    }
    this.service.postApi("/api/v1/admin/tradeList", this.apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.tradeListArr = success.body.result;
        this.paginationData = success.body.paginationData;
      }
      else {
        if (success.body.responseCode == 404) {
          this.tradeListArr = []
        }
        else {
          this.service.error(success.body.responseMessage)
          if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
            localStorage.removeItem('LOGINTOKEN');
            localStorage.removeItem('role');
            localStorage.removeItem('_id');
            this.router.navigate(["/login"]);
          }
        }
      }
    }, error => {
      this.service.error("Something went wrong");
    })
  }

  pagination(event) {
    this.tradePages = event;
    this.tradeListWithSearching("event", 3);
  }
}
