import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MyServiceService } from '../../my-service.service';

declare var $: any;
@Component({
  selector: 'app-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.css']
})
export class AdvertisementComponent implements OnInit {
  advertisementListSearch: FormGroup
  nowDate: Date;
  nowDate1: any;
  nowDate2: string;
  hide: string;
  page: any = 1;
  srNo: number
  advertisementList = [];
  totalRecordsofUser: any;
  type: any = []
  typeSetting: any = {}
  limit: number = 10
  minmaxForm: any;
  modalId: any;
  paginationData: any;
  adId: any;
  paymentMethodArr: any;
  dropdownList = [];
  countryMultiselectorData: any = []
  subadminFirstNameMultiselectorData: any = []
  userNamedropdownSettings = {};
  userNameMultiselectorData: any = [];
  uniqueIddropdownSettings: any = {}
  emaildropdownSettings: any = {};
  userPhoneNumberdropdownSettings: any = {};
  countrydropdownSettings: any = {};
  statusdropdownSettings: any = {}
  statusdropdownList: any = []
  showdropdownList: any = []
  showdropdownSettings: any = {}
  subadminFirstNamedropdownSettings: any = {}
  paymentMethodArrSettings: any = {}
  apireq: any
  url: any
  permissions: any
  min: any;
  max: any
  flag: any = 0;
  constructor(private myRoute: Router, public service: MyServiceService, private activatedRoute: ActivatedRoute) {
    this.manageRounting();
    this.advertisementListSearch = new FormGroup({
      uniqueId: new FormControl(''),
      userName: new FormControl(''),
      email: new FormControl(''),
      status: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      show: new FormControl(''),
      location: new FormControl(''),
      paymentMethod: new FormControl(''),
      type: new FormControl(''),
    });

    this.minmaxForm = new FormGroup({
      min: new FormControl('', [Validators.pattern('[0-9]*')]),
      max: new FormControl('', [Validators.pattern('[0-9]*')]),
    })

  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.countryList()
    this.advertisementSearch("reset", 1)
    this.allNames()
    this.paymentMethodList()
    this.hide = '';
    this.nowDate = new Date();
    this.nowDate1 = new Date();
    this.nowDate2 = '';
  }

  countryList(){
    var countryArr1 = []
    var country = this.service.countryArr
    for(let i=0;i<country.length;i++){
      countryArr1.push({id:i,location:country[i].name})  
    }
    this.countryMultiselectorData = countryArr1
    this.countrydropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'location',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  
  onKeyMin(event: any) {
    this.min = parseInt(event.target.value);
    if (this.min && this.max) {
      if (this.min > this.max) {
        this.flag = 1
      }
      if (this.min < this.max) {
        this.flag = 0
      }
    }
  }
  onKeyMax(event: any) {
    this.max = parseInt(event.target.value);
    if (this.min && this.max) {
      if (this.min > this.max) {
        this.flag = 1
      }
      if (this.min < this.max) {
        this.flag = 0
      }
    }
    var maxLimit = String(this.max)
    if (maxLimit == 'NaN') {
      this.flag = 0
    }
  }

  manageRounting() {
    this.myRoute.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        if (this.url == "/admin/advertisement") {
          let apireq = {
            "userId": localStorage.getItem("_id")
          }
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
            if (success.body.responseCode == 200) {
              this.permissions = success.body.data.permission[0]
              if (success.body.data.permission[0].advertisment == false) {
                this.myRoute.navigate(["/pageNotFound"]);
              }
            }
          }, error => {
            this.service.error("Something went wrong")
          })
        }

      }
    })
  }

  paymentMethodList() {
    this.service.getApi("/api/v1/admin/paymentMethodList", 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.paymentMethodArr = success.body.result
        this.paymentMethodArrSettings = {
          singleSelection: true,
          idField: '_id',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        }
      } else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }


  allNames() {
    let apireq = {
      "userId": ""
    }
    this.service.postApi("/api/v1/admin/uniqueAddList", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.userNameMultiselectorData = success.body
        this.usernameMultiselector()
      }
      else
        this.service.error(success.body.responseMessage)
      if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
        localStorage.removeItem('LOGINTOKEN');
        localStorage.removeItem('role');
        localStorage.removeItem('_id');
        this.myRoute.navigate(["/login"]);
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }
  // *************************************** multiselector **********************************************
  usernameMultiselector() {
    this.userNamedropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'user_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.emaildropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'user_email',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    
    this.type = [{ _id: 1, type: "ALL" }, { _id: 2, type: "buy" }, { _id: 3, type: "sell" }]
    this.typeSetting = {
      singleSelection: true,
      idField: '_id',
      textField: 'type',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
    this.showdropdownList = [{ _id: 1, limit: "10" }, { _id: 2, limit: "15" }, { _id: 3, limit: "20" }, { _id: 4, limit: "30" }]
    this.showdropdownSettings = {
      singleSelection: true,
      idField: '_id',
      textField: 'limit',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.statusdropdownList = [{ _id: 1, status: "ALL" }, { _id: 2, status: "ACTIVE" }, { _id: 3, status: "DISABLE" }]
    this.statusdropdownSettings = {
      singleSelection: true,
      idField: '_id',
      textField: 'status',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.uniqueIddropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'data',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }


  Changed(event) {
    if (event) {
      this.nowDate2 = event;
      this.nowDate = new Date();
    }
    else{
      this.nowDate2 = ''
    }
  }

  maxFromDate(event){
    if(event){
      this.nowDate1= new Date(event);
      }
      else{
        this.nowDate1= new Date();
      }
  }

  advertisementSearch(data, pag) {
    if (this.advertisementListSearch.invalid) {
      return
    }
    if (pag == 1) {
      this.page = pag
    }
    if (data == 'reset') {
      this.advertisementListSearch.patchValue({
        uniqueId: "",
        userName: "",
        email: "",
        status: "",
        startDate: "",
        endDate: "",
        show: "",
        location: "",
        paymentMethod: "",
        type: "",
      });
      this.minmaxForm.patchValue({
        min: '',
        max: ''
      })
      this.apireq = {
        "uniqueId": '',
        "userName": '',
        "email": '',
        "payment_method": '',
        "status": '',
        "type": '',
        "startDate": "",
        "endDate": "",
        "country": '',
        "limit": 10,
        "page": this.page,
        "minLimit": '',
        "maxlimit": ''
      }
    }
    else {
      this.apireq = {
        "uniqueId": this.advertisementListSearch.value.uniqueId != '' ? this.advertisementListSearch.value.uniqueId[0].data : '',
        "userName": this.advertisementListSearch.value.userName != '' ? this.advertisementListSearch.value.userName[0].user_name : '',
        "email": this.advertisementListSearch.value.email != '' ? this.advertisementListSearch.value.email[0].user_email : '',
        "payment_method": this.advertisementListSearch.value.paymentMethod != '' ? this.advertisementListSearch.value.paymentMethod[0].name : '',
        "status": (this.advertisementListSearch.value.status != '') ? ((this.advertisementListSearch.value.status[0].status != "ALL") ? this.advertisementListSearch.value.status[0].status : '') : '',
        "type": (this.advertisementListSearch.value.type != '') ? ((this.advertisementListSearch.value.type[0].type != "ALL") ? this.advertisementListSearch.value.type[0].type : '') : '',
        "startDate": (this.advertisementListSearch.value.startDate == '' || this.advertisementListSearch.value.startDate == undefined) ? '' : (new Date(this.advertisementListSearch.value.startDate).toISOString()),
        "endDate": (this.advertisementListSearch.value.endDate == '' || this.advertisementListSearch.value.endDate == undefined) ? '' : (new Date(new Date(this.advertisementListSearch.value.endDate).getTime() + 24 * 60 * 60 * 1000).toISOString()),
        "country": this.advertisementListSearch.value.location != '' ? this.advertisementListSearch.value.location[0].location : '',
        "limit": this.advertisementListSearch.value.show != 10 ? (this.advertisementListSearch.value.show != '' ? Number(this.advertisementListSearch.value.show[0].limit) : 10) : 10,
        "page": this.page,
        "minLimit": this.minmaxForm.value.min != '' ? this.minmaxForm.value.min : '',
        "maxlimit": this.minmaxForm.value.max != '' ? this.minmaxForm.value.max : '',
      }
    }

    this.service.postApi("/api/v1/admin/advertisementList", this.apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.advertisementList = success.body.result;
        this.paginationData = success.body.paginationData
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }


  advertisementPagination(event) {
    this.page = event;
    this.advertisementSearch("qwewe", 2)
  }

  goButtonClick(data) {
    if (this.minmaxForm.invalid) {
      return;
    }
  }

  reset() {
    this.minmaxForm.patchValue({
      min: '',
      max: ''
    })
    this.advertisementSearch("reset", 1)

    this.flag = 0;


  }
  //..........................//#endregion...........................................................................

  viewModal(id, adId) {
    this.adId = adId
    this.modalId = id
    $('#delete').modal({ backdrop: 'static', keyboard: false });
  }

  disableButton(status) {
    let apireq = {
      adId: this.adId,
      status: status
    }
    this.service.postApi('/api/v1/admin/changeAdStatus', apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.advertisementSearch("ewrere", 1)
        this.service.success(success.body.responseMessage)
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

}
