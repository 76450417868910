import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MyGuardGuard  implements CanActivate{
  constructor(private router: Router) { 
    
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('LOGINTOKEN') != null) {
      return true;          
    }
    else{
      this.router.navigate(['/login']);
      return false;
    }
  }  
}
