import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MyServiceService } from 'src/app/my-service.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
declare var $: any;
@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  id: any;
  subadminLoginHistoryArr = [];
  limit: any;
  totalRecords: any;
  userStatus: any
  userDetails: any
  page: number = 1;
  flag = 1
  userInfo: any = {}
  userData: any
  trustedData = []
  activeInactiveStatus: any
  advertisementTableData = [];
  advertisementPaginationData: any;
  walletTableData: any = [];
  KYCDataArray = [];
  tradeData = [];
  tradePaginationData: any;
  feedbackData = [];
  feedbackPaginationData: any;
  tradeSrNo: number;
  tradepage: number = 1;
  searchForm: FormGroup;
  advertisementPage: number = 1;
  currentPage: any;
  modalId: any;
  adId: any;
  userNameMultiselectorData: any = {}
  countrydropdownSettings: any = {}
  type: any = []
  typeSetting: any = {}
  showdropdownList: any = [];
  showdropdownSettings: any = {};
  statusdropdownList: any = []
  statusdropdownSettings: any = {}
  uniqueIddropdownSettings: any = {}
  paymentMethodArr: any = [];
  paymentMethodArrSettings: any = {}
  apireq: any;
  manageDropDown: any
  tradeSearchForm: FormGroup;
  dropDownArrList: any = []
  tradeuniqueIddropdownSettings: any = {};
  tradecountryDropDownSettings: any = {};
  tradeOwnerNameDropDownSettings: any = {};
  tradebuyerDropDownSettings: any = {};
  tradesellerDropDownSettings: any = {};
  tradeTypeDropDown: any = [];
  tradeTypeDropDownSettings: any = {};
  tradestatusDropDown: any = [];
  tradestatusDropDownSettings: any = {};
  tradeshowdropdownList: any = [];
  tradeshowdropdownSettings: any = {};
  userNamedropdownSettings: any = {}
  trustedUserNameDropDown: any = []
  finalTrustUserName: any = []
  manageModal: any;
  msg: any;
  apireqhere: any
  count = 0
  url: any
  trustByForm: FormGroup
  trustPageNumber: number = 1
  trustedById: any
  trustedByName: any
  walletForm: FormGroup
  toAddressArr: any = []
  finalwalletAddressArr: any = []
  walletAddressdropdownSettings: any = {}
  feedbacklength: any
  feedbackForm: FormGroup
  blockedByList: any = []
  pageData: any
  deleteBlockName: any
  blockByForm: FormGroup
  blockPageNumber: number = 1
  permissions: any
  min: number;
  max: number;
  flag3: any = 0
  nowDate2: any;
  nowDate: Date;
  nowDate1: any;
  feedbackPercentageValue: any
  countryMultiselectorData:any
  permissionTab: any=[];
  tabManage: string='';
  bolckByUserName:any=[]
  bolckByUserNamedropdownSettings:any={}
  prefixCountry: any;
  phoneNumber: { "type": string, "details": any, "status": any };
  kyc_status: { "type": string, "details": any, "status": any, "_id": any, "date": any };
  email: { "type": string, "details": any, "status": any };
  constructor(private myRoute: Router, public service: MyServiceService, private activatedRoute: ActivatedRoute, public spinnerService: Ng4LoadingSpinnerService) {
    this.manageRounting();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.setCountry()
    this.forms();
    this.getId();
    this.subadminLoginHistory();
    this.advertisementSearchDropDropData()
    this.paymentMethodList()
    this.userDetail();
    this.advertisementSearchWithList("reset", 1)
    this.feedback('reset', 1)
    this.feedbackPercentage()
    this.nowDate = new Date();
    this.nowDate1 = new Date();
    this.nowDate2 = '';
  }

  setCountry(){
    var countryArr1 = []
    var country = this.service.countryArr
    for(let i=0;i<country.length;i++){
      countryArr1.push({id:i,location:country[i].name})
    }
    this.countryMultiselectorData = countryArr1
    this.countrydropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'location',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  feedbackPercentage() {
    let apireq = {
      userId: this.id
    }
    this.service.postApi("/api/v1/feedback/feedbackScore", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.feedbackPercentageValue = success.body
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  Changed(event) {
    if (event) {
      this.nowDate2 = event;
      this.nowDate = new Date();
    }
    else{
      this.nowDate2 = ''
    }
  }

  maxFromDate(event){
    if(event){
      this.nowDate1= new Date(event);
      }
      else{
        this.nowDate1= new Date();
      }
  }


  manageRounting() {
    this.myRoute.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        var spliturl = this.url.split("/")
        if (spliturl[2] == "userDetails") {
          let apireq = {
            "userId": localStorage.getItem("_id")
          }
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
            if (success.body.responseCode == 200) {
              this.permissions = success.body.data
              if (success.body.data.permission[0].userManagment == false) {
                this.myRoute.navigate(["/pageNotFound"]);
              }
              var permission = this.permissions.permission[0];
              if(permission.advertisment){
                this.permissionTab.push("Advertisement");
                // if(this.tabManage ='')
                  this.tabManage = 'Advertisement'
              }
              if(permission.trades){
                this.permissionTab.push("Trade");
                if(this.tabManage == '')
                  this.tabManage = 'Trade'
              }
              if(permission.um_seewalletAndBalance){
                this.permissionTab.push("Wallet");
                if(this.tabManage =='')
                  this.tabManage = 'Wallet'
              }
              if(permission.um_feedbackByAction){
                this.permissionTab.push("Feedback");
                if(this.tabManage =='')
                  this.tabManage = 'Feedback'
              }
              if(permission.um_trustedByAction){
                this.permissionTab.push("trustby");
                if(this.tabManage =='')
                  this.tabManage = 'trustby'
              }
              if(permission.um_blockedByAction){
                this.permissionTab.push("blockby");
                if(this.tabManage =='')
                  this.tabManage = 'blockby'
              }
            }
            else {
              this.service.error(success.body.responseMessage)
              if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
                localStorage.removeItem('LOGINTOKEN');
                localStorage.removeItem('role');
                localStorage.removeItem('_id');
                this.myRoute.navigate(["/login"]);
              }
            }
          }, error => {
            this.service.error("Something went wrong")
          })
        }

      }
    })
  }
tab(val){
this.tabManage = val;
}

  forms() {
    this.searchForm = new FormGroup({
      uniqueId: new FormControl(''),
      status: new FormControl(''),
      startDate: new FormControl(''),
      type: new FormControl(''),
      endDate: new FormControl(''),
      minLimit: new FormControl(''),
      maxLimit: new FormControl(''),
      paymentMethod: new FormControl(''),
      show: new FormControl(''),
      location: new FormControl(''),
      tradeUniqueId: new FormControl(''),
    })

    this.tradeSearchForm = new FormGroup({
      tradeUniqueId: new FormControl(''),
      tradeStartDate: new FormControl(''),
      tradeEndDate: new FormControl(''),
      tradeStatus: new FormControl(''),
      tradePaymentMethod: new FormControl(''),
      tradeType: new FormControl(''),
      tradeShow: new FormControl(''),
    })

    this.trustByForm = new FormGroup({
      trustUserName: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    })

    this.walletForm = new FormGroup({
      walletAddress: new FormControl(''),
      walletStartDate: new FormControl(''),
      walletEndDate: new FormControl('')
    })

    this.feedbackForm = new FormGroup({
      feedbackStartDate: new FormControl(''),
      feedbackEndDate: new FormControl(''),
    })

    this.blockByForm = new FormGroup({
      blockByUserName: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
    })
  }
  changeDateToNull() {
    this.searchForm.patchValue({
      startDate: '',
      endDate: '',
    })

    this.tradeSearchForm.patchValue({
      tradeStartDate: '',
      tradeEndDate: '',
    })

    this.trustByForm.patchValue({
      fromDate: '',
      toDate: ''
    })

    this.walletForm.patchValue({
      walletStartDate: '',
      walletEndDate: ''
    })

    this.feedbackForm.patchValue({
      feedbackStartDate: '',
      feedbackEndDate: '',
    })

    this.blockByForm.patchValue({
      fromDate: '',
      toDate: '',
    })
  }

  //.............user details .....................................................................................................
  userDetail() {
    this.spinnerService.show();
    let apireq = {
      "userId": this.id
    }
    this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.userData = success.body.data
          if(this.userData.country_code){
            for(let i=0;i<=this.service.countryArr.length;i++){
              if(this.service.countryArr[i].dial_code==this.userData.country_code){
                this.prefixCountry=this.service.countryArr[i].code
                break;
              }
            }

          }
        var kyc = success.body.data.kyc_docs;
        this.userDetails = success.body.data.status
        if (this.flag == 1 && this.count == 0) {
          this.phoneNumber = {
            "type": "Phone Number",
            "details": success.body.data.phone_verification_date ? success.body.data.phone_verification_date : null,
            "status": success.body.data.verified_phone
          }
          this.kyc_status = {
            "type": "KYC Status",
            "details": (kyc.length) ? success.body.data.kyc_docs[success.body.data.kyc_docs.length - 1].updated_at : null,
            "status": success.body.data.verified_upload_docs ? success.body.data.kyc_docs[success.body.data.kyc_docs.length - 1].kyc_status : null,
            "_id": (kyc.length) ? success.body.data.kyc_docs[success.body.data.kyc_docs.length - 1]._id : null,
            "date": (kyc.length) ? success.body.data.kyc_docs[success.body.data.kyc_docs.length - 1].updated_at : null,
          }
          this.email = {
            "type": "Email",
            "details": success.body.data.email_verification_date ? success.body.data.email_verification_date : null,
            "status": success.body.data.verified_email
          }
          this.KYCDataArray.push(this.kyc_status)
          this.KYCDataArray.push(this.email)
          this.KYCDataArray.push(this.phoneNumber)
          this.count++

          this.phoneNumber = {
            "type": "Phone Number",
            "details": success.body.data.phone_verification_date ? success.body.data.phone_verification_date : null,
            "status": success.body.data.verified_phone
          }
          this.kyc_status = {
            "type": "KYC Status",
            "details": (kyc.length) ? success.body.data.kyc_docs[success.body.data.kyc_docs.length - 1].updated_at : null,
            "status": success.body.data.verified_upload_docs ? success.body.data.kyc_docs[success.body.data.kyc_docs.length - 1].kyc_status : null,
            "_id": (kyc.length) ? success.body.data.kyc_docs[success.body.data.kyc_docs.length - 1]._id : null,
            "date": (kyc.length) ? success.body.data.kyc_docs[success.body.data.kyc_docs.length - 1].updated_at : null,
          }
          this.email = {
            "type": "Email",
            "details": success.body.data.email_verification_date ? success.body.data.email_verification_date : null,
            "status": success.body.data.verified_email
          }

        }

        this.userInfo = {
          "name": success.body.data.user_name,
          "email": success.body.data.email,
          "accountCreated": success.body.data.created_at,
          "balance": success.body.data.btc.total,
          "feedback": success.body.data.feedback_score,
          "twofa": success.body.data.google2FA,
          "lastLoged": success.body.data.last_seen
        }
        this.spinnerService.hide();
      } else {

        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
        this.spinnerService.hide();
      }
    }, error => {
      this.spinnerService.hide();
      this.service.error("Something went wrong")
    })
  }
  //.......................................Advertisement ............................................................

  advertisementSearchDropDropData() {
    let apireq = {
      "userId": this.id
    }
    this.service.postApi("/api/v1/admin/uniqueAddList", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.userNameMultiselectorData = success.body
        this.usernameMultiselector()
      }
      else {
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }
  // *************************************** multiselector **********************************************

  paymentMethodList() {

    this.service.getApi("/api/v1/admin/paymentMethodList", 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.paymentMethodArr = success.body.result
        this.paymentMethodArrSettings = {
          singleSelection: true,
          idField: '_id',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        }
      } else {

        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  usernameMultiselector() {
    this.type = [{ _id: 1, type: "ALL" }, { _id: 2, type: "buy" }, { _id: 3, type: "sell" }]
    this.typeSetting = {
      singleSelection: true,
      idField: '_id',
      textField: 'type',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
    this.showdropdownList = [{ _id: 1, limit: "10" }, { _id: 2, limit: "15" }, { _id: 3, limit: "20" }, { _id: 4, limit: "30" }]
    this.showdropdownSettings = {
      singleSelection: true,
      idField: '_id',
      textField: 'limit',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.statusdropdownList = [{ _id: 1, status: "ALL" }, { _id: 2, status: "ACTIVE" }, { _id: 3, status: "DISABLE" }]
    this.statusdropdownSettings = {
      singleSelection: true,
      idField: '_id',
      textField: 'status',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.uniqueIddropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'data',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }

  advertisementSearchWithList(event, pag) {
    if (pag == 1) {
      this.page = 1
    }
    this.manageDropDown = "avertisement"
    if (event == 'reset') {
      this.searchForm.patchValue({
        uniqueId: "",
        status: "",
        startDate: "",
        type: "",
        endDate: "",
        minLimit: "",
        maxLimit: "",
        paymentMethod: "",
        show: "",
        location: "",
      })
      this.apireq = {
        "userId": this.id,
        "uniqueId": '',
        "payment_method": '',
        "status": '',
        "trade": '',
        "startDate": "",
        "endDate": "",
        "location": '',
        "limit": 10,
        "page": this.page,
        "minLimit": '',
        "maxLimit": '',
      }


    }
    else {
      this.apireq = {
        "userId": this.id,
        "uniqueId": this.searchForm.value.uniqueId != '' ? this.searchForm.value.uniqueId[0].data : '',
        "payment_method": this.searchForm.value.paymentMethod != '' ? this.searchForm.value.paymentMethod[0].name : '',
        "status": (this.searchForm.value.status != '') ? ((this.searchForm.value.status[0].status != "ALL") ? this.searchForm.value.status[0].status : '') : '',
        "trade": (this.searchForm.value.type != '') ? ((this.searchForm.value.type[0].type != "ALL") ? this.searchForm.value.type[0].type : '') : '',
        "startDate": (this.searchForm.value.startDate == '' || this.searchForm.value.startDate == undefined) ? '' : (new Date(this.searchForm.value.startDate).toISOString()),
        "endDate": (this.searchForm.value.endDate == '' || this.searchForm.value.endDate == undefined) ? '' : (new Date(new Date(this.searchForm.value.endDate).getTime() + 24 * 60 * 60 * 1000).toISOString()),
        "location": this.searchForm.value.location != '' ? this.searchForm.value.location[0].location : '',
        "limit": this.searchForm.value.show != 10 ? (this.searchForm.value.show != '' ? Number(this.searchForm.value.show[0].limit) : 10) : 10,
        "page": this.advertisementPage,
        "minLimit": this.searchForm.value.minLimit != '' ? this.searchForm.value.minLimit : '',
        "maxLimit": this.searchForm.value.maxLimit != '' ? this.searchForm.value.maxLimit : '',
      }
    }
    this.service.postApi("/api/v1/admin/getAdvertise", this.apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.advertisementTableData = success.body.result
        this.advertisementPaginationData = success.body.paginationData
      } else {

        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })

  }

  onKeyMin(event: any) {
    this.min = parseInt(event.target.value);
    if (this.min && this.max) {
      if (this.min > this.max) {
        this.flag3 = 1
      }
      if (this.min < this.max) {
        this.flag3 = 0
      }
    }
  }

  resetFlag() {
    this.flag3 = 0
  }
  onKeyMax(event: any) {
    this.max = parseInt(event.target.value);
    if (this.min && this.max) {
      if (this.min > this.max) {
        this.flag3 = 1
      }
      if (this.min < this.max) {
        this.flag3 = 0
      }
    }
    var maxLimit = String(this.max)
    if (maxLimit == 'NaN') {
      this.flag3 = 0
    }
  }

  ///..............................
  advertisementPagination(event) {
    this.advertisementPage = event;
    this.advertisementSearchWithList("event", 2)
  }

  changeAdvertisementStatus(status, adId, ) {
    this.modalId = status
    this.adId = adId
    $('#delete').modal({ backdrop: 'static', keyboard: false });
  }

  delete(adStatus) {
    let apireq = {
      adId: this.adId,
      status: adStatus
    }
    this.service.postApi('/api/v1/admin/changeAdStatus', apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.advertisementSearchWithList('reset', 1)
        this.service.success(success.body.responseMessage)
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  //********************************************************** trade **********************************************************

  tradedropdowndata() {
    this.nowDate = new Date();
    this.nowDate1 = new Date();
    this.nowDate2 = '';
    let apireqdd = {
      userId: this.id
    }
    var buyerArr = []
    var sellerArr = []
    this.service.postApi("/api/v1/admin/tradeFilter", apireqdd, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.dropDownArrList = success!.body
        this.dropdownLists()
      }
      else {

        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    })
  }

  dropdownLists() {
    this.tradeuniqueIddropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'data',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
    this.tradeOwnerNameDropDownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'trade_owner_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
    this.tradeshowdropdownList = [{ _id: 1, limit: "10" }, { _id: 2, limit: "15" }, { _id: 3, limit: "20" }, { _id: 4, limit: "30" }]
    this.tradeshowdropdownSettings = {
      singleSelection: true,
      idField: '_id',
      textField: 'limit',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.tradeTypeDropDown = [{ _id: 1, type: "ALL" }, { _id: 2, type: "BUY" }, { _id: 3, type: "SELL" }]
    this.tradeTypeDropDownSettings = {
      singleSelection: true,
      idField: '_id',
      textField: 'type',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.tradestatusDropDown = [{ id: 1, status: "ALL" }, { id: 2, status: "Pending" }, { id: 3, status: "Dispute" }, { id: 4, status: "Complete" }, { id: 5, status: "Cancel" }]
    this.tradestatusDropDownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'status',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  tradeSearchWithList(event, pag) {

    this.nowDate = new Date();
    this.nowDate1 = new Date();
    this.nowDate2 = '';

    if (pag == 1) {
      this.tradepage = 1
    }
    if (event == 'reset') {
      this.tradeSearchForm.patchValue({
        tradeUniqueId: '',
        tradeStartDate: '',
        tradeEndDate: '',
        tradeStatus: '',
        tradePaymentMethod: '',
        tradeType: '',
        tradeShow: '',
      })
      var apireq = {
        "userId": this.id,
        "uniqueId": '',
        "paymentMethod": '',
        "tradeType": '',
        "status": '',
        "startDate": "",
        "endDate": "",
        "limit": 10,
        "page": this.tradepage
      }
    }
    else {
      var stat=(this.tradeSearchForm.value.tradeStatus != '') ? ((this.tradeSearchForm.value.tradeStatus[0].status != "ALL") ? this.tradeSearchForm.value.tradeStatus[0].status : '') : '';
      apireq = {
        "userId": this.id,
        "uniqueId": this.tradeSearchForm.value.tradeUniqueId != '' ? this.tradeSearchForm.value.tradeUniqueId[0].data : '',
        "paymentMethod": this.tradeSearchForm.value.tradePaymentMethod != '' ? this.tradeSearchForm.value.tradePaymentMethod[0].name : '',
        "tradeType": (this.tradeSearchForm.value.tradeType != '') ? ((this.tradeSearchForm.value.tradeType[0].type != "ALL") ? ((this.tradeSearchForm.value.tradeType[0].type == "BUY")?'buy':'sell') : '') : '',
        "status":  stat,
        "startDate": (this.tradeSearchForm.value.tradeStartDate == '' || this.tradeSearchForm.value.tradeStartDate == undefined) ? '' : (new Date(this.tradeSearchForm.value.tradeStartDate).toISOString()),
        "endDate": (this.tradeSearchForm.value.tradeEndDate == '' || this.tradeSearchForm.value.tradeEndDate == undefined) ? '' : (new Date(new Date(this.tradeSearchForm.value.tradeEndDate).getTime() + 24 * 60 * 60 * 1000).toISOString()),
        "limit": this.tradeSearchForm.value.tradeShow != 10 ? (this.tradeSearchForm.value.tradeShow != '' ? Number(this.tradeSearchForm.value.tradeShow[0].limit) : 10) : 10,
        "page": this.tradepage
      }
    }

    this.service.postApi("/api/v1/admin/userTrade", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.tradeData = success.body.result
        this.tradePaginationData = success.body.paginationData
        // this.tradeSrNo = ((this.tradepage - 1) * 10)
      } else {

        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  tradePagination(event) {
    this.tradepage = event;
    this.tradeSearchWithList("event", 2)
  }

  //********************************************************** wallet ********************************************************************************************

  wallet(event, pag) {
    this.nowDate = new Date();
    this.nowDate1 = new Date();
    this.nowDate2 = '';
    if (pag == 1) {
      this.page = 1
    }
    if (event == 'reset') {
      this.walletForm.patchValue({
        walletAddress: '',
        walletStartDate: '',
        walletEndDate: ''
      })
      var apireq = {
        "userId": this.id,
        "toAddress": '',
        "fromDate": '',
        "toDate": '',
        "pageNumber": this.page,
        "limit": 10
      }
    }
    else {
      apireq = {
        "userId": this.id,
        "toAddress": this.walletForm.value.walletAddress != '' ? this.walletForm.value.walletAddress[0].toAddress : '',
        "fromDate": (this.walletForm.value.walletStartDate == '' || this.walletForm.value.walletStartDate == undefined) ? '' : (new Date(this.walletForm.value.walletStartDate).toISOString()),
        "toDate": (this.walletForm.value.walletEndDate == '' || this.walletForm.value.walletEndDate == undefined) ? '' : (new Date(new Date(this.walletForm.value.walletEndDate).getTime() + 24 * 60 * 60 * 1000).toISOString()),
        "pageNumber": this.page,
        "limit": 10
      }
    }
    this.service.postApi("/api/v1/admin/userWallet", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.walletTableData = success.body.result
        this.toAddressArr = []
        this.finalwalletAddressArr = []
        var address = success.body.address
        for (let i = 0; i < address.length; i++) {
          if (address[i].toAddress) {
            this.toAddressArr.push(address[i])
          }
        }
        this.finalwalletAddressArr = this.toAddressArr
        this.walletAddressdropdownSettings = {
          singleSelection: true,
          idField: '_id',
          textField: 'toAddress',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        }
      }
      else {

        if (success.body.responseCode == 404) {
          this.walletTableData['docs'] = []
        }
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  walletPagination(event) {
    this.page = event;
    this.wallet('event', 3)
  }

  //************************************************* feedback *************************************************

  feedback(event, pag) {
    this.nowDate = new Date();
    this.nowDate1 = new Date();
    this.nowDate2 = '';

    if (pag == 1) {
      this.page = 1
    }
    if (event == 'reset') {
      var apireq = {
        "feedbackTo": this.id,
        "fromDate": '',
        "toDate": '',
        "pageNumber": this.page,
        "limit": 10
      }
    }
    else {
      apireq = {
        "feedbackTo": this.id,
        "fromDate": (this.feedbackForm.value.feedbackStartDate == '' || this.feedbackForm.value.feedbackStartDate == undefined) ? '' : (new Date(this.feedbackForm.value.feedbackStartDate).toISOString()),
        "toDate": (this.feedbackForm.value.feedbackEndDate == '' || this.feedbackForm.value.feedbackEndDate == undefined) ? '' : (new Date(new Date(this.feedbackForm.value.feedbackEndDate).getTime() + 24 * 60 * 60 * 1000).toISOString()),
        "pageNumber": this.page,
        "limit": 10
      }
    }

    this.service.postApi("/api/v1/feedback/getFeedbackList", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.feedbackData = success.body.Data
        this.feedbacklength = success.body.Data.docs.length
      } else {
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  feedbackpagination(event) {
    this.page = event
    this.feedback('event', 3)
  }

  //************************************************* trusted by *************************************************

  trustedBy(event, pag) {
    this.nowDate = new Date();
    this.nowDate1 = new Date();
    this.nowDate2 = '';
    if (pag == 1) {
      this.trustPageNumber = 1
    }
    if (event == 'reset') {
      this.trustByForm.patchValue({
        trustUserName: '',
        fromDate: '',
        toDate: ''
      })
      var apireq = {
        "userId": this.id,
        "userName": '',
        "fromDate": '',
        "toDate": '',
        "limit": 10,
        "pageNumber": this.trustPageNumber
      }
    }
    else {
      apireq = {
        "userId": this.id,
        "userName": this.trustByForm.value.trustUserName != '' ? this.trustByForm.value.trustUserName[0].userName : '',
        "fromDate": (this.trustByForm.value.fromDate == '' || this.trustByForm.value.fromDate == undefined) ? '' : (new Date(this.trustByForm.value.fromDate).toISOString()),
        "toDate": (this.trustByForm.value.toDate == '' || this.trustByForm.value.toDate == undefined) ? '' : (new Date(new Date(this.trustByForm.value.toDate).getTime() + 24 * 60 * 60 * 1000).toISOString()),
        "limit": 10,
        "pageNumber": this.trustPageNumber
      }
    }

    this.service.postApi("/api/v1/trade/trustByOther", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.trustedData = success.body
        this.trustedUserNameDropDown = []
        this.finalTrustUserName = []
        for (let i = 0; i < success.body.userName.length; i++) {
          this.trustedUserNameDropDown.push({ id: i, userName: success.body.userName[i] })
        }
        this.finalTrustUserName = this.trustedUserNameDropDown
        this.userNamedropdownSettings = {
          singleSelection: true,
          idField: 'id',
          textField: 'userName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        }
      } else {
        this.trustedData['success'] = []
        this.finalTrustUserName = []
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  paginationtrust(event) {
    this.trustPageNumber = event
    this.trustedBy('jsgdsd', 2)
  }


  trustedByDelete(id, name) {
    this.trustedById = id;
    this.trustedByName = name
    $('#trustedByDeleteModal').modal({ backdrop: 'static', keyboard: false });
  }
  deleteTrusty() {
    let apireq = {
      "userId": this.id,
      "name": this.trustedByName
    }

    this.service.postApi("/api/v1/trade/deleteTrustBy", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.trustedBy('reset', 1)
        this.service.success(success.body.responseMessage)
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }


  //*********************************************************** Blocked by *********************************************/

  blockedBy(event, pag) {
    this.nowDate = new Date();
    this.nowDate1 = new Date();
    this.nowDate2 = '';
    if (pag == 1) {
      this.blockPageNumber=1
    }
    if (event == 'reset') {

      var apireq = {
        "userId": this.id,
        "userName": '',
        "fromDate": '',
        "toDate": '',
        "limit": 10,
        "pageNumber": this.blockPageNumber
      }
      this.blockByForm.patchValue({
        fromDate: '',
        toDate: '',
      })
    }
    else {
      apireq = {
        "userId": this.id,
        "userName": this.blockByForm.value.blockByUserName != '' ? this.blockByForm.value.blockByUserName[0].userName : '',
        "fromDate": (this.blockByForm.value.fromDate == '' || this.blockByForm.value.fromDate == undefined) ? '' : (new Date(this.blockByForm.value.fromDate).toISOString()),
        "toDate": (this.blockByForm.value.toDate == '' || this.blockByForm.value.toDate == undefined) ? '' : (new Date(new Date(this.blockByForm.value.toDate).getTime() + 24 * 60 * 60 * 1000).toISOString()),
        "limit": 10,
        "pageNumber": this.blockPageNumber
      }
    }
    this.service.postApi("/api/v1/trade/blockByOther", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        var userNameHai = []
        this.blockedByList = success.body.success
        this.pageData = success.body.paginationData
        var userName = success.body.userName
        for(let i=0;i<userName.length;i++){
          userNameHai.push({id:i,userName:userName[i]})
        }
        this.bolckByUserName = userNameHai
        this.bolckByUserNamedropdownSettings = {
          singleSelection: true,
          idField: 'id',
          textField: 'userName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        }
      }
      else {
        this.blockedByList = []
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })

  }

  deleteBlockByFunc(name) {
    this.deleteBlockName = name
    $('#blockedByDeleteModal').modal({ backdrop: 'static', keyboard: false });
  }

  blockedBydelete() {
    let apireq = {
      "userId": this.id,
      "name": this.deleteBlockName
    }

    this.service.postApi("/api/v1/trade/deleteBlockedBy", apireq, 1).subscribe(succes => {
      if (succes.body.responseCode == 200) {
        this.blockedBy('reset', 1)
      }
      else {
        this.service.error(succes.body.responseMessage)
        if (succes.body.responseCode == 409 || succes.body.responseCode == 410 || succes.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  getId() {
    this.activatedRoute.params.subscribe(paramsId => {
      this.id = paramsId.id;
    });
  }

  pagination(event) {
    this.page = event;
    this.subadminLoginHistory()
  }
  //.......................................subadminLoginHistory ....................................................

  subAdminPagination(event) {
    this.page = event
    this.subadminLoginHistory()
  }

  subadminLoginHistory() {
    let apireq = {
      "type": "USER",
      "userId": this.id,
      "limit": 3,
      "pageNumber": this.page
    }
    this.service.postApi("/api/v1/admin/subadminLoginHistory", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.subadminLoginHistoryArr = []
        let temp = success.body.result;
        for (let i = 0; i < temp.length; i++) {
          this.subadminLoginHistoryArr.push({
            "login_date": temp[i].login_history.login_date,
            // "system_ip": (temp[i].login_history.system_ip).slice(7),
            "system_ip": temp[i].login_history.system_ip,
            "browser": temp[i].login_history.browser
          })
        }
        this.limit = success.body.paginationData.limit;
        this.totalRecords = success.body.paginationData.total;
        this.currentPage = success.body.paginationData.currentPage;
      } else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    })
  }
  //..........................//#endregion...........................................................................

  userActiveInactiveModal(status) {
    this.userStatus = status
    $('#activeInactive').modal({ backdrop: 'static', keyboard: false });
  }

  activeInactiveViewedUser(status) {

    let apireq = {
      "userId": this.id,
      "status": status
    }
    this.service.postApi("/api/v1/admin/blockUser", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.flag = 2
        this.userDetail()
        this.activeInactiveStatus = success.body.data.status
        this.service.success(success.body.responseMessage)
      } else {
        this.service.error(success.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }


  smsandgoogleAuthvarificationfunc(manageModal, msg) {
    this.manageModal = manageModal
    this.msg = msg
    $('#SMSGoogleAuth').modal({ backdrop: 'static', keyboard: false });
  }

  activeInactiveSMSViewedUser() {
    if (this.msg == 'User phone varification has been disable') {
      this.apireqhere = {
        "userId": this.id,
        "action": this.msg,
        "verified_phone": false,
        "opt_key":false
      }
    }
    else {
      if (this.msg == "User phone varification has been enable") {
        this.apireqhere = {
          "userId": this.id,
          "action": this.msg,
          "verified_phone": true,
          "opt_key":true
        }
      }
      else {
        if (this.msg == 'User google two factor link has been disable') {
          this.apireqhere = {
            "userId": this.id,
            "action": this.msg,
            "googleTwofactorLink": false
          }
        }
        else {
          if (this.msg == 'User google two factor link has been enable') {
            this.apireqhere = {
              "userId": this.id,
              "action": this.msg,
              "googleTwofactorLink": true
            }
          }
        }
      }
    }

    this.service.postApi("/api/v1/admin/statusChange", this.apireqhere, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.userDetail()
        this.service.success(success.body.responseMessage)
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

}
