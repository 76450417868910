import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MyServiceService } from '../../my-service.service';

declare var $: any;

@Component({
  selector: 'app-edit-static-content-management',
  templateUrl: './edit-static-content-management.component.html',
  styleUrls: ['./edit-static-content-management.component.css']
})
export class EditStaticContentManagementComponent implements OnInit {
  staticForm: FormGroup
  id: any;
  type: any;
  userdata: any;
  description: any;
  constructor(private route: Router, private router: ActivatedRoute, private service: MyServiceService) {
    this.router.params.subscribe(params => {
      this.id = params
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getPermissions()
    this.staticForm = new FormGroup({
      title: new FormControl('', [Validators.required,Validators.pattern('[A-Za-z][A-Za-z \\s]*$')]),
      content: new FormControl('', [Validators.required])
    });
  }

  getPermissions() {
    let apireq = {
      "userId": localStorage.getItem("_id")
    }
    this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.userdata = success.body.data.userType
        this.staticContent()
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.route.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  staticContent() {
    this.service.postApi('/api/v1/static/staticContentById', this.id, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.staticForm.get('title').setValue(success.body.Data.title);
        this.type = success.body.Data.Type;
        if (this.userdata == "ADMIN") {
          this.staticForm.get('content').setValue(success.body.Data.description);
          return;
        }
        if (this.userdata == "SUBADMIN") {
          this.description = success.body.Data.description
          document.getElementById("content12").innerHTML = this.description;
        }
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.route.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  contentData(data) {
    if (this.staticForm.invalid) {
      return
    }
    let apiData = {
      "type": this.type,
      "title": data.title,
      "description": data.content,
      "_id": this.id.id
    }
    this.service.postApi('/api/v1/static/StaticContentUpdate', apiData, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.route.navigate(["/admin/staticContentManagement"])
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.route.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }


  back() {
    this.route.navigate(["/admin/staticContentManagement"])
  }
}
