import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tofixedto8'
})
export class Tofixedto8Pipe implements PipeTransform {

  transform(value: any, args: any): any {
    let tofixvalue = Number(value);
    let valuetoSend = tofixvalue.toFixed(args);
    return valuetoSend;
  }

}
