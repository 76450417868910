import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login/login.component';
import { UserDetailsComponent } from './userDetails/user-details/user-details.component';
import { AdvertisementComponent } from './advertisement/advertisement/advertisement.component';
import { TradesComponent } from './trades/trades.component';
import { MyGuardGuard } from './my-guard.guard';
import { AddAdminstratorComponent } from './addAdminstrator/add-adminstrator/add-adminstrator.component';
import { EditAdvertisementComponent } from './advertisement/edit-advertisement/edit-advertisement.component';
import { UserManagementComponent } from './userDetails/user-management/user-management.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { TradeDetailsComponent } from './trades/trade-details/trade-details.component';
import { KYCManagementComponent } from './KYCManagement/kycmanagement/kycmanagement.component';
import { KYCReviewComponent } from './KYCManagement/kycreview/kycreview.component';
import { FinanceManagementComponent } from './FinanceManagement/finance-management/finance-management.component';
import { DisputeManagementComponent } from './DisputeManagement/dispute-management/dispute-management.component';
import { EditUserDataComponent } from './userDetails/edit-user-data/edit-user-data.component';
import { TrackStaffActionsComponent } from './staff/track-staff-actions/track-staff-actions.component';
import { UserDetailLoginHistoryComponent } from './userDetails/user-detail-login-history/user-detail-login-history.component';
import { EditAdminUserProfileComponent } from './userDetails/edit-admin-user-profile/edit-admin-user-profile.component';
import { SystemManagementComponent } from './systemManagement/system-management/system-management.component';
import { PaymentMethodComponent } from './systemManagement/payment-method/payment-method.component';
import { StaticContentManagementComponent } from './StaticContentManagement/static-content-management/static-content-management.component';
import { EditStaticContentManagementComponent } from './StaticContentManagement/edit-static-content-management/edit-static-content-management.component';
import { TradeDisputeHistoryComponent } from './trades/trade-dispute-history/trade-dispute-history.component';
import { SidebarComponent } from './sidebar/sidebar/sidebar.component';
import { TagManagementComponent } from './tags/tag-management/tag-management.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordMainComponent } from './forgot-password-main/forgot-password-main.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { VerifyEmailMessageComponent } from './verify-email-message/verify-email-message.component';
import { EditFAQComponent } from './StaticContentManagement/edit-faq/edit-faq.component';
import { AdminWalletBalanceComponent } from './admin-wallet-balance/admin-wallet-balance.component';

  const routes: Routes = [
    {path: '',redirectTo:"login", pathMatch:"full"},
    { path:"login",component:LoginComponent },
    { path:"forgotPassword/:_id/:token",component:ForgotPasswordComponent },
    { path:"forgotPasswordMain",component:ForgotPasswordMainComponent },
    { path: "admin", component:SidebarComponent,
      children:[
          { path: "dashboard", component:DashboardComponent , canActivate:[MyGuardGuard] },
          { path: "adminWalletBalance", component:AdminWalletBalanceComponent , canActivate:[MyGuardGuard] },
          { path: "userDetails/:id" , component:UserDetailsComponent , canActivate:[MyGuardGuard] },
          { path: "advertisement", component:AdvertisementComponent , canActivate:[MyGuardGuard] },
          { path: "trades", component:TradesComponent , canActivate:[MyGuardGuard] },
          { path: "addAdminstrator", component:AddAdminstratorComponent},
          { path: "editAdvertisement/:userId/:status", component:EditAdvertisementComponent , canActivate:[MyGuardGuard] },
          { path: "userManagement/:type", component:UserManagementComponent , canActivate:[MyGuardGuard] },
          { path: "tradeDetails/:id", component:TradeDetailsComponent , canActivate:[MyGuardGuard] },
          { path: "KYCManagement", component:KYCManagementComponent , canActivate:[MyGuardGuard] },
          { path: "KYCReview/:userId/:doc_Id", component: KYCReviewComponent , canActivate:[MyGuardGuard] },
          { path : "finance", component:FinanceManagementComponent , canActivate:[MyGuardGuard] },
          { path: "disputeManagement", component:DisputeManagementComponent , canActivate:[MyGuardGuard] },
          { path: "editUserData/:id", component:EditUserDataComponent , canActivate:[MyGuardGuard] },
          { path: "trackStaffAction", component:TrackStaffActionsComponent , canActivate:[MyGuardGuard] },
          { path:"userDetailLoginHistory/:id", component:UserDetailLoginHistoryComponent , canActivate:[MyGuardGuard] },
          { path: "editAdminUserProfile/:id" , component : EditAdminUserProfileComponent , canActivate:[MyGuardGuard] },
          { path:"systemConfiguration" ,component:SystemManagementComponent , canActivate:[MyGuardGuard] },
          { path: "tradeDisputeHistory/:id/:tradeId" , component : TradeDisputeHistoryComponent , canActivate:[MyGuardGuard] },
          { path: "paymentMethod", component:PaymentMethodComponent , canActivate:[MyGuardGuard] },
          { path:"staticContentManagement",component:StaticContentManagementComponent , canActivate:[MyGuardGuard] },
          { path: "editStaticContentManagement/:id",component:EditStaticContentManagementComponent , canActivate:[MyGuardGuard] },
          { path: "tagManagement",component:TagManagementComponent , canActivate:[MyGuardGuard] },
          { path: "FAQ/:id",component:EditFAQComponent , canActivate:[MyGuardGuard] },
          // { path: '**', redirectTo: '/login',pathMatch: 'full'},
      ]
    },
    { path: 'pageNotFound', component:PageNotFoundComponent},
    { path: 'emailVerification/:id/:token', component:VerifyEmailMessageComponent},
    { path: '**', redirectTo: 'pageNotFound',pathMatch: 'full'},
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {RouterModule}
