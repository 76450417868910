import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MyServiceService } from 'src/app/my-service.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

declare var $: any;

@Component({
  selector: 'app-edit-admin-user-profile',
  templateUrl: './edit-admin-user-profile.component.html',
  styleUrls: ['./edit-admin-user-profile.component.css']
})
export class EditAdminUserProfileComponent implements OnInit {

  PatientForm: FormGroup
  id: any;
  access: any;
  role: any;
  modalForm: FormGroup;
  imageurl: any;
  details: any;
  atleastOne:number =2
  constructor(private myRoute: Router, private activatedRoute: ActivatedRoute, public service: MyServiceService, public spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.modalFormValidation();
    this.formValidation()
    this.getId()
    this.getDetail()
  }

  modalFormValidation() {
    this.modalForm = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[0-9]*')])
    })
  }

  formValidation() {
    this.PatientForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(256), Validators.pattern('[A-Za-z][A-Za-z \\s]*$')]),
      email: new FormControl('', [Validators.required, Validators.maxLength(256), Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)]),
      userName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(256)]),
      access: new FormControl('', [Validators.required]),
      dashBoard: new FormControl(''),
      userManagement: new FormControl(''),
      suspendUnsuspendUser: new FormControl(''),
      disable2FA: new FormControl(''),
      editUserData: new FormControl(''),
      seeLoginHistory: new FormControl(''),
      seeWalletAndBalance: new FormControl(''),
      feedbackAction: new FormControl(''),
      trustedByAction: new FormControl(''),
      blockedByAction: new FormControl(''),
      advertisement: new FormControl(''),
      enableDisabled: new FormControl(''),
      deleteAd: new FormControl(''),
      trades: new FormControl(''),
      disputeResolutionCenter: new FormControl(''),
      KYCVarification: new FormControl(''),
      finances: new FormControl(''),
      support: new FormControl(''),
      systemConfiguration: new FormControl('')
    })
  }

  getId() {
    this.activatedRoute.params.subscribe(paramsId => {
      this.id = paramsId.id;
    });
  }

  getDetail() {
    let apireq = {
      "userId": this.id
    }
    this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.details = success.body.data
        this.PatientForm.patchValue({
          name: success.body.data.name,
          email: success.body.data.email,
          userName: success.body.data.user_name,
          access: success.body.data.userType,
          dashBoard: success.body.data.permission[0].dashboard,
          userManagement: success.body.data.permission[0].userManagment,
          suspendUnsuspendUser: success.body.data.permission[0].um_suspend_unspend,
          disable2FA: success.body.data.permission[0].um_disable2FA,
          editUserData: success.body.data.permission[0].um_editUserData,
          seeLoginHistory: success.body.data.permission[0].seeLoginHistory,
          seeWalletAndBalance: success.body.data.permission[0].um_seewalletAndBalance,
          feedbackAction: success.body.data.permission[0].um_feedbackByAction,
          trustedByAction: success.body.data.permission[0].um_trustedByAction,
          blockedByAction: success.body.data.permission[0].um_blockedByAction,
          advertisement: success.body.data.permission[0].advertisment,
          enableDisabled: success.body.data.permission[0].ad_enableDisable,
          deleteAd: success.body.data.permission[0].ad_deleteAd,
          trades: success.body.data.permission[0].trades,
          disputeResolutionCenter: success.body.data.permission[0].dispute_resolution_center,
          KYCVarification: success.body.data.permission[0].kycVerification,
          finances: success.body.data.permission[0].finances,
          support: success.body.data.permission[0].support,
          systemConfiguration: success.body.data.permission[0].systemConfiguration
        })
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }


  onSubmit(data) {
    if (this.PatientForm.invalid) {
      if(this.PatientForm.value.dashBoard == false && this.PatientForm.value.userManagement == false &&
        this.PatientForm.value.suspendUnsuspendUser == false && this.PatientForm.value.disable2FA == false &&
        this.PatientForm.value.editUserData == false && this.PatientForm.value.seeLoginHistory == false &&
        this.PatientForm.value.seeWalletAndBalance == false && this.PatientForm.value.feedbackAction == false &&
        this.PatientForm.value.trustedByAction == false && this.PatientForm.value.blockedByAction == false &&
         this.PatientForm.value.advertisement == false &&
        this.PatientForm.value.enableDisabled == false &&
        this.PatientForm.value.deleteAd == false && this.PatientForm.value.trades == false &&
        this.PatientForm.value.disputeResolutionCenter == false &&
        this.PatientForm.value.KYCVarification == false && this.PatientForm.value.finances == false &&
        this.PatientForm.value.support == false && this.PatientForm.value.systemConfiguration == false){
          this.atleastOne = 1
          return;
        }
        else{
          this.atleastOne = 2
        }
      return
    }
    if(this.PatientForm.value.dashBoard == false && this.PatientForm.value.userManagement == false &&
      this.PatientForm.value.suspendUnsuspendUser == false && this.PatientForm.value.disable2FA == false &&
      this.PatientForm.value.editUserData == false && this.PatientForm.value.seeLoginHistory == false &&
      this.PatientForm.value.seeWalletAndBalance == false && this.PatientForm.value.feedbackAction == false &&
      this.PatientForm.value.trustedByAction == false && this.PatientForm.value.blockedByAction == false &&
       this.PatientForm.value.advertisement == false &&
      this.PatientForm.value.enableDisabled == false && 
      this.PatientForm.value.deleteAd == false && this.PatientForm.value.trades == false &&
      this.PatientForm.value.disputeResolutionCenter == false &&
      this.PatientForm.value.KYCVarification == false && this.PatientForm.value.finances == false &&
      this.PatientForm.value.support == false && this.PatientForm.value.systemConfiguration == false){
        this.atleastOne = 1
        return;
      }


    this.spinnerService.show();
    let apiData = {
      "name": data.name,
      "email": data.email,
      "user_name": data.userName,
      "type": this.details.userType,
      "id": this.id,
      "permission": [{
        "dashboard": data.dashBoard,
        "userManagment": data.userManagement,
        "dispute_resolution_center": data.disputeResolutionCenter,
        "kycVerification": data.KYCVarification,
        "finances": data.finances,
        "support": data.support,
        "systemConfiguration": data.systemConfiguration,
        "um_suspend_unspend": data.suspendUnsuspendUser,
        "um_disable2FA": data.disable2FA,
        "um_editUserData": data.editUserData,
        "seeLoginHistory": data.seeLoginHistory,
        "um_seewalletAndBalance": data.seeWalletAndBalance,
        "um_feedbackByAction": data.feedbackAction,
        "um_trustedByAction": data.trustedByAction,
        "um_blockedByAction": data.blockedByAction,
        "advertisment": data.advertisement,
        "ad_enableDisable": data.enableDisabled,
        "ad_deleteAd": data.deleteAd,
        "trades": data.trades,
      }]
    }
    this.service.postApi("/api/v1/admin/profileUpdate", apiData, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.service.success(success.body.responseMessage);
        this.imageurl = success.body.obj;
        this.myRoute.navigate(["/admin/userManagement/staff"])
        this.spinnerService.hide();
      }
      else {
        this.service.error(success.body.responseMessage);
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
        this.spinnerService.hide();
      }
    }, error => {
      this.service.error("Something went wrong")
      this.spinnerService.hide();
    })
  }

  verify2FA(code) {
    if (this.modalForm.invalid) {
      return
    }
    let apireq = {
      "googleToken": code.code,
      "id": this.id,
      "type": this.details.userType
    }
    this.service.postApi("/api/v1/admin/verifyTwoFactorAuth", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {

      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
            this.service.error(success.body.responseMessage)
            localStorage.removeItem('LOGINTOKEN');
            localStorage.removeItem('role');
            localStorage.removeItem('_id');
            this.myRoute.navigate(["/login"]);
          }
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  userManagement(value) {
    if (value == true) {
      this.PatientForm.patchValue({
        suspendUnsuspendUser: true,
        disable2FA: true,
        editUserData: true,
        seeLoginHistory: true,
        seeWalletAndBalance: true,
        feedbackAction: true,
        trustedByAction: true,
        blockedByAction: true,
      });
    }
    else {
      this.PatientForm.patchValue({
        suspendUnsuspendUser: false,
        disable2FA: false,
        editUserData: false,
        seeLoginHistory: false,
        seeWalletAndBalance: false,
        feedbackAction: false,
        trustedByAction: false,
        blockedByAction: false,
      });
    }
  }

  advertisement(value) {
    if (value == true) {
      this.PatientForm.patchValue({
        enableDisabled: true,
        deleteAd: true,
      });
    }
    else {
      this.PatientForm.patchValue({
        enableDisabled: false,
        deleteAd: false,
      });
    }
  }

  selectPermission(){
    if(this.PatientForm.value.dashBoard == false && this.PatientForm.value.userManagement == false &&
      this.PatientForm.value.suspendUnsuspendUser == false && this.PatientForm.value.disable2FA == false &&
      this.PatientForm.value.editUserData == false && this.PatientForm.value.seeLoginHistory == false &&
      this.PatientForm.value.seeWalletAndBalance == false && this.PatientForm.value.feedbackAction == false &&
      this.PatientForm.value.trustedByAction == false && this.PatientForm.value.blockedByAction == false &&
       this.PatientForm.value.advertisement == false &&
      this.PatientForm.value.enableDisabled == false && 
      this.PatientForm.value.deleteAd == false && this.PatientForm.value.trades == false &&
      this.PatientForm.value.disputeResolutionCenter == false &&
      this.PatientForm.value.KYCVarification == false && this.PatientForm.value.finances == false &&
      this.PatientForm.value.support == false && this.PatientForm.value.systemConfiguration == false){
        this.atleastOne = 1
        return;
      }
      else{
        this.atleastOne = 2
      }
  }
}
