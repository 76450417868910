import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MyServiceService } from '../my-service.service';

@Component({
  selector: 'app-verify-email-message',
  templateUrl: './verify-email-message.component.html',
  styleUrls: ['./verify-email-message.component.css']
})
export class VerifyEmailMessageComponent implements OnInit {

  id: any
  responseMessage: any
  constructor(private activatedRoute: ActivatedRoute, public service: MyServiceService, private myRoute: Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getId()
    this.api()
  }

  getId() {
    this.activatedRoute.params.subscribe(paramsId => {
      this.id = paramsId;
    });
  }

  api() {
    let apireq = {
      "_id": this.id.id,
      "token": this.id.token
    }
    this.service.postApi("/api/v1/admin/resetTwoFA", apireq, 0).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.responseMessage = success.body.responseMessage
      }
      else {
        this.responseMessage = success.body.responseMessage
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.responseMessage = "Something went wrong"
    })
  }
}
