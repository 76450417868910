import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { MyServiceService } from 'src/app/my-service.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

declare var $: any
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  subadminList = [];
  dropdownList = [];
  message: any;
  countryMultiselectorData: any = []
  subadminFirstNameMultiselectorData: any = []
  userNamedropdownSettings = {};
  userNameMultiselectorData: any = [];
  userEmaildropdownSettings: any = {};
  userPhoneNumberdropdownSettings: any = {};
  countrydropdownSettings: any = {};
  statusdropdownSettings: any = {}
  statusdropdownList: any = []
  showdropdownList: any = []
  uniqueIdropdownSettings: any = {}
  showdropdownSettings: any = {}
  subadminFirstNamedropdownSettings: any = {}
  limit: any;
  totalRecords: any;
  PatientSearchForm: FormGroup
  modalId: any
  userPages: number = 1;
  adminPages: number = 1;
  userListArr = [];
  userLimit: number = 2;
  totalRecordsofUser: number = 3;
  searchdata: { email: any; userName: any; status: any; };
  status: string;
  countryArr: any;
  subadminsearchForm: any;
  userStatus: string;
  userId: any;
  pages: number = 1;
  userType: any;
  loginUserType: any
  url: any;
  phoneNumber: any = []
  permission: any
  deleteType: any
  tabManage:any
  type:any
  constructor(private myRoute: Router, public service: MyServiceService, public spinnerService: Ng4LoadingSpinnerService, private activatedRoute: ActivatedRoute) {
    this.manageRounting();
    this.PatientSearchForm = new FormGroup({
      useruniqueId: new FormControl(''),
      name: new FormControl(''),
      email: new FormControl(''),
      phoneNumber: new FormControl(''),
      status: new FormControl(''),
      show: new FormControl(10),
      country: new FormControl(''),
    });
    this.subadminsearchForm = new FormGroup({
      uniqueId: new FormControl(''),
      SubAdminName: new FormControl(''),
      SubAdminEmail: new FormControl(''),
      SubAdminFirstName: new FormControl(''),
      status: new FormControl(''),
      show: new FormControl('')
    })
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getType()
    this.tabManage = 'staff'
    this.loginUserType = localStorage.getItem("role")
    this.countryList()
    this.countryArr = this.service.countryArr
  }

  getType(){
    this.activatedRoute.params.subscribe(paramsId => {
      if(paramsId.type == 'user'){
        this.userSearch('reset', 'USER', 1)
        this.allNames('USER')
      }
      else{
        this.adminSearchApi('reset','SUBADMIN',1)
        this.allNames('SUBADMIN')
      }
      this.type = paramsId.type;
    });
  }

  changeTabToStaff(){
    this.type='staff'
  }
  changeTabToUser(){
    this.type='user'
  }

  manageRounting() {
    this.myRoute.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        var splitUrl = this.url.split("/")
        if (splitUrl[2] == "userManagement") {
          let apireq = {
            "userId": localStorage.getItem("_id")
          }
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
            if (success.body.responseCode == 200) {
              this.permission = success.body.data
              if (success.body.data.permission[0].userManagment == false) {
                this.myRoute.navigate(["/pageNotFound"]);
              }
            }
            else {
              this.service.error(success.body.responseMessage)
              if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
                localStorage.removeItem('LOGINTOKEN');
                localStorage.removeItem('role');
                localStorage.removeItem('_id');
                this.myRoute.navigate(["/login"]);
              }
            }
          }, error => {
            this.service.error("Something went wrong")
          })
        }

      }
    })
  }

  countryList() {
    var countryArr1 = []
    var country = this.service.countryArr
    for (let i = 0; i < country.length; i++) {
      countryArr1.push({ id: i, country: country[i].name })
    }
    this.countryMultiselectorData = countryArr1
    this.countrydropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'country',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  allNames(type) {
    if (type == 'USER') {
      var apireq = {
        "userType": type
      }
    }
    else {
      apireq = {
        "userType": ''
      }
    }

    this.service.postApi("/api/v1/admin/emailList", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.userNameMultiselectorData = success.body.result
        this.service.userNameMultiselectorData = this.userNameMultiselectorData
        // this.countryMultiselectorData = success.body.country
        this.subadminFirstNameMultiselectorData = success.body.name
        this.service.usernameMultiselectorInService()
        this.usernameMultiselector()
      }

      else
        this.service.error(success.body.responseMessage)
      if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
        localStorage.removeItem('LOGINTOKEN');
        localStorage.removeItem('role');
        localStorage.removeItem('_id');
        this.myRoute.navigate(["/login"]);
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }
  // *************************************** multiselector **********************************************
  usernameMultiselector() {
    this.dropdownList = this.service.dropdownList;
    this.phoneNumber = this.service.phone;
    this.userNamedropdownSettings = this.service.userNamedropdownSettings;
    this.userEmaildropdownSettings = this.service.userEmaildropdownSettings;
    this.userPhoneNumberdropdownSettings = this.service.userPhoneNumberdropdownSettings;
    this.countrydropdownSettings = this.service.countrydropdownSettings;
    this.statusdropdownList = this.service.statusdropdownList;
    this.statusdropdownSettings = this.service.statusdropdownSettings;
    this.showdropdownList = this.service.showdropdownList
    this.showdropdownSettings = this.service.showdropdownSettings
    this.subadminFirstNamedropdownSettings = this.service.subadminFirstNamedropdownSettings;
    this.uniqueIdropdownSettings = {
      singleSelection: true,
      idField: '_id',
      textField: 'uniqueId',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }

  // ***************************************** USER ********************************************
  userSearch(event, type, pag) {
    if (pag == 1) {
      this.userPages = 1
    }
    if (event == "reset") {
      var searchapireq = {
        "uniqueId": '',
        "userName": '',
        "email": '',
        "phoneNumber": '',
        "status": '',
        "country": '',
        "limit": 10,
        "page": this.userPages,
        "type": type
      }
      this.PatientSearchForm.patchValue({
        useruniqueId: '',
        name: '',
        email: '',
        phoneNumber: '',
        status: '',
        show: 10,
        country: '',
      });
    }
    else {
      searchapireq = {
        "uniqueId": this.PatientSearchForm.value.useruniqueId != '' ? this.PatientSearchForm.value.useruniqueId[0].uniqueId : '',
        "userName": this.PatientSearchForm.value.name != '' ? this.PatientSearchForm.value.name[0].user_name : '',
        "email": this.PatientSearchForm.value.email != '' ? this.PatientSearchForm.value.email[0].email : '',
        "phoneNumber": this.PatientSearchForm.value.phoneNumber != '' ? this.PatientSearchForm.value.phoneNumber[0].phone_number : '',
        "status": (this.PatientSearchForm.value.status != '') ? ((this.PatientSearchForm.value.status[0].status != "ALL") ? this.PatientSearchForm.value.status[0].status : '') : '',
        "country": this.PatientSearchForm.value.country != '' ? this.PatientSearchForm.value.country[0].country : '',
        "limit": this.PatientSearchForm.value.show != 10 ? (this.PatientSearchForm.value.show != '' ? Number(this.PatientSearchForm.value.show[0].limit) : 10) : 10,
        "page": this.userPages,
        "type": type
      }
    }
    this.service.postApi("/api/v1/user/userList", searchapireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.userListArr = success.body.result;
        this.userLimit = success.body.paginationData.limit;
        this.totalRecordsofUser = success.body.paginationData.total;
      } else {
        this.service.error(success.body.responseMessage)

        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  // ****************************************** PAGINATION ********************************************
  paginationUserList(event) {
    this.userPages = event;
    this.userSearch("user", "USER", 3);
  }
  paginationAdminList(event) {
    this.adminPages = event;
    this.adminSearchApi('erer', 'SUBADMIN', 2);
  }

  // *********************************************** ADMIN ***********************************************

  adminSearchApi(event, type, pag) {
    if (pag == 1) {
      this.adminPages = pag
    }
    if (event == "reset") {
      var subAdminApiReq = {
        "uniqueId": '',
        "name": '',
        "userName": '',
        "email": '',
        "status": '',
        "country": "",
        "limit": 10,
        "page": this.adminPages,
        "staffType": type
      }
      this.subadminsearchForm.patchValue({
        SubAdminName: '',
        SubAdminEmail: '',
        SubAdminFirstName: '',
        status: '',
        show: 10
      })
    }
    else {
      subAdminApiReq = {
        "uniqueId": this.subadminsearchForm.value.uniqueId != '' ? this.subadminsearchForm.value.uniqueId[0].uniqueId : '',
        "name": this.subadminsearchForm.value.SubAdminFirstName != '' ? this.subadminsearchForm.value.SubAdminFirstName[0].name : '',
        "userName": this.subadminsearchForm.value.SubAdminName != '' ? this.subadminsearchForm.value.SubAdminName[0].user_name : '',
        "email": this.subadminsearchForm.value.SubAdminEmail != '' ? this.subadminsearchForm.value.SubAdminEmail[0].email : '',
        "status": (this.subadminsearchForm.value.status != '') ? ((this.subadminsearchForm.value.status[0].status != "ALL") ? this.subadminsearchForm.value.status[0].status : '') : '',
        "country": "",
        "limit": this.subadminsearchForm.value.show != 10 ? (this.subadminsearchForm.value.show != '' ? Number(this.subadminsearchForm.value.show[0].limit) : 10) : 10,
        "page": this.adminPages,
        "staffType": type
      }
    }

    this.service.postApi("/api/v1/user/userList", subAdminApiReq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.subadminList = success.body.result;
        this.limit = success.body.paginationData.limit
        this.totalRecords = success.body.paginationData.total
        this.pages = success.body.paginationData.currentPage
      } else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  //............................................//#endregion.................................................

  userProfile() {
    this.myRoute.navigate(['/admin/userDetailLoginHistory'])
  }
  //.................................Active Deactive user ....................................................
  viewModal(id, status, userId, userType) {
    this.deleteType = userType
    this.userStatus = status
    this.modalId = id
    this.userId = userId
    $('#delete').modal({ backdrop: 'static', keyboard: false });
  }
  delete() {
    this.spinnerService.show();
    let apireq = {
      "userId": this.userId,
      "status": this.userStatus
    }
    this.service.postApi("/api/v1/admin/blockUser", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        if (this.deleteType == 'USER') {
          this.userSearch('reset', 'USER', 1)
          this.allNames('USER')
        }
        else {
          this.adminSearchApi('erer', 'SUBADMIN', 1);
          this.allNames("SUBADMIN")
        }
        this.spinnerService.hide();
        this.service.success(success.body.responseMessage)
      } else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
        this.spinnerService.hide();
      }
    }, error => {
      this.service.error("Something went wrong")
      this.spinnerService.hide();
    })
  }
  //....................................//#endregion..................................................................
  addUser() {
    this.myRoute.navigate(["/admin/addAdminstrator"]);
  }
  adminInfo(id) {
    this.myRoute.navigate(["/admin/userDetails/" + id]);
  }

  staffActions() {
    this.myRoute.navigate(['/admin/trackStaffAction'])
  }

  editAdminProfile(adminId) {
    this.myRoute.navigate(['/admin/editAdminUserProfile/' + adminId])
  }

}
