import { Injectable } from "@angular/core";
import * as io from "socket.io-client";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class SocketServiceService {
  public socket;
  socketUrl: any;
  constructor() {}

  /** Function for to call init function */
  initializeSocket() {
    this.socket = io(environment.ws_url);
    this.initConnection();
  }

  /** Function for socket init */
  initConnection() {
    if (localStorage.getItem("LOGINTOKEN") != null) {
      this.socket.emit("connection", { userId: localStorage.getItem("_id") });
    }
  }
  public onEvent(event): Observable<any> {
    return new Observable<Event>(observer => {
      this.socket.on(event, () => observer.next());
    });
  }
}
