import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MyServiceService } from '../../my-service.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any

@Component({
  selector: 'app-kycreview',
  templateUrl: './kycreview.component.html',
  styleUrls: ['./kycreview.component.css']
})
export class KYCReviewComponent implements OnInit {
  idScanFront: any
  idScanBack: string;
  selfieId: any
  kYCData: any;
  rejectModal: any
  id: any
  status: any;
  constructor(private myRoute: Router, public spinnerService: Ng4LoadingSpinnerService, private service: MyServiceService, private activatedRoute: ActivatedRoute) {
    this.idScanFront = "src/assets/img/id1.jpg";
    this.idScanBack = "src/assets/img/id3.jpg";
    this.selfieId = "src/assets/img/id2.jpeg";

    this.getKYCDetail();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.rejectModal = new FormGroup({
      reason: new FormControl('', [Validators.required]),
      notifyUser: new FormControl(false),
    })
  }

  getKYCDetail() {
    this.activatedRoute.params.subscribe(params => {

      this.id = params
    });
    this.service.postApi('/api/v1/admin/viewParticularKYC', this.id, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.kYCData = success.body.success
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }
  //............Accept kYC....................................
  acceptKYC() {
    $('#accept').modal({ backdrop: 'static', keyboard: false });

  }

  AcceptModal() {
    let apiData = {
      "Type": "APPROVED",
      "userId": this.id.userId,
      "doc_Id": this.id.doc_Id,
      "ownerId": localStorage.getItem('_id')
    }
    this.spinnerService.show();
    this.service.postApi('/api/v1/user/kycAction', apiData, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.service.success(success.body.responseMessage)
        this.myRoute.navigate(["/admin/KYCManagement"]);

        this.spinnerService.hide()

      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.spinnerService.hide()
      this.service.error("Something went wrong")
    })
  }

  //...........Reject KYC..................................
  rejectKYC() {
    $('#reject').modal({ backdrop: 'static', keyboard: false });

  }
  rejectModalFunc(data) {
    if (this.rejectModal.invalid) {
      return
    }
    $('#reject').modal('hide')
    let apiData = {
      "Type": "REJECTED",
      "userId": this.id.userId,
      "doc_Id": this.id.doc_Id,
      "notifyUser": data.notifyUser,
      "comment": data.reason,
      "ownerId": localStorage.getItem('_id')
    }
    this.spinnerService.show();
    this.service.postApi('/api/v1/user/kycAction', apiData, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.service.success(success.body.responseMessage)
        this.myRoute.navigate(["/admin/KYCManagement"]);

        this.spinnerService.hide()

      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.spinnerService.hide()
      this.service.error("Something went wrong")
    })
  }

  back() {
    window.history.back();
  }
}
