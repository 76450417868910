import { Component, OnInit } from '@angular/core';
import { MyServiceService } from '../my-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  imageurl:any
  constructor(public service:MyServiceService,public router:Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  goToHome(){
    if(localStorage.getItem("_id")){
      let apireq = {
        "userId": localStorage.getItem("_id")
      }
      this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
        if (success.body.responseCode == 200) {
          this.imageurl = success.body.data;
          if (this.imageurl.permission[0].dashboard == true) {
            this.router.navigate(["/admin/dashboard"]);
          }
          else {
            if (this.imageurl.permission[0].userManagment == true) {
              this.router.navigate(["/admin/userManagement/user"]);
            }
            else {
              if (this.imageurl.permission[0].advertisment == true) {
                this.router.navigate(["/admin/advertisement"]);
              }
              else {
                if (this.imageurl.permission[0].trades == true) {
                  this.router.navigate(["/admin/trades"]);
                }
                else {
                  if (this.imageurl.permission[0].dispute_resolution_center == true) {
                    this.router.navigate(["/admin/disputeManagement"]);
                  }
                  else {
                    if (this.imageurl.permission[0].kycVerification == true) {
                      this.router.navigate(["/admin/KYCManagement"]);
                    }
                    else {
                      if (this.imageurl.permission[0].finances == true) {
                        this.router.navigate(["/admin/finance"]);
                      }
                      else {
                        if (this.imageurl.permission[0].systemConfiguration == true) {
                          this.router.navigate(["/admin/systemConfiguration"]);
                        }
                        else {
                          this.router.navigate(["/login"]);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }, error => {
        this.service.error("Something went wrong")
      })
    }
    else{
      this.router.navigate(['/login'])
    }
  }

}
