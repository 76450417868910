import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Router , ActivatedRoute, NavigationEnd } from '@angular/router';
import { MyServiceService } from '../my-service.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassword: any;
  id: any;
  url:any

  constructor(private myRoute: Router, public spinnerService: Ng4LoadingSpinnerService, private service:MyServiceService,public activatedroute : ActivatedRoute) { 
    this.getId();
    this.manageRouting()
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.forgotPassword=new FormGroup({
      newPassword: new FormControl('',[Validators.required,Validators.minLength(8),Validators.maxLength(16)]),
      confirmPassword: new FormControl('',[Validators.required,Validators.minLength(8),Validators.maxLength(16)])
    })
    
  }

  manageRouting(){
    this.myRoute.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        var splitUrl = this.url.split("/")
        if (splitUrl[1] == "forgotPassword") {
          let apireq = {
            "userId":this.id._id,
            "token":this.id.token
          }
          this.service.postApi("/api/v1/admin/verifyUser", apireq, 0).subscribe((success) => {
            if (success.body.responseCode == 200) {
            }
            else {
              this.myRoute.navigate(["/pageNotFound"]);
              this.service.error(success.body.responseMessage)
              if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
                localStorage.removeItem('LOGINTOKEN');
                localStorage.removeItem('role');
                localStorage.removeItem('_id');
                this.myRoute.navigate(["/login"]);
              }
            }
          }, error => {
            this.service.error("Something went wrong")
          })
        }

      }
    })
  }

  getId(){
    this.activatedroute.params.subscribe(paramsId => {
      this.id = paramsId;
    });
  }

  onSubmit(data){
    if(this.forgotPassword.invalid){
      return;
    }
    if(data.newPassword!=data.confirmPassword){
      return;
    }
    this.spinnerService.show();
    let apireq = {
        "_id":this.id._id,
        "token":this.id.token,
        "password":data.newPassword
      }
    this.service.postApi("/api/v1/admin/resetPassword",apireq,0).subscribe((success)=>{
      if(success.body.responseCode==200){
        this.service.success(success.body.responseMessage)
        this.myRoute.navigate(["/login"])
        this.spinnerService.hide();
      }
      else{
        this.service.error(success.body.responseMessage)
        this.spinnerService.hide();
      }
    },error=>{
      this.service.error("Something went wrong");
      this.spinnerService.hide();
    })
  }

}
