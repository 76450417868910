import { Component, OnInit } from '@angular/core';
import { MyServiceService } from 'src/app/my-service.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $: any

@Component({
  selector: 'app-edit-faq',
  templateUrl: './edit-faq.component.html',
  styleUrls: ['./edit-faq.component.css']
})
export class EditFAQComponent implements OnInit {

  userdata: any
  staticData: any
  test: number
  answerId: any
  updateAnswerForm: FormGroup
  counter: number = 0
  answerToBind:any
  questionData:any
  deleteQuetionDetail:any
  noDataFound:any;
  constructor(private service: MyServiceService, public route: Router) {

  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getPermissions()
    this.staticPageContent()
    this.formvalues()
  }

  formvalues() {
    this.updateAnswerForm = new FormGroup({
      question: new FormControl('', [Validators.required]),
      answer: new FormControl('', [Validators.required])
    });
  }

  getPermissions() {
    let apireq = {
      "userId": localStorage.getItem("_id")
    }
    this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.userdata = success.body.data.userType
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.route.navigate(['/login'])
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  staticPageContent() {
    this.service.getApi('/api/v1/static/staticContentGet', 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.noDataFound = success.body.Data
        for(let i=0;i<success.body.Data.length;i++){
          if(success.body.Data[i].Type=="FAQ"){
            this.staticData = success.body.Data[i].FAQ;
            break;
          }
        }
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.route.navigate(["/login"]);
        }
      }
    })
  }

  updateAnswer(answerId,i,data) {
    this.answerId = answerId
    this.questionData = data
    this.answerToBind = this.staticData[i];
    this.updateAnswerForm.patchValue({
      question: this.answerToBind.question,
      answer: this.answerToBind.answer
    })
    $('#viewAnswer').modal({ backdrop: 'static', keyboard: false });
  }

  questionClick(i) {
    $("#answer"+i).toggle(400)
  }

  updateAnswerModal(){
    let updatedAnswer =  this.updateAnswerForm.value
    let apireq = {
                    "_id": this.questionData._id,
                    "question": this.updateAnswerForm.value.question,
                    "answer": updatedAnswer.answer,
                    "category": this.questionData.category
                }
    this.service.postApi("/api/v1/static/updateFaq", apireq, 1).subscribe(success => {
      if (success.body.responseCode === 200) {
        this.staticPageContent();
        $('#viewAnswer').modal('hide');
      }
      else{
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.route.navigate(["/login"]);
        }
      }
    },error=>{
      this.service.error("Something went wrong")
    })
  }

  deleteAnswerModal(i,data){
    this.deleteQuetionDetail = data
    $('#deleteQuetion').modal({ backdrop: 'static', keyboard: false });
  }

  deleteQuestion(){
    let apireq = {
                    "_id":this.deleteQuetionDetail._id
                }
    this.service.postApi("/api/v1/static/deletedFaq",apireq,1).subscribe(success=>{
      if(success.body.responseCode==200){
        this.staticPageContent()
        $('#deleteQuetion').modal('hide')
      }else{
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.route.navigate(["/login"]);
        }
      }
    },error=>{
      this.service.error("Something went wrong")
    })
  }

}
