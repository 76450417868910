import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MyServiceService } from '../my-service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin-wallet-balance',
  templateUrl: './admin-wallet-balance.component.html',
  styleUrls: ['./admin-wallet-balance.component.css']
})
export class AdminWalletBalanceComponent implements OnInit {

  url: any;
  escrowAmount: any;
  walletHistorty: any;
  flag = 0;
  LoginForm: any;
  responsData: any;
  tableData: any = [];
  page = 1;
  pageinationData: any;
  adminWalletHistoryArr: any = [];
  paginationData: any;
  addressPage: number = 1;
  totalUserAmount: any;
  adminWallet: any;
  bondAmount: any;

  constructor(private myRoute: Router, private service: MyServiceService) {
    this.manageRounting();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    // this.totalEscrowAmount();   //...Total escrow amount
    this.walletApiHistorty();   // wallet api history
    this.formFunc();    // form Function
    this.adminWalletHistory();
    this.totalUserAmountApi();
    this.adminWalletAmount();
    this.bondAmountApi();
    this.totalEscrowAmount();
  }

  bondAmountApi() {
    this.service.getApi('/api/v1/trade/totalBondAmount', 1).subscribe(success => {
      if (success.body.responseCode === 200) {
        this.bondAmount = success.body.result;
      }
    }, error => {
      this.service.error('Something went wrong')
    });
  }

  adminWalletHistory() {
    let apireq={
      'userId' : localStorage.getItem('_id'),
      'limit': 10,
      'pageNummber': this.addressPage
    };
    this.service.postApi('/api/v1/admin/adminAddressList', apireq, 1).subscribe(success => {
      if (success.body.responseCode === 200) {
        this.adminWalletHistoryArr = success.body.result;
        this.paginationData = success.body.paginationData;
      } else {
        if (success.body.responseCode === 409 || success.body.responseCode === 410 || success.body.responseCode === 411) {
          this.service.error(success.body.responseMessage);
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(['/login']);
        }
      }
    }, error => {
      this.service.error('Something went wrong');
    });
  }

  totalUserAmountApi() {
    this.service.getApi('/api/v1/trade/totalUserAmount', 1).subscribe(success => {
      if (success.body.responseCode === 200) {
        this.totalUserAmount = success.body;
      } else {
        this.service.error(success.body.responseMessage);
      }
    },error=>{
      this.service.error('Something went wrong')
    })
  }

  adminWalletAmount(){
    this.service.getApi('/api/v1/trade/totalAdminAmount',1).subscribe(success=>{
      if(success.body.responseCode == 200){
        this.adminWallet = success.body.result
      }
      else{
        this.service.error(success.body.responseMessage)
      }
    },error=>{
      this.service.error('Something went wrong')
    })
  }

  addressPagination(event){
    this.addressPage = event
    this.adminWalletHistory()
  }

  formFunc() {
    this.LoginForm = new FormGroup({
      sendTo: new FormControl('',[Validators.required]),
      remark: new FormControl('',[Validators.required]),
      amount: new FormControl('',[Validators.required]),
    });
  }

  submitFunc(data) {
    if (this.LoginForm.invalid) {
      return
    }
    this.service.postApi(`/api/v1/admin/hotWallet`, data, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.flag = 0
        this.walletApiHistorty()    // wallet api history
        this.service.success(success.body.responseMessage)
        this.LoginForm.reset();
      }
      else {
        if(success.body.responseCode==201){
          this.service.error(success.body.responseMessage)
        }
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(['/login']);
        }
      }
    }, error => {
      this.service.error('Something went wrong')
    })
  }
  showFunc() {
    this.LoginForm.reset();
    this.flag = 1
  }

  removeFunc() {
    this.LoginForm.reset();
    this.flag = 0
  }

  manageRounting() {
    this.myRoute.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        if (this.url == '/admin/adminWalletBalance') {
          let apireq = {
            'userId': localStorage.getItem('_id')
          }
          this.service.postApi('/api/v1/admin/viewDetail', apireq, 1).subscribe((success) => {
            if (success.body.responseCode == 200) {
              if (success.body.data.userType != 'ADMIN') {
                this.myRoute.navigate(['/pageNotFound']);
              }
            }
            else {
              if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
                this.service.error(success.body.responseMessage)
                localStorage.removeItem('LOGINTOKEN');
                localStorage.removeItem('role');
                localStorage.removeItem('_id');
                this.myRoute.navigate(['/login']);
              }
            }
          }, error => {
            this.service.error('Something went wrong')
          })
        }
      }
    })
  }

  totalEscrowAmount() {
    this.service.getApi(`/api/v1/trade/totalEscrowAmount`, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        // if (success.body.result) {
          this.escrowAmount = success.body.result
        // }
      }
      else {
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(['/login']);
        }
      }
    }, error => {
      this.service.error('Something went wrong')
    })
  }

  walletApiHistorty() {
    let data = {
      limit : 10,
      pageNumber : this.page
    }
    this.service.postApi(`/api/v1/admin/hotWalletData`, data, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.responsData = success.body
        this.tableData = this.responsData.result
        this.pageinationData = success.body.paginationData
      }
      else{
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(['/login']);
        }
      }
    }, error => {
      this.service.error('Something went wrong')
    })
  }

  tablePagination(event){
    this.page = event
    this.walletApiHistorty()
  }
}
