import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MyServiceService } from '../my-service.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-forgot-password-main',
  templateUrl: './forgot-password-main.component.html',
  styleUrls: ['./forgot-password-main.component.css']
})
export class ForgotPasswordMainComponent implements OnInit {
  forgotPasswordMain: any;

  constructor(public router:Router,public service : MyServiceService, public spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.forgotPasswordMain=new FormGroup({
      email: new FormControl('',[Validators.required,Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)]),
    });
  }

  submit(data){
    if(this.forgotPasswordMain.invalid){
      return;
    }
    let apireq = {
      email : data.email
    }
    this.spinnerService.show();
    this.service.postApi("/api/v1/admin/forgotPassword",apireq,0).subscribe((success)=>{
      if(success.body.responseCode==200){
        this.service.success(success.body.responseMessage);
        this.router.navigate(["/login"]);
        this.spinnerService.hide();
      }
      else{
        this.service.error(success.body.responseMessage)
        this.spinnerService.hide();
      }
    },error=>{
      this.service.error("Something went wrong")
      this.spinnerService.hide();
    })

  }
}
