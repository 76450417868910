import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MyServiceService } from '../../my-service.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-finance-management',
  templateUrl: './finance-management.component.html',
  styleUrls: ['./finance-management.component.css']
})
export class FinanceManagementComponent implements OnInit {
  dipositForm: any
  WithDrawsForm: any
  feeCollectionForm: any
  countryArr: any;
  depositArr: any = []
  character: any
  url: any
  apireq: any
  dropdownArr: any = []
  addressdropdownSettings: any = {}
  user_namedropdownSettings:any
  pageNumber:number =1
  nowDate : any
  nowDate1 : any
  nowDate2 : any
  user_nameArr: any;
  constructor(private router: Router, private service: MyServiceService) {
    this.manageRounting();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.nowDate = new Date()
    this.nowDate1 = new Date()
    this.allForms()
    this.depositArr['docs'] = []
    this.depositeApi('DEPOSIT', 'reset',1)
    this.countryArr = this.service.countryArr
  }

  Changed(event) {
    if (event) {
      this.nowDate2 = new Date(event)
    }
    else{
      this.nowDate2 = ''
    }
  }

  maxFromDate(event){
    if(event){
      this.nowDate1 = new Date(event)
      }
      else{
        this.nowDate1 = new Date();
      }
  }

  manageRounting() {
    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        if (this.url == "/admin/finance") {
          let apireq = {
            "userId": localStorage.getItem("_id")
          }
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
            if (success.body.responseCode == 200) {
              if (success.body.data.permission[0].finances == false) {
                this.router.navigate(["/pageNotFound"]);
              }
            }
          }, error => {
            this.service.error("Something went wrong")
          })
        }
      }
    })
  }

  allForms() {
    this.dipositForm = new FormGroup({
      address: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      user_name: new FormControl(''),
    });

    this.WithDrawsForm = new FormGroup({
      address: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      user_name:new FormControl(''),
    });

    this.feeCollectionForm = new FormGroup({
      startDate: new FormControl(''),
      endDate: new FormControl('')
    });
  }

  depositeApi(apiType, event,pag) {
    if(pag==1){
      this.pageNumber = 1
    }
    // this.nowDate = new Date();
    // this.nowDate1 = new Date();
    // this.nowDate2 = '';
    // this.nowDate4 = new Date();
    // this.nowDate5 = '';
    // this.nowDate6 = new Date();
    if (apiType == 'Fee') {
      if (event == 'reset') {
        this.feeCollectionForm.patchValue({
          startDate: '',
          endDate: '',
        });
        this.apireq = {
          type: "",
          collection: 'fee',
          fromDate: '',
          toDate: '',
          pageNumber : this.pageNumber,
          limit : 10
        }
      }
      else {
        this.apireq = {
          type: "",
          collection: 'fee',
          fromDate: (this.feeCollectionForm.value.startDate == '' || this.feeCollectionForm.value.startDate == undefined) ? '' : (new Date(this.feeCollectionForm.value.startDate).toISOString()),
          toDate: (this.feeCollectionForm.value.endDate == '' || this.feeCollectionForm.value.endDate == undefined) ? '' : (new Date(new Date(this.feeCollectionForm.value.endDate).getTime() + 24 * 60 * 60 * 1000).toISOString()),
          pageNumber : this.pageNumber,
          limit : 10
        }
      }

    }
    else {
      if (apiType == 'DEPOSIT') {
        if (event == 'reset') {
          this.dipositForm.patchValue({
            address: '',
            fromDate: '',
            toDate: '',
            user_name:''
          });
          this.apireq = {
            type: apiType,
            address: '',
            fromDate: '',
            toDate: '',
            user_name:'',

            pageNumber : this.pageNumber,
            limit : 10
          }
        }
        else {
          this.apireq = {
            type: apiType,
            address: this.dipositForm.value.address != '' ? this.dipositForm.value.address[0].toAddress : '',
            user_name: this.dipositForm.value.user_name != '' ? this.dipositForm.value.user_name[0].toAddress : '',
            fromDate: (this.dipositForm.value.fromDate == '' || this.dipositForm.value.fromDate == undefined) ? '' : (new Date(this.dipositForm.value.fromDate).toISOString()),
            toDate: (this.dipositForm.value.toDate == '' || this.dipositForm.value.toDate == undefined) ? '' : (new Date(new Date(this.dipositForm.value.toDate).getTime() + 24 * 60 * 60 * 1000).toISOString()),
            pageNumber : this.pageNumber,
            limit : 10
          }
        }

      }
      else {
        if (event == 'reset') {
          this.WithDrawsForm.patchValue({
            address: '',
            fromDate: '',
            toDate: '',
            user_name:''
          });
          this.apireq = {
            type: apiType,
            address: '',
            fromDate: '',
            user_name:'',
            toDate: '',
            pageNumber : this.pageNumber,
            limit : 10
          }
        }
        else {
          this.apireq = {
            type: apiType,
            address: this.WithDrawsForm.value.address != '' ? this.WithDrawsForm.value.address[0].toAddress : '',
            user_name: this.WithDrawsForm.value.user_name != '' ? this.WithDrawsForm.value.user_name[0].toAddress : '',
            fromDate: (this.WithDrawsForm.value.fromDate == '' || this.WithDrawsForm.value.fromDate == undefined) ? '' : (new Date(this.WithDrawsForm.value.fromDate).toISOString()),
            toDate: (this.WithDrawsForm.value.toDate == '' || this.WithDrawsForm.value.toDate == undefined) ? '' : (new Date(new Date(this.WithDrawsForm.value.toDate).getTime() + 24 * 60 * 60 * 1000).toISOString()),
            pageNumber : this.pageNumber,
            limit : 10
          }
        }

      }
    }
    this.service.postApi("/api/v1/trade/financeHistory", this.apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.depositArr = success.body.deposite
        var addreeArr = success.body.allData
        var address = [];
        var user_Name=[]
        for (let i = 0; i < addreeArr.length; i++) {
          if (addreeArr[i].toAddress) {
            address.push({ _id: addreeArr[i]._id, toAddress: addreeArr[i].toAddress })

            user_Name.push({ _id: addreeArr[i]._id, toAddress: addreeArr[i].user_name })

          }
        }
        
        this.user_nameArr=user_Name
        this.dropdownArr = address
        this.addressdropdownSettings = {
          singleSelection: true,
          idField: '_id',
          textField: 'toAddress',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        }

        this.user_namedropdownSettings = {
          singleSelection: true,
          idField: '_id',
          textField: 'toAddress',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        }
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }


  depositePagination(event){
    this.pageNumber = event
    this.depositeApi('DEPOSIT','wewewe',6)
  }

  withdrawlPagination(event){
    this.pageNumber = event
    this.depositeApi('WITHDRAW','wrwscds',6)
  }

  feePagination(event){
    this.pageNumber = event
    this.depositeApi('Fee','wdehh',6)
  }


}
