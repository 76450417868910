import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MyServiceService } from 'src/app/my-service.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { SocketServiceService } from 'src/app/socket-service.service';

declare var $: any

@Component({
  selector: 'app-trade-details',
  templateUrl: './trade-details.component.html',
  styleUrls: ['./trade-details.component.css']
})
export class TradeDetailsComponent implements OnInit {
  userId: any;
  id: any;
  tradeDetail: any;
  chatHistoryArr: any = [];
  tradeHistoryArr: any = [];
  pageNumber: number = 1;
  chatImageList: any = [];
  chatImageListButton: any;
  noOfCarosuelArr: any = [];
  url: any;
  userType: any;
  firstImage: any;
  chatListImageArr: any = [];
  seeBtcAmount: any;

  constructor(public zone:NgZone , public socket: SocketServiceService,public activatedRoute: ActivatedRoute, public service: MyServiceService, public spinnerService: Ng4LoadingSpinnerService, public router: Router) {
    this.userId = "src/assets/img/dummy.png";
    this.manageRounting();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getId();
    this.getTradeDetailFunc();
    this.chattingHistory();
    this.chatImageFunc();

  }

  manageRounting() {
    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        var splitUrl = this.url.split("/")
        if (splitUrl[2] == "tradeDetails") {
          let apireq = {
            "userId": localStorage.getItem("_id")
          };
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
            if (success.body.responseCode == 200) {
              this.userType = success.body.data
              if (success.body.data.permission[0].trades == false) {
                this.router.navigate(["/pageNotFound"]);
              }
            }
            else {
              this.service.error(success.body.responseMessage)
              if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
                localStorage.removeItem('LOGINTOKEN');
                localStorage.removeItem('role');
                localStorage.removeItem('_id');
                this.router.navigate(["/login"]);
              }
            }
          }, error => {
            this.service.error("Something went wrong")
          });
        }
      }
    });
  }

  getId() {
    this.activatedRoute.params.subscribe(paramsId => {
      this.id = paramsId.id;
    });
  }

  chatImageFunc() {
    let apireq = {
      tradeId: this.id,
    };
    this.socket.socket.emit('chatImage', apireq);
    this.getImages();
  }
  getImages(){
    this.socket.socket.on('chatImage', (data) => {
      var chatListLength = data.data.length;
      this.chatImageList = data.data;
      if(chatListLength > 0) {
        this.firstImage = this.chatImageList[0].image;
        for (let i = 0; i < chatListLength; i++) {
          if (i == 0) {
            continue;
          }
          this.chatListImageArr.push(this.chatImageList[i])
        }
      }
    })
  }

  getTradeDetailFunc() {
    this.spinnerService.show();
    let apireq = {
      tradeId: this.id,
      pageNumber: this.pageNumber
    }
    this.service.postApi("/api/v1/admin/tradeList", apireq, 1).subscribe((success) => {
      if (success.body.responseCode === 200) {
        this.tradeDetail = success.body.result[0];
        let btcAmount = Number(success.body.result[0].amount_of_cryptocurrency);
        this.seeBtcAmount = btcAmount.toFixed(8);
        this.tradeHistoryArr = success.body;
        this.spinnerService.hide();
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
        else {
          if (success.body.responseCode == 404) {
            this.router.navigate(['/admin/trades'])
          }
        }
        this.spinnerService.hide();
      }
    }, error => {
      this.service.error("Something went wrong")
      this.spinnerService.hide();
    })
  }

  pagination(page) {
    this.pageNumber = page
    this.getTradeDetailFunc()
  }

  chattingHistory() {
    this.spinnerService.show();
    let apireq = {
      tradeId: this.id
    }
    this.service.postApi("/api/v1/trade/chatHistory", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.chatHistoryArr = success.body.result
        setTimeout(function () {
          $('.chat').scrollTop($('.chat')[0].scrollHeight);
        }, 100);
        this.spinnerService.hide();
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
        this.spinnerService.hide();
      }
    }, error => {
      this.service.error("Something went wrong")
      this.spinnerService.hide();
    })
  }

}
