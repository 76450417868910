import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MyServiceService } from 'src/app/my-service.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

declare var $: any;

@Component({
  selector: 'app-edit-user-data',
  templateUrl: './edit-user-data.component.html',
  styleUrls: ['./edit-user-data.component.css']
})
export class EditUserDataComponent implements OnInit {
  editUserDataForm: FormGroup;
  modalForm: FormGroup;
  imageurl: string;
  id: any;
  countryCodeList: any = []
  countryCodeListSettings: any = {}
  selectedItems: any = [];
  userdata: any;
  countryAr:any

  constructor(private router: Router, public service: MyServiceService, private activatedRoute: ActivatedRoute, public spinnerService: Ng4LoadingSpinnerService) {
    this.imageurl = "src/assets/img/QR.png";
  }

  ngOnInit() {

    this. countryAr=this.service.countryArr
    this.formValidation();
    this.getId();
    this.getDetails();
    this.modalFormValidation();
    window.scrollTo(0, 0);
    this.countryCodeList = this.service.countryArr
    this.countryCodeListSettings = {
      singleSelection: true,
      idField: 'country',
      textField: 'code',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }

  formValidation() {
    this.editUserDataForm = new FormGroup({
      countryCode: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.pattern(/^[1-9][0-9]{7,16}$/),Validators.minLength(8),Validators.maxLength(16)]),
      email: new FormControl('', [Validators.required,Validators.maxLength(256), Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i)]),
    })
  }

  getId() {
    this.activatedRoute.params.subscribe(paramsId => {
      this.id = paramsId.id;
    });
  }

  getDetails() {
    let apireq = {
      "userId": this.id
    }
    this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.userdata = success.body.data
        this.selectedItems = [{ country: success.body.data.country, code: success.body.data.country_code }];
        this.editUserDataForm.patchValue({
          phoneNumber: success.body.data.phone_number,
          email: success.body.data.email,
          countryCode:success.body.data.country_code
        })
      } else {
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
        else {
          this.service.error(success.body.responseMessage)
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  modalFormValidation() {
    this.modalForm = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[0-9]*')])
    })
  }

  editModal(data) {
    if (this.editUserDataForm.invalid) {
      return;
    }
    this.spinnerService.show();
    let apireq = {
      country_code: data.countryCode[0].code,
      phone_number: data.phoneNumber,
      email: data.email,
      userId: this.id
    }
    this.service.postApi("/api/v1/admin/editUserProfile", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.router.navigate(["/admin/userDetails/" + this.id])
        this.service.success(success.body.responseMessage)
        this.spinnerService.hide();
      } else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
        this.spinnerService.hide();
      }
    }, error => {
      this.service.error("Something went wrong")
      this.spinnerService.hide();
    })

  }

  edit(data) {
    if (this.modalForm.invalid) {
      return;
    }
    $('#2FA').modal('hide')
  }

  back() {
    this.router.navigate(['/admin/userDetails/' + this.id])
  }
}
