import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MyServiceService } from '../../my-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

declare var $: any
@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css']
})
export class PaymentMethodComponent implements OnInit {
  congigurePaymentForm: FormGroup;
  paymentListArr: any;
  pageNumber: number = 1
  riskType: string;
  paymentId: any
  editPaymentForm: FormGroup;
  particularPayementDetail: any
  constructor(private myRoute: Router, public spinnerService: Ng4LoadingSpinnerService, private service: MyServiceService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.congigurePaymentForm = new FormGroup({
      name: new FormControl('', [ Validators.required,Validators.minLength(2),Validators.maxLength(256)]),
      riskLevel: new FormControl('', [Validators.required]),
      sellerPaymentMessage: new FormControl('', [Validators.required]),
      buyerPaymentMessage: new FormControl('', [Validators.required]),
    })
    this.editPaymentForm = new FormGroup({
      editname: new FormControl('', [Validators.required]),
      editriskLevel: new FormControl('', [Validators.required]),
      sellerPaymentMessage: new FormControl('', [Validators.required]),
      buyerPaymentMessage: new FormControl('', [Validators.required]),
    })
    this.paymentList()
  }

  paymentList() {
    let apireq = {
      "paymentId": "",
      "pageNumber": this.pageNumber,
      "limit": 10
    }
    this.service.postApi("/api/v1/admin/paymentAction", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.paymentListArr = success.body.result
      } else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  pagination(event) {
    this.pageNumber = event
    this.paymentList()
  }

  goToConfigure() {
    $('#ConfigurePayment').modal({ backdrop: 'static', keyboard: false });
  }

  //************************************************** add payment method ****************************************/

  congigurePaymentFunc(data) {
    if (this.congigurePaymentForm.invalid) {
      return;
    }
    let apireq = {
      "paymentId": "",
      "add": true,
      "tempData": "added",
      "name": this.congigurePaymentForm.value.name,
      "levelOfRisk": this.congigurePaymentForm.value.riskLevel,
      "sellerPaymentMessage":this.congigurePaymentForm.value.sellerPaymentMessage ,
      "buyerPaymentMessage":this.congigurePaymentForm.value.buyerPaymentMessage ,
    }
    this.service.postApi("/api/v1/admin/paymentAction", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        $('#ConfigurePayment').modal('hide')
        this.paymentList()
        this.service.success(success.body.responseMessage)
        this.congigurePaymentForm.patchValue({
          name: '',
          riskLevel: '',
          paymentWindowTime: '',
        })
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong");
    })
  }

  //************************************************** delete payment method ****************************************

  deleteModal(id) {
    this.paymentId = id
    for (var i = 0; i < this.paymentListArr.docs.length; i++) {
      if (this.paymentListArr.docs[i]._id == this.paymentId) {
        this.particularPayementDetail = this.paymentListArr.docs[i]
        break;
      }
    }
    $('#deleteModal').modal({ backdrop: 'static', keyboard: false });
  }

  delete() {
    let apireq = {
      "paymentId": this.paymentId,
      "name": this.particularPayementDetail.name,
      "action": true,
      "tempData": "deleted",
      "status": "DELETE"
    }
    this.service.postApi("/api/v1/admin/paymentAction", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        $('#ConfigurePayment').modal('hide')
        this.paymentList()
        this.service.success("Payement method deleted successfully")
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong");
    })
  }

  //************************************************** Edit payment method ****************************************

  editModal(id) {
    this.paymentId = id
    for (var i = 0; i < this.paymentListArr.docs.length; i++) {
      if (this.paymentListArr.docs[i]._id == this.paymentId) {
        this.particularPayementDetail = this.paymentListArr.docs[i]
        break;
      }
    }
    $('#editModal').modal({ backdrop: 'static', keyboard: false });
    this.editPaymentForm.patchValue({
      editname: this.particularPayementDetail.name,
       sellerPaymentMessage:this.particularPayementDetail.sellerPaymentMessage ,
       buyerPaymentMessage:this.particularPayementDetail.buyerPaymentMessage ,

      edityes: (this.particularPayementDetail.secondField == 'Yes') ? true : false,
      editriskLevel: this.particularPayementDetail.levelOfRisk,
    })
  }

  editPaymentMethod() {
    if (this.editPaymentForm.invalid) {
      return;
    }
    var secondField;
    if (this.editPaymentForm.value.yes == true) {
      secondField = "Yes"
    }
    else {
      secondField = "No"
    }
    let apireq = {
      "paymentId": this.paymentId,
      "action": true,
      "name": this.editPaymentForm.value.editname,
      "secondField": secondField,
      "levelOfRisk": this.editPaymentForm.value.editriskLevel,
    
      "tempData": "updated",
      sellerPaymentMessage:this.editPaymentForm.value.sellerPaymentMessage ,
      buyerPaymentMessage:this.editPaymentForm.value.buyerPaymentMessage ,
    }

    this.service.postApi("/api/v1/admin/paymentAction", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        $('#editModal').modal('hide')
        this.paymentList()
        this.service.success(success.body.responseMessage)
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong");
    })
  }


}
