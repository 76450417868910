import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MyServiceService } from '../../my-service.service';

declare var $: any
@Component({
  selector: 'app-tag-management',
  templateUrl: './tag-management.component.html',
  styleUrls: ['./tag-management.component.css']
})
export class TagManagementComponent implements OnInit {
  tagSearch: FormGroup;
  addTagForm: FormGroup;
  editTagForm: FormGroup;
  tagData: any={docs:[]};
  Pages: Number = 1;
  statusDropDownList: any = []
  statusDropDownListSettings: any = {}
  showDropDownList: any = []
  showDropDownListSettings: any = {}
  uniqueIdDropDownList: any = []
  uniqueIdDropDownListSettings: any = {}
  tagId: any
  status: any
  url: any
  todayDate:any
  nowDate2:any
  todayDate1:any
  constructor(private myRoute: Router, public spinnerService: Ng4LoadingSpinnerService, private service: MyServiceService, private activatedRoute: ActivatedRoute) {
    this.manageRounting()
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.todayDate1 = new Date()
    this.todayDate = new Date()
    this.searchFormValidation();
    this.addTag();
    this.getTagList('reset', 1);
    this.editTagFo();
    this.dropDownSetting();
  }

  manageRounting() {
    this.myRoute.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        if (this.url == "/admin/tagManagement") {
          let apireq = {
            "userId": localStorage.getItem("_id")
          }
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
            if (success.body.responseCode == 200) {
              if (success.body.data.permission[0].systemConfiguration == false) {
                this.myRoute.navigate(["/pageNotFound"]);
              }
            }
            else {
              this.service.error(success.body.responseMessage)
              localStorage.removeItem('LOGINTOKEN');
              localStorage.removeItem('role');
              localStorage.removeItem('_id');
              this.myRoute.navigate(["/login"]);
            }
          }, error => {
            this.service.error("Something went wrong")
          })
        }

      }
    })
  }

  searchFormValidation() {
    this.tagSearch = new FormGroup({
      status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      show: new FormControl(''),
      uniqueId: new FormControl(''),
    })
  }

  addTag() {
    this.addTagForm = new FormGroup({
      tagValue: new FormControl('',[ Validators.required,Validators.minLength(2),Validators.maxLength(256)])
    })
  }

  editTagFo() {
    this.editTagForm = new FormGroup({
      tagValue: new FormControl('', [ Validators.required,Validators.minLength(2),Validators.maxLength(256)])
    })
  }

  dropDownSetting() {
    this.statusDropDownList = [{ id: 1, status: "All" }, { id: 2, status: "Approved" }, { id: 3, status: "Rejected" }]
    this.statusDropDownListSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'status',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
    this.showDropDownList = [ { id: 2, show: "10" }, { id: 3, show: "15" }, { id: 4, show: "20" }, { id: 5, show: "30" }]
    this.showDropDownListSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'show',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
  }

  getTagList(event, pag) {
    if (pag == 1) {
      this.Pages = 1
    }
    if (event == "reset") {
      this.tagSearch.patchValue({
        status: '',
        fromDate: '',
        toDate: '',
        show: '',
        uniqueId: '',
      })
      var apireq = {
        "tagName": "",
        "postedBy": "",
        "add": "",
        "action": false,
        "pageNumber": this.Pages,
        "status": '',
        "uniqueId": '',
        "startDate": "",
        "endDate": "",
        "limit": 10,
      }
    }
    else {
      apireq = {
        "tagName": "",
        "postedBy": "",
        "add": "",
        "action": false,
        "pageNumber": this.Pages,
        "status": (this.tagSearch.value.status != '') ? ((this.tagSearch.value.status[0].status != "ALL") ? (this.tagSearch.value.status[0].status == 'Approved') ? 'ACTIVE' : (this.tagSearch.value.status[0].status == 'Rejected') ? 'BLOCK' : '' : '') : '',
        "uniqueId": this.tagSearch.value.uniqueId != '' ? this.tagSearch.value.uniqueId[0].result : '',
        "startDate": (this.tagSearch.value.fromDate == '' || this.tagSearch.value.fromDate == undefined) ? '' : (new Date(this.tagSearch.value.fromDate).toISOString()),
        "endDate": (this.tagSearch.value.toDate == '' || this.tagSearch.value.toDate == undefined) ? '' : (new Date(new Date(this.tagSearch.value.toDate).getTime() + 24 * 60 * 60 * 1000).toISOString()),
        "limit": this.tagSearch.value.show != 10 ? (this.tagSearch.value.show != '' ? Number(this.tagSearch.value.show[0].show) : 10) : 10,
      }
    }

    this.service.postApi('/api/v1/trade/addTags', apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.tagData = success.body.result
        this.uniqueIdDropDownList = success.body.uniqueId
        this.uniqueIdDropDownListSettings = {
          singleSelection: true,
          idField: 'id',
          textField: 'result',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        }
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  tagsearch(data) {
  }

  paginationUserList(event) {
    this.Pages = event;
    this.getTagList("evev", 4);
  }
  //**************************************** Add tag **************************************** 
  addModal() {

    $("#addTagModal").modal({ backdrop: 'static', keyboard: false });
  }
  addTagClick(data) {
    if (this.addTagForm.invalid) {
      return
    }
    let apiData = {
      "tagName": data.tagValue,
      "postedBy": localStorage.getItem("role"),
      "add": true,
      "tempData": "added",
    }
    this.service.postApi('/api/v1/trade/addTags', apiData, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.service.success("Tag added successfully")
        this.myRoute.navigate(['/admin/tagManagement'])
        this.getTagList('reset', 1);
        this.addTagForm.controls['tagValue'].setValue('')
        $('#addTagModal').modal('hide')
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  //********************************************** Edit modal *******************************************

  editModal(data) {
    this.tagId = data
    for (let i = 0; i < this.tagData.docs.length; i++) {
      if (this.tagData.docs[i]._id == this.tagId) {
        this.editTagForm.patchValue({
          tagValue: this.tagData.docs[i].tagName
        })
        break;
      }
    }
    $("#editModal").modal({ backdrop: 'static', keyboard: false });
  }
  editTag(data) {
    if (this.editTagForm.invalid) {
      return
    }
    let apiData = {
      "tagId": this.tagId,
      "tagName": data.tagValue,
      "postedBy": localStorage.getItem("role"),
      "action": true,
      "tempData": "updated",
    }
    this.service.postApi('/api/v1/trade/addTags', apiData, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.myRoute.navigate(['/admin/tagManagement'])
        this.getTagList('reset', 1);
        this.service.success("Tag successfully edited")
        this.editTagForm.get('tagValue').setValue('')
        $('#editModal').modal('hide')
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
    this.editTagForm.controls['tagValue'].setValue('')
  }
  //..............................................................................................

  deleteModal(data) {
    this.tagId = data
    $("#deleteModal").modal({ backdrop: 'static', keyboard: false });
  }

  deleteTag() {
    let apiData = {
      "tagId": this.tagId,
      "postedBy": localStorage.getItem("role"),
      "action": true,
      "status": "DELETE",
      "tempData": "deleted",
    }
    this.service.postApi('/api/v1/trade/addTags', apiData, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.myRoute.navigate(['/admin/tagManagement'])
        this.getTagList('reset', 1);
        this.service.success("Tag successfully deleted")
        this.editTagForm.get('tagValue').setValue('')
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  openModal(tagId, status) {
    this.tagId = tagId
    this.status = status
    $("#approveModal").modal({ backdrop: 'static', keyboard: false });
  }

  approvetag(data) {
    let apiData = {
      "tagId": this.tagId,
      "postedBy": localStorage.getItem("role"),
      "action": true,
      "status": data
    }
    this.service.postApi('/api/v1/trade/addTags', apiData, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.myRoute.navigate(['/admin/tagManagement'])
        this.getTagList('reset', 1);
        if(data=="ACTIVE"){
          this.service.success("Tag approved successfully")
        }
        else{
          this.service.success("Tag rejected successfully")
        }
        this.editTagForm.get('tagValue').setValue('')
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })

  }

  changed(event){
    if (event) {
      this.nowDate2 = event;
    }
    else{
      this.nowDate2 = ''
    }
  }
  maxFromDate(event){
    if(event){
      this.todayDate= new Date(event);
      }
      else{
        this.todayDate= new Date();
      }
  }
}

