import { Component, OnInit, NgZone } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { MyServiceService } from "src/app/my-service.service";
import { SocketServiceService } from "src/app/socket-service.service";
import { Ng2ImgMaxService } from "ng2-img-max";

declare var $: any;

@Component({
  selector: "app-trade-dispute-history",
  templateUrl: "./trade-dispute-history.component.html",
  styleUrls: ["./trade-dispute-history.component.css"]
})
export class TradeDisputeHistoryComponent implements OnInit {
  userId = "src/assets/img/dummy.png";
  modalForm: FormGroup;
  showChatList: any = [];
  id: any;
  DisputeDetail: any;
  dropDownArr: any = [];
  nameDropDownArr: any = [];
  namesDropDownSettings: any = {};
  imageurl = "src/assets/img/QR.png";
  chatData: FormGroup;
  url: string;
  tradeAndDisputeTableData: any = [];
  tradeAndDisputeTablePagination: any;
  recommendActionForm: FormGroup;
  image: any;
  imageBase64: any = "";
  Arr: any = [];
  pageNumber: number = 1;
  recommenActionTableData: any;
  userType: any;
  apireq: any;
  disputeHistoryPageNumber: any;
  myid: any;
  toWhom: any;
  message: any = [];
  messageSetting: any = {};
  dropdownMessage: any = '';
  predefineMsg: boolean = false;
  constructor(
    public zone: NgZone,
    public router: Router,
    private ng2ImgMaxService: Ng2ImgMaxService,
    private activatedRoute: ActivatedRoute,
    public service: MyServiceService,
    public socket: SocketServiceService
  ) {
    this.socket.onEvent('disconnect')
      .subscribe(() => {
        this.receiveMessage();
      });
    this.receiveMessage();
    this.manageRounting();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getId();
    this.chattingForm();
    this.modalFormValidation();
    this.chatHistory();
    this.getDisputeList();
    this.myid = localStorage.getItem("_id")
    this.recommendActionForm = new FormGroup({
      actionToRecommend: new FormControl("", [Validators.required])
    });
  }

  manageRounting() {
    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        var splitUrl = this.url.split("/");
        if (splitUrl[2] == "tradeDisputeHistory") {
          let apireq = {
            userId: localStorage.getItem("_id")
          };
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe(
            success => {
              if (success.body.responseCode == 200) {
                this.userType = success.body.data.userType;
              } else {
                this.service.error(success.body.responseMessage);
                if (
                  success.body.responseCode == 409 ||
                  success.body.responseCode == 410 ||
                  success.body.responseCode == 411
                ) {
                  localStorage.removeItem("LOGINTOKEN");
                  localStorage.removeItem("role");
                  localStorage.removeItem("_id");
                  this.router.navigate(["/login"]);
                }
              }
            },
            error => {
              this.service.error("Something went wrong");
            }
          );
        }
      }
    });
  }

  getId() {
    this.activatedRoute.params.subscribe(paramsId => {
      this.id = paramsId;
    });
  }

  chatHistory() {
    let apireq = {
      tradeId: this.id.tradeId
    };
    this.service.postApi("/api/v1/trade/chatHistory", apireq, 1).subscribe(
      succ => {
        if (succ.body.responseCode === 200) {
          this.showChatList = succ.body.result;
        }
        console.log(JSON.stringify(this.showChatList))
        setTimeout(function() {
          $(".chat").scrollTop($(".chat")[0].scrollHeight);
        }, 200);
      },
      error => {
        this.service.error("Something went wrong");
      });
  }

  chattingForm() {
    this.chatData = new FormGroup({
      selectedUserData: new FormControl(""),
      message: new FormControl("", [Validators.required]),
      imageUrl: new FormControl("")
    });
  }

  messageApi() {
    let apireq = {
      paymentMethod : this.tradeAndDisputeTableData[0].payment_method
    };
    console.log('apireq ===>>>', apireq);
    this.service.postApi('/api/v1/admin/paymentMethodMessage', apireq, 1).subscribe(success => {
      console.log('message anshul ===>>>', success);
      if (success.body.responseCode === 200) {

        var msg = [];
        if (success.body.paymentMessages != null) {
          msg.push({id: 1, message: '(buyer message) '+success.body.paymentMessages.buyerPaymentMessage});
        // }
        // if (success.body.paymentMessages.sellerPaymentMessage) {
          msg.push({id: 2, message: '(seller message) '+success.body.paymentMessages.sellerPaymentMessage});
        }
        this.message = msg;
        console.log(this.message);
        this.messageSetting = {
          singleSelection: true,
          idField: 'id',
          textField: 'message',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        };
      }
    }, error => {
      this.service.error('Something went wrong');
    });
  }

  getDisputeList() {
    let apireq = {
      tradeId: this.id.tradeId,
      pageNumber: this.disputeHistoryPageNumber
    };
    this.service.postApi("/api/v1/admin/tradeList", apireq, 1).subscribe(
      success => {
        if (success.body.responseCode == 200) {
          this.tradeAndDisputeTableData = success.body.result;
          this.messageApi();
          console.log('anshul ===>>>', this.tradeAndDisputeTableData);
          this.tradeAndDisputeTablePagination = success.body.paginationData;
          this.DisputeDetail = success.body.result[0];
          var buyer = {
            _id: this.DisputeDetail.buyerId,
            name: this.DisputeDetail.buyer[0].buyer_name
          };
          var seller = {
            _id: this.DisputeDetail.seller[0].seller_id,
            name: this.DisputeDetail.seller[0].seller_name
          };
          this.dropDownArr.push(buyer);
          this.dropDownArr.push(seller);
          this.nameDropDownArr = this.dropDownArr;
          this.namesDropDownSettings = {
            singleSelection: false,
            idField: "_id",
            textField: "name",
            selectAllText : "Select All",
            unSelectAllText : "UnSelect All",
            itemsShowLimit: 3,
            allowSearchFilter: true
          };
          this.recommendActionTable();
        } else {
          this.service.error(success.body.responseMessage);
          if (
            success.body.responseCode == 409 ||
            success.body.responseCode == 410 ||
            success.body.responseCode == 411
          ) {
            localStorage.removeItem("LOGINTOKEN");
            localStorage.removeItem("role");
            localStorage.removeItem("_id");
            this.router.navigate(["/login"]);
          }
        }
      },
      error => {
        this.service.error("Something went wrong");
      }
    );
  }
  paginationDisputeList(event) {
    this.disputeHistoryPageNumber = event;
    this.getDisputeList();
  }

  paginationTradeList(event) {
    this.disputeHistoryPageNumber = event;
    this.getDisputeList();
  }

  modalFormValidation() {
    this.modalForm = new FormGroup({
      code: new FormControl("", [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
        Validators.pattern("[0-9]*")
      ])
    });
  }

  predefineMessageSelect() {
    this.predefineMsg = true;
  }
  predefineMessageDeselect() {
    this.predefineMsg = false;
  }

  sendMsg(whatToSend) {
    if (this.predefineMsg) {
      var apireq = {
        image: this.imageBase64,
        senderId: localStorage.getItem('_id'),
        receiverId: [
          this.DisputeDetail.buyerId,
          this.DisputeDetail.seller[0].seller_id
        ],
        message: this.dropdownMessage[0].message.split(')')[1],
        tradeId: this.id.tradeId,
        type: 'GROUP',
        colorType : 'ADMIN'
      };
    } else {
      if ((whatToSend === 'message')) {
        if (this.chatData.invalid) {
          return;
        }
        if (this.chatData.value.message.trim() === '') {
          return;
        }
      }
      apireq = {
        image: this.imageBase64,
        senderId: localStorage.getItem('_id'),
        receiverId: [
          this.DisputeDetail.buyerId,
          this.DisputeDetail.seller[0].seller_id
        ],
        message: this.chatData.value.message,
        tradeId: this.id.tradeId,
        type: 'GROUP',
        colorType : 'ADMIN'
      };
    }
    console.log(apireq);
    this.dropdownMessage = '';
    this.predefineMsg = false;
    this.socket.socket.emit('sendMessage', apireq);
    this.chatData.patchValue({
      message: ''
    });
    this.imageBase64 = '';
  }

  receiveMessage() {
    this.socket.socket.on("receivemessage", data => {
      console.log('receive msg ===>>>', data);
      this.zone.run(() => {
        console.log('receive msg ===>>>', data);
        this.showChatList.push(data.requireData);
      });
      setTimeout(function() {
        $(".chat").scrollTop($(".chat")[0].scrollHeight);
      }, 200);
    });
  }

  releaseYes() {
    $("#2FA").modal({ backdrop: "static", keyboard: false });
  }
  release(data) {
    if (this.modalForm.invalid) {
      return;
    }
    $("#2FA").modal("hide");
  }

  recomendAcion() {
    $("#recomendedAction").modal({ backdrop: "static", keyboard: false });
  }

  releaseEscrow(who) {
    this.toWhom = who
    if (who === "buyer") {
      this.apireq = {
        userId: this.DisputeDetail.buyerId,
        tradeId: this.DisputeDetail._id
      };
    } else {
      this.apireq = {
        userId: this.DisputeDetail.seller[0].seller_id,
        tradeId: this.DisputeDetail._id
      };
    }
    $("#recomendedAction").modal("hide");
    $("#realseEscrow1").modal({ backdrop: "static", keyboard: false });
  }

  sendBTC() {
    this.service
      .postApi("/api/v1/escrow/releaseEscrow", this.apireq, 1)
      .subscribe(
        success => {
          if (success.body.responseCode === 200) {
            if (this.toWhom === 'buyer') {
              this.service.success('Amount released to seller');
            } else {
              this.service.success("Amount released to buyer")
            }
            this.getDisputeList();
            $("#realseEscrow1").modal("hide");
          } else {
            this.service.error(success.body.responseMessage);
            if (
              success.body.responseCode == 409 ||
              success.body.responseCode == 410 ||
              success.body.responseCode == 411
            ) {
              localStorage.removeItem("LOGINTOKEN");
              localStorage.removeItem("role");
              localStorage.removeItem("_id");
              this.router.navigate(["/login"]);
            }
          }
        },
        error => {
          this.service.error("Something went wrong");
        }
      );
  }

  recommendActionTable() {
    let apireq = {
      tradeId: this.DisputeDetail._id,
      limit: 10,
      pageNumber: this.pageNumber
    };
    this.service
      .postApi("/api/v1/admin/recommentPaginationData", apireq, 1)
      .subscribe(
        success => {
          if (success.body.responseCode === 200) {
            this.recommenActionTableData = success.body.result;
          } else {
            this.service.error(success.body.responseMessage);
            if (
              success.body.responseCode === 409 ||
              success.body.responseCode === 410 ||
              success.body.responseCode === 411
            ) {
              localStorage.removeItem("LOGINTOKEN");
              localStorage.removeItem("role");
              localStorage.removeItem("_id");
              this.router.navigate(["/login"]);
            }
          }
        },
        error => {
          this.service.error("Something went wrong");
        }
      );
  }

  recommendAction() {
    if (this.recommendActionForm.invalid) {
      return;
    }
    let apireq = {
      employeeId: localStorage.getItem("_id"),
      tradeId: this.DisputeDetail._id,
      sellerId: this.DisputeDetail.seller[0].seller_id,
      buyerId: this.DisputeDetail.buyerId,
      recommentMessage: this.recommendActionForm.value.actionToRecommend
    };
    this.service.postApi("/api/v1/admin/recommentAction", apireq, 1).subscribe(
      success => {
        if (success.body.responseCode === 200) {
        } else {
          this.service.error(success.body.responseMessage);
          if (
            success.body.responseCode === 409 ||
            success.body.responseCode === 410 ||
            success.body.responseCode === 411
          ) {
            localStorage.removeItem("LOGINTOKEN");
            localStorage.removeItem("role");
            localStorage.removeItem("_id");
            this.router.navigate(["/login"]);
          }
        }
      },
      error => {
        this.service.error("Something went wrong");
      }
    );

    this.recommendActionForm.patchValue({
      actionToRecommend: ""
    });
  }

  paginationrecommendAction(event) {
    this.pageNumber = event;
    this.recommendActionTable();
  }

  onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      let image = event.target.files[0];
      this.ng2ImgMaxService.resizeImage(image, 400, 300).subscribe(
        result => {
          var reader = new FileReader();
          reader.readAsDataURL(result);
          reader.onload = (event: any) => {
            this.image = new Image();
            this.image.src = event.target.result;
            this.imageBase64 = this.image.src;
            this.sendMsg("image");
          };
        },
        error => {
          this.service.error(error.reason);
        }
      );
    }
  }
}
