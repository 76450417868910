import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MyServiceService } from "../../my-service.service";

declare var $: any;

@Component({
  selector: "app-dispute-management",
  templateUrl: "./dispute-management.component.html",
  styleUrls: ["./dispute-management.component.css"]
})
export class DisputeManagementComponent implements OnInit {
  PatientSearchForm: FormGroup;
  countryArr: any;
  patientData = [];
  url: any;
  apireq: any;
  pageNumber: number = 1;
  finalFinalSeller: any = [];
  tradeListArr: any = [];
  paginationData: any;
  finalfinalbuyer: any = [];
  finalSellerArr: any = [];
  paymentMethodArr: any = [];
  paymentMethodArrSettings: any = {};
  dropDownArrList: any = [];
  tradeIdArrSettings: any = {};
  countryArrSettings: any = {};
  showdropdownList: any = [];
  showdropdownSettings: any = {};
  disputeIdArrSettings: any = {};
  permissions: any;
  select: any = {};
  selectedRowDataArr: any = [];
  multiselectorArr: any = [];
  multiselectorSetting: any = {};
  assignEmployeeForm: FormGroup;
  userType: any;
  nowDate: Date;
  nowDate1: any;
  nowDate2: string;
  countryMultiselectorData: any;
  tradeOwnerNameDropDownSettings: any = {};
  finalBuyerArr: any = [];
  buyerDropDownSettings: any = {};
  sellerDropDownSettings: any = {};
  constructor(private router: Router, public service: MyServiceService) {
    this.manageRounting();
    this.PatientSearchForm = new FormGroup({
      trade: new FormControl(""),
      country: new FormControl(""),
      startDate: new FormControl(""),
      toDate: new FormControl(""),
      paymentMode: new FormControl(""),
      disputeId: new FormControl(""),
      show: new FormControl(""),
      tradeOwnerName: new FormControl(""),
      buyer: new FormControl(""),
      seller: new FormControl("")
    });
    this.assignEmployeeForm = new FormGroup({
      employee: new FormControl("", [Validators.required])
    });
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.countryList();
    this.countryArr = this.service.countryArr;
    this.userType = localStorage.getItem("role");
    this.disputwListWithSearching("reset", 1);
    this.paymentMethod();
    this.dropdowndata();
    this.multiselectordropdownList();

    this.nowDate = new Date();
    this.nowDate1 = new Date();
    this.nowDate2 = "";
  }

  countryList() {
    var countryArr1 = [];
    var country = this.service.countryArr;
    for (let i = 0; i < country.length; i++) {
      countryArr1.push({ id: i, country: country[i].name });
    }
    this.countryMultiselectorData = countryArr1;
    this.countryArrSettings = {
      singleSelection: true,
      idField: "id",
      textField: "country",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  Changed(event) {
    if (event) {
      this.nowDate2 = event;
      this.nowDate = new Date();
    } else {
      this.nowDate2 = "";
    }
  }
  maxFromDate(event) {
    if (event) {
      this.nowDate1 = new Date(event);
    } else {
      this.nowDate1 = new Date();
    }
  }

  multiselectordropdownList() {
    if (this.userType == "ADMIN") {
      var apireq = {
        userType: "MANAGER"
      };
    } else {
      apireq = {
        userType: "SUBADMIN"
      };
    }

    this.service.postApi("/api/v1/admin/emailList", apireq, 1).subscribe(
      success => {
        if (success.body.responseCode == 200) {
          this.multiselectorArr = success.body.result;
          this.multiselectorSetting = {
            singleSelection: true,
            idField: "_id",
            textField: "user_name",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            itemsShowLimit: 3,
            allowSearchFilter: true
          };
        } else this.service.error(success.body.responseMessage);
        if (
          success.body.responseCode == 409 ||
          success.body.responseCode == 410 ||
          success.body.responseCode == 411
        ) {
          localStorage.removeItem("LOGINTOKEN");
          localStorage.removeItem("role");
          localStorage.removeItem("_id");
          this.router.navigate(["/login"]);
        }
      },
      error => {
        this.service.error("Something went wrong");
      }
    );
  }

  assignEmployee() {
    if (this.assignEmployeeForm.invalid) {
      return;
    }
    if (this.selectedRowDataArr.length == 0) {
      return;
    }
    if (this.selectedRowDataArr.length > 1) {
      var apireq = {
        type: 1,
        tradeUser: this.selectedRowDataArr,
        employeeId: this.assignEmployeeForm.value.employee[0]._id
      };
    } else {
      apireq = {
        type: 0,
        tradeUser: this.selectedRowDataArr[0],
        employeeId: this.assignEmployeeForm.value.employee[0]._id
      };
    }
    this.service
      .postApi("/api/v1/escrow/assignToEmployee", apireq, 1)
      .subscribe(
        success => {
          if (success.body.responseCode == 200) {
            this.disputwListWithSearching("reset", 1);
          } else {
            this.service.error(success.body.responseMessage);
            if (
              success.body.responseCode == 409 ||
              success.body.responseCode == 410 ||
              success.body.responseCode == 411
            ) {
              localStorage.removeItem("LOGINTOKEN");
              localStorage.removeItem("role");
              localStorage.removeItem("_id");
              this.router.navigate(["/login"]);
            }
          }
        },
        error => {
          this.service.error("Something went wrong");
        }
      );
  }

  paymentMethod() {
    this.service.getApi("/api/v1/admin/paymentMethodList", 1).subscribe(
      success => {
        if (success.body.responseCode == 200) {
          this.paymentMethodArr = success.body.result;
          this.paymentMethodArrSettings = {
            singleSelection: true,
            idField: "_id",
            textField: "name",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            itemsShowLimit: 3,
            allowSearchFilter: true
          };
        } else {
          this.service.error(success.body.responseMessage);
          if (
            success.body.responseCode == 409 ||
            success.body.responseCode == 410 ||
            success.body.responseCode == 411
          ) {
            localStorage.removeItem("LOGINTOKEN");
            localStorage.removeItem("role");
            localStorage.removeItem("_id");
            this.router.navigate(["/login"]);
          }
        }
      },
      error => {
        this.service.error("Something went wrong");
      }
    );
  }

  manageRounting() {
    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        if (this.url == "/admin/disputeManagement") {
          let apireq = {
            userId: localStorage.getItem("_id")
          };
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe(
            success => {
              if (success.body.responseCode == 200) {
                this.permissions = success.body.data.permission[0];
                if (
                  success.body.data.permission[0].dispute_resolution_center ==
                  false
                ) {
                  this.router.navigate(["/pageNotFound"]);
                }
              }
            },
            error => {
              this.service.error("Something went wrong");
            }
          );
        }
      }
    });
  }

  dropdowndata() {
    if (localStorage.getItem("role") == "ADMIN") {
      var apireqdd = {
        employeeId: "",
        status: "Dispute"
      };
    } else {
      var apireqdd = {
        employeeId: localStorage.getItem("_id"),
        status: "Dispute"
      };
    }
    var buyerArr = [];
    var sellerArr = [];
    this.service
      .postApi("/api/v1/admin/tradeFilter", apireqdd, 1)
      .subscribe(success => {
        if (success.body.responseCode == 200) {
          this.dropDownArrList = success!.body;
          for (let i = 0; i < this.dropDownArrList.result1.length; i++) {
            buyerArr.push(this.dropDownArrList.result1[i].buyer[0].buyer_name);
          }
          var filteredBuyerArray = buyerArr.filter(function(item, pos) {
            return buyerArr.indexOf(item) == pos;
          });
          for (let i = 0; i < filteredBuyerArray.length; i++) {
            this.finalBuyerArr.push({
              id: i,
              buyerName: filteredBuyerArray[i]
            });
          }
          this.finalfinalbuyer = this.finalBuyerArr;
          for (let i = 0; i < this.dropDownArrList.result1.length; i++) {
            sellerArr.push(
              this.dropDownArrList.result1[i].seller[0].seller_name
            );
          }
          var filteredSellerArray = sellerArr.filter(function(item, pos) {
            return sellerArr.indexOf(item) == pos;
          });
          for (let i = 0; i < filteredSellerArray.length; i++) {
            this.finalSellerArr.push({
              id: i,
              sellerName: filteredSellerArray[i]
            });
          }
          this.finalFinalSeller = this.finalSellerArr;
          this.dropdownLists();
        } else {
          this.service.error(success.body.responseMessage);
          if (
            success.body.responseCode == 409 ||
            success.body.responseCode == 410 ||
            success.body.responseCode == 411
          ) {
            localStorage.removeItem("LOGINTOKEN");
            localStorage.removeItem("role");
            localStorage.removeItem("_id");
            this.router.navigate(["/login"]);
          }
        }
      });
  }

  dropdownLists() {
    this.tradeIdArrSettings = {
      singleSelection: true,
      idField: "id",
      textField: "data",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.tradeOwnerNameDropDownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "trade_owner_name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.buyerDropDownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "buyerName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.sellerDropDownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "sellerName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.disputeIdArrSettings = {
      singleSelection: true,
      idField: "_id",
      textField: "disputeUniqueId",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.showdropdownList = [
      { _id: 1, limit: "10" },
      { _id: 2, limit: "15" },
      { _id: 3, limit: "20" },
      { _id: 4, limit: "30" }
    ];
    this.showdropdownSettings = {
      singleSelection: true,
      idField: "_id",
      textField: "limit",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  disputwListWithSearching(event, pag) {
    if (this.userType == "ADMIN") {
      var employeeId = "";
    } else {
      if (this.userType == "SUBADMIN") {
        var employeeId = localStorage.getItem("_id");
      } else {
        if (this.userType == "MANAGER") {
          var employeeId = localStorage.getItem("_id");
        }
      }
    }
    if (pag == 1) {
      this.pageNumber = 1;
    }
    if (event === 'reset') {
      this.dropdowndata();
      this.PatientSearchForm.patchValue({
        trade: '',
        country: '',
        startDate: '',
        toDate: '',
        paymentMode: '',
        disputeId: '',
        show: '',
        tradeOwnerName: '',
        buyer: '',
        seller: ''
      });
      this.apireq = {
        employeeId: employeeId,
        uniqueId: "",
        disbuteUniqueId: "",
        paymentMethod: "",
        // "status": "Dispute",
        //"dispute_status": true,
        disputeDone: true,
        fromDate: "",
        toDate: "",
        country: "",
        limit: 10,
        page: this.pageNumber
      };
    } else {
      this.apireq = {
        employeeId: employeeId,
        uniqueId:
          this.PatientSearchForm.value.trade != ""
            ? this.PatientSearchForm.value.trade[0].data
            : "",
        disbuteUniqueId:
          this.PatientSearchForm.value.disputeId != ""
            ? this.PatientSearchForm.value.disputeId[0].disbuteUniqueId
            : "",
        paymentMethod:
          this.PatientSearchForm.value.paymentMode != ""
            ? this.PatientSearchForm.value.paymentMode[0].name
            : "",
        // "status": 'Dispute',
        // "dispute_status": true,
        tradeOwner:
          this.PatientSearchForm.value.tradeOwnerName != ""
            ? this.PatientSearchForm.value.tradeOwnerName[0].trade_owner_name
            : "",
        buyerName:
          this.PatientSearchForm.value.buyer != ""
            ? this.PatientSearchForm.value.buyer[0].buyerName
            : "",
        sellerName:
          this.PatientSearchForm.value.seller != ""
            ? this.PatientSearchForm.value.seller[0].sellerName
            : "",
        disputeDone: true,
        fromDate:
          this.PatientSearchForm.value.startDate == "" ||
          this.PatientSearchForm.value.startDate == undefined
            ? ""
            : new Date(this.PatientSearchForm.value.startDate).toISOString(),
        toDate:
          this.PatientSearchForm.value.toDate == "" ||
          this.PatientSearchForm.value.toDate == undefined
            ? ""
            : new Date(
                new Date(this.PatientSearchForm.value.toDate).getTime() +
                  24 * 60 * 60 * 1000
              ).toISOString(),
        country:
          this.PatientSearchForm.value.country != ""
            ? this.PatientSearchForm.value.country[0].country
            : "",
        limit:
          this.PatientSearchForm.value.show != 10
            ? this.PatientSearchForm.value.show != ""
              ? Number(this.PatientSearchForm.value.show[0].limit)
              : 10
            : 10,
        page: this.pageNumber
      };
    }
    this.service.postApi("/api/v1/admin/tradeList", this.apireq, 1).subscribe(
      success => {
        if (success.body.responseCode == 200) {
          this.tradeListArr = success.body.result;
          this.paginationData = success.body.paginationData;
        } else {
          if (success.body.responseCode == 404) {
            this.tradeListArr = [];
          }
          if (
            success.body.responseCode == 409 ||
            success.body.responseCode == 410 ||
            success.body.responseCode == 411
          ) {
            this.service.error(success.body.responseMessage);
            localStorage.removeItem("LOGINTOKEN");
            localStorage.removeItem("role");
            localStorage.removeItem("_id");
            this.router.navigate(["/login"]);
          }
        }
      },
      error => {
        this.service.error("Something went wrong");
      }
    );
  }

  pagination(page) {
    this.pageNumber = page;
    this.disputwListWithSearching("event", 2);
  }

  selectAll(event) {
    this.selectedRowDataArr = [];
    if (this.select.selectAll == true) {
      for (let i = 0; i < this.tradeListArr.length; i++) {
        if (!this.tradeListArr[i].employeeId) {
          this.selectedRowDataArr.push(this.tradeListArr[i]._id);
        }
        $("#selectParticular" + i).prop("checked", true);
      }
    } else {
      this.selectedRowDataArr = [];
      for (let i = 0; i < this.tradeListArr.length; i++) {
        $("#selectParticular" + i).prop("checked", false);
      }
    }
  }

  selectparticular(i, id) {
    if ($("#selectParticular" + i).is(":checked")) {
      this.selectedRowDataArr.push(id);
    } else {
      this.selectedRowDataArr = this.selectedRowDataArr.filter(function(item) {
        return item !== id;
      });
      this.select.selectAll = false;
    }
    if (this.selectedRowDataArr.length == this.tradeListArr.length) {
      this.select.selectAll = true;
    }
  }
}
