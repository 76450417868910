import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MyServiceService } from '../../my-service.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Component({
  selector: 'app-static-content-management',
  templateUrl: './static-content-management.component.html',
  styleUrls: ['./static-content-management.component.css']
})
export class StaticContentManagementComponent implements OnInit {
  staticData: any=[];
  url:any
  permissions:any

  constructor(public route: Router,  public spinnerService: Ng4LoadingSpinnerService,private service: MyServiceService) { 
    this.manageRounting()
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.service.getApi('/api/v1/static/staticContentGet', 1)
      .subscribe((success) => {
       
        if (success.body.responseCode == 200) {
          this.staticData = success.body.Data;
        }
        else {
          this.service.error(success.body.responseMessage)
          if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
            localStorage.removeItem('LOGINTOKEN');
            localStorage.removeItem('role');
            localStorage.removeItem('_id');
            this.route.navigate(["/login"]);
          }
        }
      })
  }

  manageRounting() {
    this.route.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        if (this.url == "/admin/staticContentManagement") {
          let apireq = {
            "userId": localStorage.getItem("_id")
          }
          this.spinnerService.show();
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
            if (success.body.responseCode == 200) {
              this.spinnerService.hide();
              if (success.body.data.userType != 'ADMIN') {
                this.route.navigate(["/pageNotFound"]);
              }
            }
          }, error => {
            this.spinnerService.hide();
            this.service.error("Something went wrong")
          })
        }

      }
    })
  }

  editContent() {
    this.route.navigate(["/admin/editStaticContentManagement"])
  }

}
