import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { MyServiceService } from 'src/app/my-service.service';

@Component({
  selector: 'app-track-staff-actions',
  templateUrl: './track-staff-actions.component.html',
  styleUrls: ['./track-staff-actions.component.css']
})
export class TrackStaffActionsComponent implements OnInit {
  PatientSearchForm: FormGroup;
  list: any;
  dropdownList: any = []
  staffNamedropdownSettings: any = {}
  userNamedropdownList: any = []
  userNamedropdownSettings: any = {}
  moduledropdownList: any = []
  moduledropdownSettings: any = {}
  showdropdownList: any = []
  showdropdownSettings: any = {}
  userType: any;
  uniqueIddropdownList: any = []
  uniqueIddropdownSettings: any = {}
  pagenumber: number = 1
  userName: any = []
  userNameArr: any = []
  url: any
  uniqueIdArr: any = []
  staffName: any = []
  todayDate:any
  nowDate2:any
  todayDate1:any
  constructor(private router: Router, public service: MyServiceService) {
    this.manageRounting()
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.todayDate1 = new Date()
    this.todayDate = new Date()
    this.formValidation();
    this.getRole()
  }

  manageRounting() {
    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        if (this.url == "/admin/trackStaffAction") {
          let apireq = {
            "userId": localStorage.getItem("_id")
          }
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
            if (success.body.responseCode == 200) {
              if (success.body.data.permission[0].userManagment == false) {
                this.router.navigate(["/pageNotFound"]);
              }
              else {
                if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
                  this.service.error(success.body.responseMessage)
                  localStorage.removeItem('LOGINTOKEN');
                  localStorage.removeItem('role');
                  localStorage.removeItem('_id');
                  this.router.navigate(["/login"]);
                }
              }
            }
          }, error => {
            this.service.error("Something went wrong")
          })
        }

      }
    })
  }

  formValidation() {
    this.PatientSearchForm = new FormGroup({
      staffUniqueId: new FormControl(''),
      staffUserName: new FormControl(''),
      userName: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      show: new FormControl(''),
      module: new FormControl(''),
    });
  }

  getRole() {
    let apireq = {
      "userId": localStorage.getItem("_id")
    }
    this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.userType = success.body.data
        this.actionList('reset')
      }
      else {
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  actionList(event) {
    if (event == 'reset') {
      this.PatientSearchForm.patchValue({
        staffUniqueId: '',
        staffName: '',
        userName: '',
        fromDate: '',
        toDate: '',
        show: '',
        module: '',
      });
      var apireq = {
        "userId": "",
        "uniqueId": '',
        "staffName": '',
        "useName": '',
        "module": '',
        "startDate": "",
        "endDate": "",
        "limit": 10,
        "pageNumber": this.pagenumber
      }
    }
    else {
      apireq = {
        "userId": "",
        "uniqueId": this.PatientSearchForm.value.staffUniqueId != '' ? this.PatientSearchForm.value.staffUniqueId[0].data : '',
        "staffName": this.PatientSearchForm.value.staffUserName != '' ? this.PatientSearchForm.value.staffUserName[0] : '',
        "useName": this.PatientSearchForm.value.userName != '' ? this.PatientSearchForm.value.userName[0].usename : '',
        "module": this.PatientSearchForm.value.module != '' ? this.PatientSearchForm.value.module[0] : '',
        "startDate": (this.PatientSearchForm.value.fromDate == '' || this.PatientSearchForm.value.fromDate == undefined) ? '' : (new Date(this.PatientSearchForm.value.fromDate).toISOString()),
        "endDate": (this.PatientSearchForm.value.toDate == '' || this.PatientSearchForm.value.toDate == undefined) ? '' : (new Date(new Date(this.PatientSearchForm.value.toDate).getTime() + 24 * 60 * 60 * 1000).toISOString()),
        "limit": this.PatientSearchForm.value.show != 10 ? (this.PatientSearchForm.value.show != '' ? Number(this.PatientSearchForm.value.show[0].limit) : 10) : 10,
        "pageNumber": this.pagenumber
      }
    }

    this.service.postApi("/api/v1/admin/showStaffAction", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.list = success.body.result;
        this.dropdownList = success.body.staffName;
        this.uniqueIddropdownList = success.body.uniqueId;
        var uniqueIdArr = []
        for (let i = 0; i < this.uniqueIddropdownList.length; i++) {
          uniqueIdArr.push({ id: i, data: this.uniqueIddropdownList[i] })
        }
        this.uniqueIdArr = uniqueIdArr
        var staff = []
        for (let i = 0; i < this.dropdownList.length; i++) {
          staff.push({ id: i, staffName: this.dropdownList[i] })
        }
        this.staffName = staff
        this.uniqueIddropdownSettings = {
          singleSelection: true,
          idField: 'id',
          textField: 'data',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        }
        this.staffNamedropdownSettings = {
          singleSelection: true,
          idField: 'id',
          textField: 'staffName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        }
        this.userNamedropdownList = success.body.username
        for (let i = 0; i < this.userNamedropdownList.length; i++) {
          if (this.userNamedropdownList[i] == null) {
            continue;
          }
          this.userName.push({ id: i, usename: this.userNamedropdownList[i] })
        }
        this.userNameArr = this.userName
        this.userNamedropdownSettings = {
          singleSelection: true,
          idField: 'id',
          textField: 'usename',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        }
        this.moduledropdownList = success.body.module
        this.moduledropdownSettings = {
          singleSelection: true,
          idField: 'id',
          textField: 'module',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        }
        this.showdropdownList = [{ _id: 1, limit: 10 }, { _id: 2, limit: 15 }, { _id: 3, limit: 20 }, { _id: 4, limit: 30 }]
        this.showdropdownSettings = {
          singleSelection: true,
          idField: '_id',
          textField: 'limit',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        };
      } else {
        if(success.body.responseCode == 404){
          this.list = []
        }
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  paginationUserList(event) {
    this.pagenumber = event
    this.actionList('event')
  }

  userDetail() {
    this.router.navigate(['/admin/userDetailLoginHistory']);
  }
  changed(event){
    if (event) {
      this.nowDate2 = event;
    }
    else{
      this.nowDate2 = ''
    }
  }
  maxFromDate(event){
    if(event){
      this.todayDate= new Date(event);
      }
      else{
        this.todayDate= new Date();
      }
  }
}
