import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { MyServiceService } from '../../my-service.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

declare var $:any
@Component({
  selector: 'app-add-adminstrator',
  templateUrl: './add-adminstrator.component.html',
  styleUrls: ['./add-adminstrator.component.css']
})
export class AddAdminstratorComponent implements OnInit {
  PatientForm:FormGroup
  modalForm:FormGroup
  apiData:any
  message: any;
  userType: any;
  url:any
  atleastOne:number =2
  constructor( private myRoute: Router, private service:MyServiceService, public spinnerService: Ng4LoadingSpinnerService) {
    this.manageRounting();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.PatientForm=new FormGroup({
      name: new FormControl('',[Validators.required, Validators.minLength(2), Validators.maxLength(256), Validators.pattern('[A-Za-z][A-Za-z \\s]*$')]),
      email: new FormControl('',[Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/),Validators.maxLength(256)]),
      userName: new FormControl('',[Validators.required,Validators.minLength(2),Validators.maxLength(256)]),
      password: new FormControl('',[Validators.required,Validators.minLength(8),Validators.maxLength(16)]),
      confirmPassword: new FormControl('',[Validators.required]),
      access: new FormControl('',[Validators.required]),
      dashBoard:new FormControl(false),
      userManagement:new FormControl(false),
        suspendUnsuspendUser:new FormControl(false),
        disable2FA:new FormControl(false),
        editUserData:new FormControl(false),
        seeLoginHistory:new FormControl(false),
        seeWalletAndBalance:new FormControl(false),
        feedbackAction:new FormControl(false),
        trustedByAction:new FormControl(false),
        blockedByAction:new FormControl(false),
      advertisement:new FormControl(false),
        enableDisabled:new FormControl(false),
        deleteAd:new FormControl(false),
      trades:new FormControl(false),
      disputeResolutionCenter:new FormControl(false),
      KYCVarification:new FormControl(false),
      finances:new FormControl(false),
      support:new FormControl(false),
      systemConfiguration:new FormControl(false),
    })

    this.modalForm = new FormGroup({
      code : new FormControl('',[Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[0-9]*')])
    })
      this.userType = localStorage.getItem("role")
  }

  manageRounting() {
    this.myRoute.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        if(this.url=="/admin/addAdminstrator"){
          let apireq = {
            "userId": localStorage.getItem("_id")
          }
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
            if (success.body.responseCode == 200) {
              if (success.body.data.permission[0].userManagment == false) {
                this.myRoute.navigate(["/pageNotFound"]);
              }
            }
          }, error => {
            this.service.error("Something went wrong")
          })
        }

      }
    })
  }


  onSubmit(data){
    if(this.PatientForm.invalid){
      if(this.PatientForm.value.dashBoard == false && this.PatientForm.value.userManagement == false &&
        this.PatientForm.value.suspendUnsuspendUser == false && this.PatientForm.value.disable2FA == false &&
        this.PatientForm.value.editUserData == false && this.PatientForm.value.seeLoginHistory == false &&
        this.PatientForm.value.seeWalletAndBalance == false && this.PatientForm.value.feedbackAction == false &&
        this.PatientForm.value.trustedByAction == false && this.PatientForm.value.blockedByAction == false
         && this.PatientForm.value.advertisement == false &&
        this.PatientForm.value.enableDisabled == false &&
        this.PatientForm.value.deleteAd == false && this.PatientForm.value.trades == false &&
        this.PatientForm.value.disputeResolutionCenter == false &&
        this.PatientForm.value.KYCVarification == false && this.PatientForm.value.finances == false &&
        this.PatientForm.value.support == false && this.PatientForm.value.systemConfiguration == false){
          this.atleastOne = 1
          return;
        }
        else{
          this.atleastOne = 2
        }
      return
    }
    this.spinnerService.show();
    if(this.PatientForm.value.dashBoard == false && this.PatientForm.value.userManagement == false &&
      this.PatientForm.value.suspendUnsuspendUser == false && this.PatientForm.value.disable2FA == false &&
      this.PatientForm.value.editUserData == false && this.PatientForm.value.seeLoginHistory == false &&
      this.PatientForm.value.seeWalletAndBalance == false && this.PatientForm.value.feedbackAction == false &&
      this.PatientForm.value.trustedByAction == false && this.PatientForm.value.blockedByAction == false
      && this.PatientForm.value.advertisement == false &&
      this.PatientForm.value.enableDisabled == false &&
      this.PatientForm.value.deleteAd == false && this.PatientForm.value.trades == false &&
      this.PatientForm.value.disputeResolutionCenter == false &&
      this.PatientForm.value.KYCVarification == false && this.PatientForm.value.finances == false &&
      this.PatientForm.value.support == false && this.PatientForm.value.systemConfiguration == false){
        this.atleastOne = 1
        return;
      }
      this.atleastOne = 2
    this.apiData=data
    let checkEmailandUserNamereq = {
      "email":this.apiData.email,
      "username":this.apiData.userName,
    }

    this.service.postApi('/api/v1/admin/checkQuery', checkEmailandUserNamereq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        if(this.userType=="ADMIN"){
          $('#2FA').modal({ backdrop: 'static', keyboard: false });
        }
        else{
          this.addApi()
        }
        this.spinnerService.hide();
      }
      else{
        this.service.error(success.body.responseMessage)
        if(success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411 ){
              localStorage.removeItem('LOGINTOKEN');
              localStorage.removeItem('role');
              localStorage.removeItem('_id');
              this.myRoute.navigate(["/login"]);
        }
        this.spinnerService.hide();
      }
    },error=>{
      this.service.error("Something went wrong")
      this.spinnerService.hide();
    })
  }

  twoFAButton(data){
    if(this.modalForm.invalid){
      return
    }
    this.spinnerService.show();
    let apiData = {
      "googleToken": data.code,
      "id": localStorage.getItem("_id"),
      "type": localStorage.getItem("role"),
      "addAdmin" : "add"
    }
    this.service.postApi('/api/v1/admin/verifyTwoFactorAuth', apiData, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.addApi()
        this.spinnerService.hide();
      }
      else {
        this.service.error(success.body.responseMessage)
        this.spinnerService.hide();
        if(success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411 ){
          localStorage.removeItem('LOGINTOKEN');
              localStorage.removeItem('role');
              localStorage.removeItem('_id');
              this.myRoute.navigate(["/login"]);
        }
        this.modalForm.reset()
      }
    }, error => {
      this.service.error("Something went wrong")
      this.modalForm.reset()
      this.spinnerService.hide();
    })
  }

  addApi(){
    this.spinnerService.show();
    let apireq={ "name":this.apiData.name,
          "email":this.apiData.email,
          "username":this.apiData.userName,
          "password":this.apiData.password,
          "type":this.apiData.access,
          "adminId":localStorage.getItem("_id"),
          "permission":[{"dashboard": this.apiData.dashBoard,
                "userManagment": this.apiData.userManagement,
                "dispute_resolution_center": this.apiData.disputeResolutionCenter,
                "kycVerification": this.apiData.KYCVarification,
                "finances": this.apiData.finances,
                "support": this.apiData.support,
                "systemConfiguration": this.apiData.systemConfiguration,
                "um_suspend_unspend": this.apiData.suspendUnsuspendUser,
                "um_disable2FA": this.apiData.disable2FA,
                "um_editUserData": this.apiData.editUserData,
                "seeLoginHistory": this.apiData.seeLoginHistory,
                "um_seewalletAndBalance": this.apiData.seeWalletAndBalance,
                "um_feedbackByAction": this.apiData.feedbackAction,
                "um_trustedByAction": this.apiData.trustedByAction,
                "um_blockedByAction": this.apiData.blockedByAction,
                "advertisment": this.apiData.advertisement,
                "ad_enableDisable": this.apiData.enableDisabled,
                "ad_deleteAd": this.apiData.deleteAd,
                "trades": this.apiData.trades,
            }]
    }
    this.service.postApi('/api/v1/admin/addAdmin',apireq,1).subscribe((success)=>{
      if(success.body.responseCode==200){
        $('#2FA').modal('hide')
        this.myRoute.navigate(["/admin/userManagement/staff"]);
        if(this.apiData.access=="MANAGER"){
          this.service.success("Manager addedd successfully")
        }
        else{
          this.service.success("Employee addedd successfully")
        }
        this.spinnerService.hide();
      } else {

        this.service.error(success.body.responseMessage)
        this.spinnerService.hide();
        if(success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411 ){
          localStorage.removeItem('LOGINTOKEN');
              localStorage.removeItem('role');
              localStorage.removeItem('_id');
              this.myRoute.navigate(["/login"]);
        }
      }
    },error=>{

      this.spinnerService.hide();
      this.service.error('Something went wrong');
    })
  }

  advertisement(value){
    if(value==true){
      this.PatientForm.patchValue({
        enableDisabled:true,
        deleteAd:true,
      });
    }
    else{
      this.PatientForm.patchValue({
        enableDisabled:false,
        deleteAd:false,
      });
    }
  }

  userManagement(value){
    if(value==true){
      this.PatientForm.patchValue({
        suspendUnsuspendUser:true,
        disable2FA:true,
        editUserData:true,
        seeLoginHistory:true,
        seeWalletAndBalance:true,
        feedbackAction:true,
        trustedByAction:true,
        blockedByAction:true,
      });
    }
    else{
      this.PatientForm.patchValue({
        suspendUnsuspendUser:false,
        disable2FA:false,
        editUserData:false,
        seeLoginHistory:false,
        seeWalletAndBalance:false,
        feedbackAction:false,
        trustedByAction:false,
        blockedByAction:false,
      });
    }

  }

  cancle(){
    this.myRoute.navigate(["/admin/userManagement/staff"]);
  }

  selectPermission(){
    if(this.PatientForm.value.dashBoard == false && this.PatientForm.value.userManagement == false &&
      this.PatientForm.value.suspendUnsuspendUser == false && this.PatientForm.value.disable2FA == false &&
      this.PatientForm.value.editUserData == false && this.PatientForm.value.seeLoginHistory == false &&
      this.PatientForm.value.seeWalletAndBalance == false && this.PatientForm.value.feedbackAction == false &&
      this.PatientForm.value.trustedByAction == false && this.PatientForm.value.blockedByAction == false &&
       this.PatientForm.value.advertisement == false &&
      this.PatientForm.value.enableDisabled == false &&
      this.PatientForm.value.deleteAd == false && this.PatientForm.value.trades == false &&
      this.PatientForm.value.disputeResolutionCenter == false &&
      this.PatientForm.value.KYCVarification == false && this.PatientForm.value.finances == false &&
      this.PatientForm.value.support == false && this.PatientForm.value.systemConfiguration == false){
        this.atleastOne = 1
        return;
      }
      else{
        this.atleastOne = 2
      }
  }
}

