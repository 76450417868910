import { Component, OnInit } from '@angular/core';
import { MyServiceService } from '../../my-service.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  data: any;
  newUserData: any;
  newUserDataArr: any = [];
  tradeArr: any = []
  tradeFinalArr: any = []
  tradeForm: FormGroup
  countryArr: any = []
  tradeCountryArr: any = []
  permissions: any
  count = 1;
  url: any;
  payment: any = []
  walletBalance: any=[];
  newUserForm : FormGroup
  averageTimeTradeForm : FormGroup
  paymentMethodForm : FormGroup
  constructor(public service: MyServiceService, public router: Router) {
    this.manageRounting();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.newUserForm = new FormGroup({
      days : new FormControl(1,)
    })
    this.tradeForm = new FormGroup({
      trade: new FormControl(''),
      days: new FormControl(1),
    })
    this.averageTimeTradeForm = new FormGroup({
      days : new FormControl(1,)
    })
    this.paymentMethodForm = new FormGroup({
      days : new FormControl(1,)
    })
    this.newUser(this.newUserForm.value.days)
    this.trades()
    this.country()
    this.tradeByCountry()
    this.paymentGraph()
    this.totalWalletBalance()
  }

  manageRounting() {
    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        if (this.url == "/admin/dashboard") {
          let apireq = {
            "userId": localStorage.getItem("_id")
          }
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
            if (success.body.responseCode == 200) {
              if (success.body.data.permission[0].dashboard == false) {
                this.router.navigate(["/pageNotFound"]);
              }
            }
          }, error => {
            this.service.error("Something went wrong")
          })
        }
      }
    })
  }

  // ================================================ New Users ============================================

  newUser(newFormValue) {
    let apireq = {
      "dayCount": newFormValue.days || 1
    }
    this.service.postApi("/api/v1/graph/userGraph", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.newUserData = success.body.result
        var newUserDataKeyValuePair = Object.keys(this.newUserData)
        let new_length = this.newUserDataArr.length
        this.newUserDataArr.splice(0, new_length)
        for (var i = 0; i < newUserDataKeyValuePair.length; i++) {
          this.newUserDataArr.push({
            "label": newUserDataKeyValuePair[i],
            "value": this.newUserData[newUserDataKeyValuePair[i]]
          })
        }
        if(this.newUserDataArr.length == 0){
          this.newUserDataArr.push({
            "label": 0,
            "value": 0
          })
        }
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }
  width = 350;
  height = 400;
  type = 'line';
  dataFormat = 'json';
  dataSource = {
    "chart": {
      "caption": "New Users",
      "yaxisname": "Total Users",
      "xaxisname": "Days",
      "rotatelabels": "1",
      "setadaptiveymin": "1",
      "theme": "fusion"
    },
    "data": this.newUserDataArr
  }

  // ================================================ Trades ============================================

  trades() {
    let apireq = {
      "dayCount": this.tradeForm.value.days || 1,
      "status": this.tradeForm.value.trade || 'Pending'
    }
    this.service.postApi("/api/v1/graph/tradeGraph", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        var tradeData = success.body.result;
        var newUserDataKeyValuePair = Object.keys(tradeData)
        let new_length = this.tradeArr.length
        this.tradeArr.splice(0, new_length)
        for (var i = 0; i < newUserDataKeyValuePair.length; i++) {
          this.tradeArr.push({
            "label": newUserDataKeyValuePair[i],
            "value": tradeData[newUserDataKeyValuePair[i]]
          })
        }
        if(this.tradeArr.length == 0){
          this.tradeArr.push({
            "label": 0,
            "value": 0
          })
        }
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }
  tradewidth = 350;
  tradeheight = 400;
  tradetype = 'line';
  tradedataFormat = 'json';
  tradedataSource = {
    "chart": {
      "caption": "Trades",
      "yaxisname": "Number of trades",
      "xaxisname": "Days",
      "rotatelabels": "1",
      "setadaptiveymin": "1",
      "theme": "fusion"
    },
    "data": this.tradeArr
  }

  // ==================================== Payment Method ===============================


  paymentGraph() {
    let apireq = {
      "dayCount": this.paymentMethodForm.value.days || 1
    }
    this.service.postApi("/api/v1/graph/paymentGraph", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        var totalKeys = Object.keys(success.body.result)
        let new_length = this.payment.length
        this.payment.splice(0, new_length)
        for (let i = 0; i < totalKeys.length; i++)
          this.payment.push({ "label": totalKeys[i], "value": success.body.result[totalKeys[i]] })
        if(this.payment.length == 0){
          this.payment.push({ "label": 0, "value": 0 })
        }
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }


  PMwidth = 350;
  PMheight = 400;
  PMtype = 'column2d';
  PMdataFormat = 'json';
  PMdataSource = {
    "chart": {
      "yaxisname": "Payment method frequency",
      "xaxisname": "Payment",
      "decimals": "1",
      "theme": "fusion"
    },
    "data": this.payment
  }

  // ==================================== Ads by Country ===============================

  country() {
    this.service.getApi("/api/v1/graph/addCountryGraph", 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        var tradeData = success.body.result
        var newUserDataKeyValuePair = Object.keys(tradeData)
        for (var i = 0; i < newUserDataKeyValuePair.length; i++) {
          this.countryArr.push({
            "label": newUserDataKeyValuePair[i],
            "value": tradeData[newUserDataKeyValuePair[i]]
          })
        }
        if(this.countryArr.length == 0){
          this.countryArr.push({
            "label": 0,
            "value": 0
          })
        }
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }
  ABCwidth = 350;
  ABCheight = 400;
  ABCtype = 'column2d';
  ABCdataFormat = 'json';
  ABCdataSource = {
    "chart": {
      "xaxisname": "Trade",
      "decimals": "1",
      "theme": "fusion"
    },
    "data": this.countryArr.reverse()
  }

  // ==================================== Trade by Country ===============================

  tradeByCountry() {
    this.service.getApi("/api/v1/graph/tradeCountryGraph", 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        var tradeData = success.body.result
        var newUserDataKeyValuePair = Object.keys(tradeData)
        for (var i = 0; i < newUserDataKeyValuePair.length; i++) {
          this.tradeCountryArr.push({
            "label": newUserDataKeyValuePair[i],
            "value": tradeData[newUserDataKeyValuePair[i]]
          })
        }
        if(this.tradeCountryArr.length == 0){
          this.tradeCountryArr.push({
            "label": 0,
            "value": 0
          })
        }
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  TBCwidth = 350;
  TBCheight = 400;
  TBCtype = 'column2d';
  TBCdataFormat = 'json';
  TBCdataSource = {
    "chart": {
      "decimals": "1",
      "theme": "fusion"
    },
    "data": this.tradeCountryArr.reverse()
  }

  // ==================================== Total Dispute ===============================

  totalWalletBalance(){
    
    this.service.getApi("/api/v1/graph/disputeList",1).subscribe(success=>{
      if(success.body.responseCode==200){
        for(let i=0;i<success.body.result.length;i++){
          this.walletBalance.push({"label":success.body.result[i].month,"value":success.body.result[i].count})
        }
        if(this.walletBalance.length == 0){
          this.walletBalance.push({"label":0,"value":0})
        }
      }
      else{
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    },error=>{
      this.service.error("Something went wrong")
    })
  }
  TWBwidth = 350;
  TWBheight = 400;
  TWBtype = 'column2d';
  TWBdataFormat = 'json';
  TWBdataSource = {
    "chart": {
      "yaxisname": "count",
      "xaxisname": "month",
      "decimals": "1",
      "theme": "fusion"
    },
    "data": this.walletBalance
  }

}
