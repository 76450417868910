import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MyServiceService } from 'src/app/my-service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-user-detail-login-history',
  templateUrl: './user-detail-login-history.component.html',
  styleUrls: ['./user-detail-login-history.component.css']
})
export class UserDetailLoginHistoryComponent implements OnInit {
  modalId: any;
  id: any;
  subadminDetails: any;
  subadminLoginHistoryArr: any = [];
  limit: any;
  totalRecords: any;
  permissionsForm: FormGroup;
  currentPage: any;
  page: number = 1;
  staffPagenumber: number = 1;
  list: any = [];
  url: any
  userType: any
  tradeListPageNumber: number = 1
  tradeListArr: any = [];
  paginationData: any;
  constructor(public router: Router, public service: MyServiceService, private activatedRoute: ActivatedRoute) {
    this.manageRounting()
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getId()
    this.userForm()
    this.subadminDetail()
    this.subadminLoginHistory()
    this.staffActionHistory()
    this.tradeList()
    this.list['docs'] = []
  }

  userForm() {
    this.permissionsForm = new FormGroup({
      dashBoard: new FormControl(''),
      userManagement: new FormControl(''),
      suspendUnsuspendUser: new FormControl(''),
      disable2FA: new FormControl(''),
      editUserData: new FormControl(''),
      seeLoginHistory: new FormControl(''),
      seeWalletAndBalance: new FormControl(''),
      feedbackAction: new FormControl(''),
      trustedByAction: new FormControl(''),
      blockedByAction: new FormControl(''),
      seeAddAdmins: new FormControl(''),
      advertisement: new FormControl(''),
      enableDisabled: new FormControl(''),
      deleteAd: new FormControl(''),
      trades: new FormControl(''),
      disputeResolutionCenter: new FormControl(''),
      KYCVarification: new FormControl(''),
      finances: new FormControl(''),
      support: new FormControl(''),
      systemConfiguration: new FormControl('')
    })
  }

  manageRounting() {
    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        var spliturl = this.url.split("/")
        if (spliturl[2] == "userDetailLoginHistory") {
          let apireq = {
            "userId": localStorage.getItem("_id")
          }
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
            if (success.body.responseCode == 200) {
              this.userType = success.body.data
            }
            else {
              this.service.error(success.body.responseMessage)
              if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
                localStorage.removeItem('LOGINTOKEN');
                localStorage.removeItem('role');
                localStorage.removeItem('_id');
                this.router.navigate(["/login"]);
              }
            }
          }, error => {
            this.service.error("Something went wrong")
          })
        }

      }
    })
  }

  getId() {
    this.activatedRoute.params.subscribe(paramsId => {
      this.id = paramsId.id;
    });
  }

  subadminDetail() {
    let apireq = {
      "userId": this.id
    }
    this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.subadminDetails = success.body.data
        this.permissionsForm.patchValue({
          dashBoard: success.body.data!.permission[0]!.dashboard,
          userManagement: success.body.data!.permission[0]!.userManagment,
          suspendUnsuspendUser: success.body.data!.permission[0]!.um_suspend_unspend,
          disable2FA: success.body.data!.permission[0]!.um_disable2FA,
          editUserData: success.body.data!.permission[0]!.um_editUserData,
          seeLoginHistory: success.body.data!.permission[0]!.seeLoginHistory,
          seeWalletAndBalance: success.body.data!.permission[0]!.um_seewalletAndBalance,
          feedbackAction: success.body.data!.permission[0]!.um_feedbackByAction,
          trustedByAction: success.body.data!.permission[0]!.um_trustedByAction,
          blockedByAction: success.body.data!.permission[0]!.um_blockedByAction,
          seeAddAdmins: success.body.data!.permission[0]!.um_seeAddAdmin,
          advertisement: success.body.data!.permission[0]!.advertisment,
          enableDisabled: success.body.data!.permission[0]!.ad_enableDisable,
          deleteAd: success.body.data!.permission[0]!.ad_deleteAd,
          trades: success.body.data!.permission[0]!.trades,
          disputeResolutionCenter: success.body.data!.permission[0]!.dispute_resolution_center,
          KYCVarification: success.body.data!.permission[0]!.kycVerification,
          finances: success.body.data!.permission[0]!.finances,
          support: success.body.data!.permission[0]!.support,
          systemConfiguration: success.body.data!.permission[0]!.systemConfiguration
        })
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  subAdminPagination(event) {
    this.page = event
    this.subadminLoginHistory()
  }

  subadminLoginHistory() {
    let apireq = {
      "type": "SUBADMIN",
      "userId": this.id,
      "limit": 4,
      "pageNumber": this.page
    }
    this.service.postApi("/api/v1/admin/subadminLoginHistory", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.subadminLoginHistoryArr = []
        let temp = success.body.result;
        for (let i = 0; i < temp.length; i++) {
          this.subadminLoginHistoryArr.push({
            "browser": temp[i].login_history.browser,
            "login_date": temp[i].login_history.login_date,
            "system_ip": temp[i].login_history.system_ip ? (temp[i].login_history.system_ip) : ''
          })
        }
        this.limit = success.body.paginationData.limit;
        this.totalRecords = success.body.paginationData.total;
        this.currentPage = success.body.paginationData.currentPage;
      } else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  viewModal(id) {
    this.modalId = id
    $('#delete').modal({ backdrop: 'static', keyboard: false });
  }

  activeInactiveButton(status) {
    let apireq = {
      "userId": this.id,
      "status": status
    }
    this.service.postApi("/api/v1/admin/blockUser", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.subadminDetail()
      } else {
        this.service.error(success.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  staffActionHistory() {
    let apireq = {
      "userId": this.id,
      "limit": 10,
      "pageNumber": this.staffPagenumber
    }
    this.service.postApi("/api/v1/admin/showStaffAction", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.list = success.body.result;
      }
      else {
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  pagination(event) {
    this.staffPagenumber = event
    this.staffActionHistory()
  }

  tradeList() {
    let apireq = {
      "employeeId": this.id,
      "status": "Dispute",
      "dispute_status": true,
      "limit": 10,
      "page": this.tradeListPageNumber
    }
    this.service.postApi("/api/v1/admin/tradeList", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.tradeListArr = success.body.result;
        this.paginationData = success.body.paginationData;
      }
      else {
        if (success.body.responseCode == 404) {
          this.tradeListArr = []
        }
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          this.service.error(success.body.responseMessage)
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong");
    })
  }

}
