import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MyServiceService } from '../../my-service.service';

@Component({
  selector: 'app-kycmanagement',
  templateUrl: './kycmanagement.component.html',
  styleUrls: ['./kycmanagement.component.css']
})
export class KYCManagementComponent implements OnInit {
  PatientSearchForm: FormGroup
  nowDate: Date;
  nowDate1: any;
  nowDate2: any;
  hide: string;
  Pages: number = 1;
  userLimit: number = 10;
  paginationData: any
  KYCData = [];
  dropdownData: any;
  uniqueIdDropDown: any = []
  uniqueIddropdownSettings: any = {}
  dropdownDataForAll: any
  userNamedropdownSettings: any = {}
  statusDropdownData: any = []
  statusdropdownSettings: any = {}
  apireq: any;
  showdropdownList: any
  showdropdownSettings: any = {}
  dropdownusernamekeys: any
  nameDropDown: any = []
  url: any
  nowDate5:any
  constructor(private myRoute: Router, public spinnerService: Ng4LoadingSpinnerService, private service: MyServiceService, private activatedRoute: ActivatedRoute) {
    this.manageRounting();
    this.PatientSearchForm = new FormGroup({
      uniqueId: new FormControl(''),
      userName: new FormControl(''),
      type: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      status: new FormControl(''),
      show: new FormControl('')
    });

  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.nowDate5 = new Date()
    this.nowDate = new Date();
    this.nowDate1 = new Date();
    this.nowDate2 = '';
    this.hide = '';
    this.kycSearchWithList("reset", 1)


  }

  Changed(event) {
    if (event) {
      this.nowDate2 = event;
      this.nowDate = new Date();
    }
    else{
      this.nowDate2 = '';
    }
  }

  maxFromDate(event){
    if(event){
      this.nowDate1= new Date(event);
      }
      else{
        this.nowDate1= new Date();
      }
  }

  manageRounting() {
    this.myRoute.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.url = x.url;
        if (this.url == "/admin/KYCManagement") {
          let apireq = {
            "userId": localStorage.getItem("_id")
          }
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
            if (success.body.responseCode == 200) {
              if (success.body.data.permission[0].kycVerification == false) {
                this.myRoute.navigate(["/pageNotFound"]);
              }
            }
          }, error => {
            this.service.error("Something went wrong")
          })
        }

      }
    })
  }

  dropdownSetting() {
    this.uniqueIdDropDown = []
    for (var i = 0; i < this.dropdownData.length; i++) {
      this.uniqueIdDropDown.push({ id: i, uniqueId: this.dropdownData[i].kyc_docs[this.dropdownData[i].kyc_docs.length-1].uniqueId })
    }
    this.nameDropDown = []
    for (var i = 0; i < this.dropdownData.length; i++) {
      this.nameDropDown.push({ id: i, user_name: this.dropdownData[i].user_name })
    }
    this.uniqueIddropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'uniqueId',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
    this.userNamedropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'user_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
    this.statusDropdownData = [{ id: 1, status: "ALL" }, { id: 2, status: "APPROVED" }, { id: 3, status: "PENDING" }, { id: 4, status: "REJECTED" }]
    this.statusdropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'status',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    }
    this.showdropdownList = [{ _id: 1, limit: "10" }, { _id: 2, limit: "15" }, { _id: 3, limit: "20" }, { _id: 4, limit: "30" }]
    this.showdropdownSettings = {
      singleSelection: true,
      idField: '_id',
      textField: 'limit',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  kycSearchWithList(data, pag) {
    if (pag == 1) {
      this.Pages = 1
    }
    if (data == "deselect" || data == 'reset') {
      this.Pages = 1
    }
    if (data == 'reset') {
      this.apireq = {
        "uniqueId": '',
        "userName": '',
        "startDate": '',
        "endDate": '',
        "pageNumber": this.Pages,
        "limit": 10,
        "status": '',
      }
      this.PatientSearchForm.patchValue({
        uniqueId: "",
        userName: "",
        type: "",
        startDate: "",
        endDate: "",
        status: "",
        show: ""
      });
    }
    else {
      this.apireq = {
        "uniqueId": (this.PatientSearchForm.value.uniqueId != '') ? this.PatientSearchForm.value.uniqueId[0].uniqueId : '',
        "userName": (this.PatientSearchForm.value.userName != '') ? this.PatientSearchForm.value.userName[0].user_name : '',
        "fromDate": (this.PatientSearchForm.value.startDate == '' || this.PatientSearchForm.value.startDate == undefined) ? '' : (new Date(this.PatientSearchForm.value.startDate).toISOString()),
        "toDate": (this.PatientSearchForm.value.endDate == '' || this.PatientSearchForm.value.endDate == undefined) ? '' : (new Date(new Date(this.PatientSearchForm.value.endDate).getTime() + 24 * 60 * 60 * 1000).toISOString()),
        "pageNumber": this.Pages,
        "limit": this.PatientSearchForm.value.show != 10 ? (this.PatientSearchForm.value.show != '' ? (this.PatientSearchForm.value.show[0].limit) : 10) : 10,
        "status": (this.PatientSearchForm.value.status != '') ? ((this.PatientSearchForm.value.status[0].status != "ALL") ? this.PatientSearchForm.value.status[0].status : '') : '',
      }
    }
    this.service.postApi('/api/v1/user/getAllKyc', this.apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.KYCData = success.body.success;
        this.paginationData = success.body.paginationData;
        this.service.getApi('/api/v1/user/kycUniqueData', 1).subscribe((succ) => {
          if (succ.body.responseCode == 200) {
            this.dropdownData = succ.body.result
            this.dropdownSetting()
          }
        })
      }
      else {
        if (success.body.responseCode == 404) {
          this.KYCData = []
        }
        else {
          if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
            localStorage.removeItem('LOGINTOKEN');
            localStorage.removeItem('role');
            localStorage.removeItem('_id');
            this.myRoute.navigate(["/login"]);
          }
        }
      }

    }, error => {
      this.service.error("Something went wrong")
    })
  }



  paginationUserList(event) {
    this.Pages = event;
    this.kycSearchWithList("event", 3)
  }

}
