import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MyServiceService } from '../../my-service.service';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  permissions:any;
  url:any;
  userType:any

  constructor(private myRoute: Router, private service: MyServiceService) {
    this.myRoute.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        let urlh = x.url.split('/');
        this.url = urlh[2];
      }
    })
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.check();
    this.getPermissions()
  }

  getPermissions(){
    let apireq = {
      "userId": localStorage.getItem("_id")
    }
    this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.permissions=success.body.data
        if(this.permissions.userType == 'ADMIN'){
          this.userType = 'Admin'
        }
        else{
          if(this.permissions.userType == "MANAGER"){
            this.userType = 'Manager'
          }
        }
      }
      else{
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.myRoute.navigate(["/login"]);
        }
      }
    },error=>{
      this.service.error("Something went wrong")
    })
  }

  check() {
    $('.app-sidebar__toggle').click(function () {
      $('body').toggleClass('sidenav-toggled');
    });
  }

  logout() {
    $('#logout_modal').modal({ backdrop: 'static', keyboard: false });
  }
  logoutModal() {
    let apireq = {
      "_id":localStorage.getItem("_id")
    }
    this.service.postApi("/api/v1/user/logout", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.service.success(success.body.responseMessage)
        localStorage.removeItem('LOGINTOKEN');
        localStorage.removeItem('role');
        localStorage.removeItem('_id');
        this.myRoute.navigate([""]);
      } else {
        this.service.error(success.body.responseMessage)
      }
    }, error => {
      this.service.error("Something went wrong")
    })

  }

}
