import { Component, OnInit } from '@angular/core';
import { MyServiceService } from '../../my-service.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-edit-advertisement',
  templateUrl: './edit-advertisement.component.html',
  styleUrls: ['./edit-advertisement.component.css']
})
export class EditAdvertisementComponent implements OnInit {
  urlData: any
  adId: any;
  addetail: any;
  adForm: FormGroup;
  paymentMethodArr: any;
  paymentId: any;
  tradeArr: any = [];
  tradePagination: any;
  tradeCurrentPage: number = 1;
  url:any
  permission:any
  constructor(public service: MyServiceService, private activatedRoute: ActivatedRoute, public router: Router) {
    this.getDetails()
    this.activatedRoute.params.subscribe(params => {
      this.urlData = params
      this.adId = params.userId;
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.formvalidations();
    this.paymentMethodList()
    this.getAdvertisementDetails()
    this.tradeList()
  }

  getDetails(){
    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        
        this.url = x.url;
        var splitUrl = this.url.split("/")
        if (splitUrl[2] == "editAdvertisement") {
          let apireq = {
            "userId": localStorage.getItem("_id")
          }
          this.service.postApi("/api/v1/admin/viewDetail", apireq, 1).subscribe((success) => {
            if (success.body.responseCode == 200) {
              if (success.body.data.permission[0].advertisement == false) {
                this.router.navigate(["/pageNotFound"]);
              }
              this.permission = success.body.data
            }
            else {
              this.service.error(success.body.responseMessage)
              if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
                localStorage.removeItem('LOGINTOKEN');
                localStorage.removeItem('role');
                localStorage.removeItem('_id');
                this.router.navigate(["/login"]);
              }
            }
          }, error => {
            this.service.error("Something went wrong")
          })
        }

      }
    })
  }

  formvalidations() {
    this.adForm = new FormGroup({
      identifiedPeople: new FormControl('', [Validators.required]),
      SMSVerification: new FormControl('', [Validators.required]),
      trustedsuspendUnsuspend: new FormControl('', [Validators.required])
    })
  }

  tradeList() {
    let apireq = {
      adId: this.adId,
      limit: 10,
      pageNumber: this.tradeCurrentPage
    }
    this.service.postApi("/api/v1/admin/tradeAdHistory", apireq, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.tradeArr = success.body.result;
        // this.tradePagination = success.body.result
      } else {
        this.tradeArr = []
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  tradePaginationFunc(event) {
    this.tradeCurrentPage = event
    this.tradeList()
  }

  paymentMethodList() {
    this.service.getApi("/api/v1/admin/paymentMethodList", 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.paymentMethodArr = success.body.result
      } else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }
  getAdvertisementDetails() {
    this.service.getApi("/api/v1/trade/detail_trade/" + this.adId, 1).subscribe(success => {
      if (success.body.responseCode == 200) {
        this.addetail = success.body.result;
        this.adForm.patchValue({
          identifiedPeople: success.body.result.security_options.identfifed_people,
          SMSVerification: success.body.result.security_options.sms_verification,
          trustedsuspendUnsuspend: success.body.result.security_options.trusted_people
        })
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }
  payment_method(event) {
    this.paymentId = event
  }
  viewModal() {
    $('#delete').modal({ backdrop: 'static', keyboard: false });
  }

  deleteAd() {
    let apireq = {
      adId: this.adId,
      userType: localStorage.getItem("role")
    }
    this.service.postApi('/api/v1/trade/deleteAdvertisement', apireq, 1).subscribe((success) => {
      if (success.body.responseCode == 200) {
        this.service.success("Advertisement deleted successfully");
        this.router.navigate(['/admin/advertisement'])
      }
      else {
        this.service.error(success.body.responseMessage)
        if (success.body.responseCode == 409 || success.body.responseCode == 410 || success.body.responseCode == 411) {
          localStorage.removeItem('LOGINTOKEN');
          localStorage.removeItem('role');
          localStorage.removeItem('_id');
          this.router.navigate(["/login"]);
        }
      }
    }, error => {
      this.service.error("Something went wrong")
    })
  }

  cancel() {
    window.history.back();
  }
}
